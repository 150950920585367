import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
// Footer
import Footer from "../../widgets/Footer/Footer";
// Icons
import { BsCheckCircleFill } from "react-icons/bs";
// Css
import "./orderComplete.css";

export default function OrderComplete(props) {
  const navigate = useNavigate();
  const [orderDetail, setOrderDetail] = useState(null);

  useEffect(() => {
    if (props.newOrderDetail === null) {
      navigate("/");
    } else {
      setOrderDetail(props.newOrderDetail);
    }

    return () => {
      if (props.newOrderDetail !== null) {
        props.setNewOrderDetail(null);
      }
    };
  }, []);

  return (
    <div id="page-order-complete" className="flex-column v-center page">
      <div className="flex-column v-center page-content">
        <h2 className="flex v-center">
          <BsCheckCircleFill />
          交易成功
        </h2>
        {orderDetail && (
          <div className="flex-column content">
            <a className="message">
              謝謝, 我們已收到您的訂單, 將會儘快為您處理。
            </a>
            {/* Tracking number / order amount / payment method */}
            <div className="flex-column block">
              <div className="flex v-baseline field">
                <a className="title">訂單編號:</a>
                <a>{orderDetail.trackingNumber}</a>
              </div>
              <div className="flex v-baseline field">
                <a className="title">訂單金額:</a>
                <a>NT${orderDetail.totalAmount}</a>
              </div>
              <div className="flex v-baseline field">
                <a className="title">付款方式:</a>
                <a>{orderDetail.method}</a>
              </div>
            </div>
            {/* Shipping */}
            <div className="flex-column block">
              <div className="flex-column field info">
                <a className="title">{orderDetail.fullName}</a>
                <a>
                  {orderDetail.city}
                  {orderDetail.district}
                </a>
                <a>
                  {orderDetail.address} {orderDetail.zipcode}
                </a>
              </div>
            </div>
            {/* Contact info */}
            <div className="flex-column block">
              <div className="flex v-baseline field">
                <a className="title">聯絡電話:</a>
                <a>+886 {orderDetail.phoneNumber}</a>
              </div>
            </div>
          </div>
        )}
        <button className="button1" onClick={() => navigate("/")}>回首頁</button>
      </div>
      <Footer />
    </div>
  );
}
