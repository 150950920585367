import React from "react";
import { Link } from "react-router-dom";
// Icons
import { ImFacebook } from "react-icons/im";
import { FaInstagram } from "react-icons/fa";
import { FaLine } from "react-icons/fa";
// Css
import "./footer.scss";

export default function Footer() {
  return (
    <footer className="flex h-center">
      <div className="flex h-between footer-content">
        {/* 關於Toping */}
        <div className="flex-column field">
          <a className="footer-title">關於Toping</a>
          <Link to="/about">關於我們</Link>
          <Link to="/privacy">隱私權及網站使用條款</Link>

          {/* 客服資訊 */}
          <div className="flex-column field field-mobile">
            <a className="footer-title">客服資訊</a>
            <Link to="/contact">聯絡我們</Link>
            <Link to="/qa">Q&A</Link>
          </div>

          {/* Link */}
          <div className="flex footer-icons">
            <ImFacebook
              className="icon icon-facebook"
              onClick={() =>
                window.open(
                  "https://www.facebook.com/people/Toping-%25E6%259D%25B1%25E4%25BA%259E%25E5%25AE%2589%25E5%2585%25A8%25E9%259E%258B/100082934212185/",
                  "_blank"
                )
              }
            />
            <FaLine
              className="icon icon-line"
              onClick={() =>
                window.open("https://line.me/R/ti/p/@345ycbrl", "_blank")
              }
            />
          </div>
          <div className="copyright">
            Copyright © 2022, All Rights Reserved.
          </div>
        </div>
        {/* 客服資訊 */}
        <div className="flex-column field field-desktop">
          <a className="footer-title">客服資訊</a>
          <Link to="/contact">聯絡我們</Link>
          <Link to="/qa">Q&A</Link>
        </div>
        {/* Facebook page */}
        <iframe
          src="https://www.facebook.com/plugins/page.php?href=https%3A%2F%2Fwww.facebook.com%2Fprofile.php%3Fid%3D100082934212185&tabs&width=340&height=130&small_header=false&adapt_container_width=true&hide_cover=false&show_facepile=true&appId"
          className="field fb-page"
          style={{ border: "none", overflow: "hidden" }}
          allowFullScreen
          allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share"
        />
      </div>
    </footer>
  );
}
