import React, { useState, useRef, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Buffer } from "buffer";
import axios from "axios";
import HCaptcha from "@hcaptcha/react-hcaptcha";
// Zipcode
import { zipCodes, cities, districts } from "use-tw-zipcode";
// Footer
import Footer from "../../widgets/Footer/Footer";
// Icons
import { FaChevronUp, FaChevronDown } from "react-icons/fa";
import { AiFillCaretDown, AiFillCaretUp } from "react-icons/ai";
import logo_7eleven from "../../files/images/logo-7eleven.png";
import logo_hilife from "../../files/images/logo-hilife.png";
import logo_familymart from "../../files/images/logo-familymart.jpeg";
import logo_okmart from "../../files/images/logo-okmart.png";
// Css
import "./shippingInfo.css";

export default function ShippingInfo(props) {
  const navigate = useNavigate();
  const location = useLocation();
  const { cart } = location.state;

  // Toggle cart
  const [showCart, setShowCart] = useState(true);
  // Inputs
  const [company, setCompany] = useState("");
  const [fullName, setFullName] = useState("");
  const [gender, setGender] = useState("先生");
  const [email, setEmail] = useState("");
  const [lines, setLines] = useState(1);
  const [phoneType, setPhoneType] = useState(["手機"]);
  const [updatePhoneType, setUpdatePhoneType] = useState(false);
  const [phoneNumber, setPhoneNumber] = useState([""]);
  const [updatePhoneNumber, setUpdatePhoneNumber] = useState(false);
  const [address, setAddress] = useState("");
  const [city, setCity] = useState("");
  const [district, setDistrict] = useState("");
  const [zipcode, setZipcode] = useState("--");
  const note = useRef("");
  // Dropdowns
  const [showTypeDropdown, setShowTypeDropdown] = useState(false);
  const [typeDropdownIndex, setTypeDropdownIndex] = useState(null);
  const [showCityDropdown, setShowCityDropdown] = useState(false);
  const [showDistrictDropdown, setShowDistrictDropdown] = useState(false);
  // Validation
  const [isValid, setIsValid] = useState({
    fullName: true,
    email: true,
    phoneNumber: [true],
    address: true,
    city: true,
    district: true,
  });
  const [error, setError] = useState(null);
  // Summary
  const [originalTotal, setOriginalTotal] = useState(0);
  const [total, setTotal] = useState(0);
  // Shipping - 7-ELEVEN/萊爾富/全家/OK mart/郵寄寄送/貨到付款
  const shippingMethod = [
    "7-ELEVEN",
    "萊爾富",
    "全家",
    "OK mart",
    "郵寄寄送",
    "貨到付款",
  ];
  const shippingPrice = [100, 100, 100, 100, 100, 100];
  const [shipping, setShipping] = useState(0);
  const [method, setMethod] = useState(0);
  // Payment
  const [payment, setPayment] = useState(null);
  // [ Captcha ]
  const [token, setToken] = useState(null);
  // Event listener
  const [click, setClick] = useState(null);

  // Env
  const contactEmail = process.env.REACT_APP_CONTACT_EMAIL;
  const environment = process.env.REACT_APP_ENVIRONMENT;

  useEffect(() => {
    // User log in
    const info = getCookie();

    if (info) {
      callGetUserInfoEndpoint(info);
    }

    // [ Cart ]
    if (cart.length === 0) {
      navigate("/cart");
    } else {
      let originalAmount = 0;
      let totalAmount = 0;

      // Set original total & total amount
      cart.forEach((item) => {
        originalAmount += item.quantity * item.price;
        totalAmount += item.quantity * item.sale;
      });
      setOriginalTotal(originalAmount);
      setTotal(totalAmount);
    }

    // [ Event listener - mouse click ]
    const handleClick = (event) => {
      setClick(event);
    };
    document.addEventListener("click", handleClick);

    return () => {
      document.removeEventListener("click", handleClick);
    };
  }, []);

  // Phone input change
  useEffect(() => {
    // Phone type
    if (updatePhoneType) {
      setUpdatePhoneType(false);
    }

    // Phone number
    if (updatePhoneNumber) {
      setUpdatePhoneNumber(false);
    }

    // Click event
    if (click) {
      hideDropdowns(click);
      setClick(null);
    }
  }, [updatePhoneType, updatePhoneNumber, click]);

  // Endpoint
  async function callGetUserInfoEndpoint(info) {
    await axios
      .post(
        `${process.env.REACT_APP_API}/accountInfo/getInfo`,
        {
          userName: info.user,
        },
        {
          headers: { 
            "x-access-token": info.x,
            "x-api-key": process.env.REACT_APP_API_KEY
          },
        }
      )
      .then((res) => {
        if (res.status === 200) {
          // Update user info
          updateUserInfo(res.data);
        }
      })
      .catch(() => {});
  }
  async function callAddOrderEndpoint() {
    const info = getCookie();
    const header = {};
    const data = {
      cart,
      company,
      fullName,
      email,
      phoneType: phoneType[0],
      phoneNumber: phoneNumber[0],
      address,
      city,
      district,
      zipcode,
      shipping,
      method: shippingMethod[method],
      originalTotal,
      total,
      payment,
      note: note.current.value,
    };

    if (info) {
      if (info.x) {
        header.headers = {
          "x-access-token": info.x,
          "x-api-key": process.env.REACT_APP_API_KEY
        };
      }

      if (info.user) {
        data.userName = info.user;
      }

      if (info.cart) {
        data.cartId = info.cart;
      }
    }

    await axios
      .post(`${process.env.REACT_APP_API}/orders/add`, data, header)
      .then((res) => {
        if (res.status === 200) {
          // Clear cookie
          deleteCartCookie();

          // Update nav
          props.setCartQuantity(0);
          props.setRefreshCart(true);

          // Send tracking number edirect to order complete page
          props.setNewOrderDetail(res.data);
          navigate("/order-complete");
        }
      })
      .catch(() => {});
  }

  // Get cookie
  const getCookie = () => {
    let cookie = document.cookie;
    cookie = cookie.split("; ");

    if (cookie.length === 1 && cookie[0] === "") {
      return null;
    } else {
      const info = {};
      cookie.forEach((item) => {
        const str = item.split("=");
        info[str[0]] = str[1];
      });

      return info;
    }
  };
  // Delete cart from cookie
  const deleteCartCookie = () => {
    const time = new Date().toUTCString();
    document.cookie = "cart=; expires=" + time + "; path=/";
  };

  // Update user info
  const updateUserInfo = (data) => {
    const { info, phones } = data;

    setFullName(info.fullName);
    setGender(info.gender);
    setCompany(info.company);
    setEmail(info.email);
    setZipcode(info.zipcode);
    setCity(info.city);
    setDistrict(info.district);
    setAddress(info.address);

    // Phones
    const phoneTs = [];
    const phoneNs = [];
    phones.forEach((item) => {
      let num = item.number;
      if (item.type === "手機") {
        num = num.slice(0, 3) + "-" + num.slice(3, 6) + "-" + num.slice(6, 9);
      } else {
        num = num[0] + " " + num.slice(1, -4) + "-" + num.slice(-4);
      }

      phoneTs.push(item.type);
      phoneNs.push(num);
    });
    setLines(phones.length);
    setPhoneType(phoneTs);
    setPhoneNumber(phoneNs);
  };

  // Get cart list
  const getCartList = () => {
    const list = [];

    if (cart.length > 0) {
      cart.forEach((item, index) => {
        const { name, model, colorCode, price, sale, size, quantity, image } =
          item;

        list.push(
          <div
            key={"cart" + index}
            className="flex h-between v-center row item"
          >
            <img
              key={"cart-image" + index}
              src={
                image
                  ? `data:image/png;base64, ${new Buffer.from(image).toString(
                      "base64"
                    )}`
                  : null
              }
            />
            <div key={"cart-name-div" + index} className="flex-column name">
              <a key={"cart-name" + index}>{`${name}`}</a>
              <a key={"cart-model" + index}>{`(型號: ${model})`}</a>
            </div>
            <div
              key={"cart-color-div" + index}
              className="flex h-center color-div"
            >
              <div
                key={"cart-color" + index}
                style={{ backgroundColor: colorCode }}
                className="color"
              ></div>
            </div>
            <a key={"cart-size" + index}>{size}</a>
            <a key={"cart-quantity" + index}>{quantity}</a>
            <div key={"cart-price-div" + index} className="flex-column">
              {sale !== price && (
                <a key={"cart-sale" + index} className="sale">
                  ${sale.toLocaleString("en-US")}
                </a>
              )}
              <a
                key={"cart-price" + index}
                style={{
                  color: sale !== price ? "var(--red)" : "var(--dark5)",
                }}
              >
                ${price.toLocaleString("en-US")}
              </a>
            </div>
            <a key={"cart-total" + index}>
              ${(quantity * sale).toLocaleString("en-US")}
            </a>
          </div>
        );
      });
    } else {
      list.push(
        <div key="cart-no-item" className="flex h-center row no-item">
          購物車內無商品
        </div>
      );
    }

    return list;
  };

  // Dropdowns
  const toggleTypeDropdown = (i) => {
    if (typeDropdownIndex === i) {
      setShowTypeDropdown(!showTypeDropdown);
    } else {
      setShowTypeDropdown(true);
    }
    setTypeDropdownIndex(i);
  };
  const hideDropdowns = (event) => {
    const { id } = event.target;
    const parentId = event.target.parentElement
      ? event.target.parentElement.id
      : null;

    // Phone type dropdowns
    if (
      showTypeDropdown &&
      id !== "dropdown-type-text" &&
      id !== "dropdown-type-content" &&
      id !== "dropdown-type-icon" &&
      parentId !== "dropdown-type-icon"
    ) {
      setShowTypeDropdown(false);
      setTypeDropdownIndex(null);
    }

    // City dropdown
    if (
      showCityDropdown &&
      id !== "dropdown-city-text" &&
      id !== "dropdown-city-content" &&
      id !== "dropdown-city-icon" &&
      parentId !== "dropdown-city-icon"
    ) {
      setShowCityDropdown(false);
    }

    // District dropdown
    if (
      showDistrictDropdown &&
      id !== "dropdown-district-text" &&
      id !== "dropdown-district-content" &&
      id !== "dropdown-district-icon" &&
      parentId !== "dropdown-district-icon"
    ) {
      setShowDistrictDropdown(false);
    }
  };

  // Phone
  const getLine = () => {
    const list = [];

    for (let i = 0; i < lines; i++) {
      list.push(
        <div
          key={"phone-line" + i}
          style={{ marginTop: i > 0 ? "-15px" : "0" }}
          className="flex h-between v-start"
        >
          <div key={"phone-input" + i} className="flex h-between phone-input">
            <div
              key={"phone-type" + i}
              className="flex-column v-start phone-input-type"
            >
              <a key={"phone-type-txt" + i}>
                類型<span>*</span>
              </a>
              <div
                key={"phone-type-dropdown" + i}
                className="flex-column dropdown"
              >
                <div
                  key={"phone-type-dropdown-text-div" + i}
                  style={{
                    borderRadius:
                      showTypeDropdown && typeDropdownIndex == i
                        ? "5px 5px 0 0"
                        : "5px",
                  }}
                  className="flex h-between v-center dropdown-text"
                >
                  <a
                    key={"phone-type-dropdown-text" + i}
                    id="dropdown-type-text"
                    onClick={() => {
                      toggleTypeDropdown(i);
                    }}
                  >
                    {phoneType[i]}
                  </a>
                  {showTypeDropdown && typeDropdownIndex == i ? (
                    <AiFillCaretUp
                      id="dropdown-type-icon"
                      className="icon "
                      onClick={() => {
                        toggleTypeDropdown(i);
                      }}
                    />
                  ) : (
                    <AiFillCaretDown
                      id="dropdown-type-icon"
                      className="icon"
                      onClick={() => {
                        toggleTypeDropdown(i);
                      }}
                    />
                  )}
                </div>
                <div
                  key={"phone-type-dropdown-content" + i}
                  style={{
                    display:
                      showTypeDropdown && typeDropdownIndex == i
                        ? "flex"
                        : "none",
                  }}
                  id="dropdown-type-content"
                  className="flex-column dropdown-content"
                >
                  <a
                    key={"phone-type-dropdown-content-phone" + i}
                    onClick={() => {
                      setPhoneType((old) => {
                        old[i] = "手機";
                        return old;
                      });
                      setUpdatePhoneType(true);
                    }}
                  >
                    手機
                  </a>
                  <a
                    key={"phone-type-dropdown-content-landline" + i}
                    onClick={() => {
                      setPhoneType((old) => {
                        old[i] = "市話";
                        return old;
                      });
                      setUpdatePhoneType(true);
                    }}
                  >
                    市話
                  </a>
                </div>
              </div>
            </div>
            <div
              key={"phone-number" + i}
              className="flex-column v-start phone-input-number"
            >
              <a key={"phone-number-text" + i}>
                電話<span>*</span>
              </a>
              <div className="flex">
                <input
                  key={"phone-number-area-code" + i}
                  className={determineInvalid("phoneNumber", i)}
                  type="text"
                  value="+886"
                  disabled
                />
                <input
                  key={"phone-number-input" + i}
                  className={determineInvalid("phoneNumber", i)}
                  type="text"
                  placeholder={"聯絡電話" + (i + 1)}
                  value={phoneNumber[i]}
                  onChange={(event) => {
                    phoneNumber[i] = event.target.value;
                    setPhoneNumber(phoneNumber);
                    setUpdatePhoneNumber(true);
                  }}
                />
              </div>
            </div>
          </div>
        </div>
      );
    }

    return list;
  };

  // City
  const getCities = () => {
    const list = [];

    for (let i = 0; i < cities.length; i++) {
      list.push(
        <a
          key={"city-dropdown-content-text" + i}
          onClick={() => {
            setCity(cities[i]);
            setDistrict("");
            setZipcode("--");
          }}
        >
          {cities[i]}
        </a>
      );
    }

    return (
      <div className="flex-column dropdown">
        <div
          style={{
            borderRadius: showCityDropdown ? "5px 5px 0 0" : "5px",
          }}
          className={
            "flex h-between v-center dropdown-text" + determineInvalid("city")
          }
        >
          <a
            id="dropdown-city-text"
            onClick={() => setShowCityDropdown(!showCityDropdown)}
          >
            {city === "" ? "--" : city}
          </a>
          {showCityDropdown ? (
            <AiFillCaretUp
              id="dropdown-city-icon"
              className="icon "
              onClick={() => setShowCityDropdown(!showCityDropdown)}
            />
          ) : (
            <AiFillCaretDown
              id="dropdown-city-icon"
              className="icon"
              onClick={() => setShowCityDropdown(!showCityDropdown)}
            />
          )}
        </div>
        <div
          style={{
            display: showCityDropdown ? "flex" : "none",
          }}
          id="dropdown-city-content"
          className="flex-column dropdown-content"
        >
          {list}
        </div>
      </div>
    );
  };

  // Distrct
  const getDistrict = () => {
    const list = [];
    const districtList = districts[city] ? districts[city] : [];

    for (let i = 0; i < districtList.length; i++) {
      list.push(
        <a
          key={"district-dropdown-content-text" + i}
          onClick={() => {
            setDistrict(districtList[i]);
            setZipcode(zipCodes[city][districtList[i]]);
          }}
        >
          {districtList[i]}
        </a>
      );
    }

    return (
      <div className="flex-column dropdown">
        <div
          style={{
            borderRadius: showDistrictDropdown ? "5px 5px 0 0" : "5px",
          }}
          className={
            "flex h-between v-center dropdown-text" +
            determineInvalid("district")
          }
        >
          <a
            id="dropdown-district-text"
            style={{ cursor: districtList.length > 0 ? "pointer" : "auto" }}
            onClick={() => {
              setShowDistrictDropdown(
                districtList.length > 0 ? !showDistrictDropdown : false
              );
            }}
          >
            {district === "" ? "--" : district}
          </a>
          {districtList.length > 0 &&
            (showDistrictDropdown ? (
              <AiFillCaretUp
                id="dropdown-district-icon"
                className="icon "
                onClick={() => setShowDistrictDropdown(!showDistrictDropdown)}
              />
            ) : (
              <AiFillCaretDown
                id="dropdown-district-icon"
                className="icon"
                onClick={() => setShowDistrictDropdown(!showDistrictDropdown)}
              />
            ))}
        </div>
        <div
          style={{
            display: showDistrictDropdown ? "flex" : "none",
          }}
          id="dropdown-district-content"
          className="flex-column dropdown-content"
        >
          {list}
        </div>
      </div>
    );
  };

  // Validation
  const validation = () => {
    let allValid = true;
    const newValid = isValid;

    for (const key of Object.keys(isValid)) {
      // Email
      if (key === "email") {
        if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(email)) {
          newValid[key] = true;
        } else {
          allValid = false;
          newValid[key] = false;
        }
      }
      // Phone number
      else if (key === "phoneNumber") {
        for (let i = 0; i < phoneNumber.length; i++) {
          let regex = null;

          if (phoneType[i] === "市話") {
            regex = /^(2|3|4|5|6|7|8)[-\s]?[0-9]{3,4}[-\s]?[0-9]{4}$/;
          } else if (phoneType[i] === "手機") {
            regex = /^9[-\s]?[0-9]{2}[-\s]?[0-9]{3}[-\s]?[0-9]{3}$/;
          }
          // Skip check if doesn't specify type
          else {
            continue;
          }

          if (!regex.test(phoneNumber[i])) {
            allValid = false;
            newValid[key][i] = false;
          } else {
            newValid[key][i] = true;
          }
        }
      }
      // Full name, Address, City, District
      else {
        let field = null;

        if (key === "fullName") {
          field = fullName;
        } else if (key === "address") {
          field = address;
        } else if (key === "city") {
          field = city;
        } else if (key === "district") {
          field = district;
        }

        if (field === "") {
          allValid = false;
          newValid[key] = false;
        } else {
          newValid[key] = true;
        }
      }
    }
    setIsValid(newValid);
    setError(null);

    return allValid;
  };
  const determineInvalid = (field, index) => {
    // Phone
    if (index !== undefined) {
      return isValid[field][index] ? "" : " invalid-input";
    }
    // Zip code
    else if (field === "zipcode") {
      return isValid.city && isValid.district ? "" : " invalid-input";
    }
    // Others
    else {
      return isValid[field] ? "" : " invalid-input";
    }
  };
  const getInvalidMsg = (key, field) => {
    // Email
    if (key === "email") {
      return isValid[key] || error
        ? ""
        : `請輸入${field}或確認您的格式是否正確`;
    }
    // Phone number
    else if (key === "phoneNumber") {
      let allValid = true;
      isValid[key].forEach((elmnt) => {
        if (!elmnt) {
          allValid = false;
        }
      });
      return allValid ? "" : `請輸入${field}或確認您的格式是否正確`;
    }
    // Zipcode
    else if (key === "zipcode") {
      return isValid.city && isValid.district ? "" : `請輸入${field}`;
    }
    // Others
    else {
      return isValid[key] ? "" : `請輸入${field}`;
    }
  };

  // Submit
  async function handleSubmit() {
    // Validation
    const valid = validation();

    if (valid) {
      if (environment === "prod") {
        callAddOrderEndpoint();
      } else {
        const data =
          `secret=${process.env.REACT_APP_CAPTCHA_SECRET_KEY}` +
          `&response=${token}`;
        await axios
          .post(process.env.REACT_APP_CAPTCHA_VERIFY_URL, data, {
            headers: { "x-api-key": process.env.REACT_APP_API_KEY },
          })
          .then((res) => {
            const { success } = res.data;

            if (success) {
              callAddOrderEndpoint();
            } else {
              setError(
                "請完成驗證, 如果您認為有問題, 請與我們聯繫: " + contactEmail
              );
            }
          })
          .catch((err) => {
            console.log(err);
          });
      }
    }
  }

  return (
    <div id="page-shipping" className="flex-column v-center page">
      <div className="flex-column v-center page-content">
        <h2>配送與付款方式</h2>
        <div className="flex h-between content">
          <div className="flex-column content-left">
            {/* 商品清單 */}
            <div className="flex-column block">
              <div className="flex h-between v-center title">
                <h3>商品清單</h3>
                {showCart ? (
                  <FaChevronUp onClick={() => setShowCart(!showCart)} />
                ) : (
                  <FaChevronDown onClick={() => setShowCart(!showCart)} />
                )}
              </div>
              <div
                className="flex-column table"
                style={{ display: showCart ? "flex" : "none" }}
              >
                {/* Title */}
                <div className="flex h-between v-center row table-title">
                  <a className="image"></a>
                  <a className="name">商品名稱</a>
                  <a>顏色</a>
                  <a>尺寸</a>
                  <a className="quantity">數量</a>
                  <a>單價</a>
                  <a>小計</a>
                </div>
                {getCartList()}
              </div>
            </div>
            {/* 送貨資料 */}
            <div className="flex-column block">
              <div className="flex h-between v-center title">
                <h3>送貨資料</h3>
              </div>
              <div className="flex h-between input-row company">
                <div className="flex-column v-start input-field">
                  <a>公司名稱</a>
                  <input
                    type="text"
                    value={company ? company : ""}
                    onChange={(event) => setCompany(event.target.value)}
                  />
                  <a className="invalid-msg"></a>
                </div>
              </div>
              <div className="flex h-between input-row input-row-2column">
                <div className="flex-column v-start input-field">
                  <a>
                    姓名<span>*</span>
                  </a>
                  <input
                    type="text"
                    value={fullName}
                    onChange={(event) => setFullName(event.target.value)}
                    className={determineInvalid("fullName")}
                  />
                  <a className="invalid-msg">
                    {getInvalidMsg("fullName", "姓名")}
                  </a>
                </div>
                <div className="flex-column v-start input-field">
                  <a>
                    性別<span>*</span>
                  </a>
                  <div className="flex h-center gender">
                    <div className="flex h-end v-center">
                      <input
                        type="radio"
                        name="gender"
                        checked={gender === "先生"}
                        onChange={() => setGender("先生")}
                      />
                      <label onClick={() => setGender("先生")}>先生</label>
                    </div>
                    <div className="flex v-center">
                      <input
                        type="radio"
                        name="gender"
                        checked={gender === "小姐"}
                        onChange={() => setGender("小姐")}
                      />
                      <label onClick={() => setGender("小姐")}>小姐</label>
                    </div>
                  </div>
                </div>
              </div>
              <div className="flex h-between input-row">
                <div className="flex-column v-start input-field">
                  <a>
                    聯絡信箱<span>*</span>
                  </a>
                  <input
                    type="email"
                    value={email}
                    onChange={(event) => setEmail(event.target.value)}
                    className={determineInvalid("email")}
                  />
                  <a className="invalid-msg">
                    {getInvalidMsg("email", "信箱")}
                  </a>
                </div>
              </div>
              <div className="flex h-between input-row">
                <div className="flex-column v-start input-field phone">
                  {getLine()}
                  <a className="invalid-msg">
                    {getInvalidMsg("phoneNumber", "電話號碼")}
                  </a>
                </div>
              </div>
              <div className="flex h-between input-row">
                <div className="flex-column v-start input-field">
                  <a>
                    聯絡地址<span>*</span>
                  </a>
                  <input
                    type="text"
                    value={address}
                    onChange={(event) => setAddress(event.target.value)}
                    className={determineInvalid("address")}
                  />
                  <a className="invalid-msg">
                    {getInvalidMsg("address", "地址")}
                  </a>
                </div>
              </div>
              <div className="flex h-between input-row input-row-3column">
                <div className="flex-column v-start input-field">
                  <a>
                    縣市<span>*</span>
                  </a>
                  {getCities()}
                  <a className="invalid-msg">{getInvalidMsg("city", "縣市")}</a>
                </div>
                <div className="flex-column v-start input-field">
                  <a>
                    行政區<span>*</span>
                  </a>
                  {getDistrict()}
                  <a className="invalid-msg">
                    {getInvalidMsg("district", "行政區")}
                  </a>
                </div>
                <div className="flex-column v-start input-field zipcode">
                  <a>
                    郵遞區號<span>*</span>
                  </a>
                  <input
                    type="text"
                    value={zipcode}
                    className={determineInvalid("zipcode")}
                    disabled
                  />
                  <a className="invalid-msg">
                    {getInvalidMsg("zipcode", "縣市和行政區")}
                  </a>
                </div>
              </div>
              <div className="flex h-between input-row">
                <div className="flex-column v-start input-field">
                  <a>其他注意事項</a>
                  <textarea
                    placeholder={"若您有任何需要我們注意的事項, 請在這裡填寫。"}
                    ref={note}
                  />
                </div>
              </div>
            </div>
            {/* 送貨方式 */}
            <div className="flex-column block shipping-method">
              <div className="flex h-between v-center title">
                <h3>送貨方式</h3>
              </div>
              <div className="flex-column v-start options">
                <div
                  className={"flex v-center" + (method === 0 ? " select" : "")}
                  onClick={() => {
                    setMethod(0);
                    setShipping(shippingPrice[0]);
                  }}
                >
                  <input
                    type="radio"
                    name="method"
                    checked={method === 0}
                    onChange={() => {
                      setMethod(0);
                      setShipping(shippingPrice[0]);
                    }}
                  />
                  <img src={logo_7eleven} />
                  <a className="text">7-ELEVEN</a>
                  <a className="price">( NT${shippingPrice[0]} )</a>
                </div>
                <div
                  className={"flex v-center" + (method === 1 ? " select" : "")}
                  onClick={() => {
                    setMethod(1);
                    setShipping(shippingPrice[1]);
                  }}
                >
                  <input
                    type="radio"
                    name="method"
                    checked={method === 1}
                    onChange={() => {
                      setMethod(1);
                      setShipping(shippingPrice[1]);
                    }}
                  />
                  <img src={logo_hilife} />
                  <a className="text">萊爾富</a>
                  <a className="price">( NT${shippingPrice[1]} )</a>
                </div>
                <div
                  className={"flex v-center" + (method === 2 ? " select" : "")}
                  onClick={() => {
                    setMethod(2);
                    setShipping(shippingPrice[2]);
                  }}
                >
                  <input
                    type="radio"
                    name="method"
                    checked={method === 2}
                    onChange={() => {
                      setMethod(2);
                      setShipping(shippingPrice[2]);
                    }}
                  />
                  <img src={logo_familymart} />
                  <a className="text">全家</a>
                  <a className="price">( NT${shippingPrice[2]} )</a>
                </div>
                <div
                  className={"flex v-center" + (method === 3 ? " select" : "")}
                  onClick={() => {
                    setMethod(3);
                    setShipping(shippingPrice[3]);
                  }}
                >
                  <input
                    type="radio"
                    name="method"
                    checked={method === 3}
                    onChange={() => {
                      setMethod(3);
                      setShipping(shippingPrice[3]);
                    }}
                  />
                  <img src={logo_okmart} />
                  <a className="text">OK Mart</a>
                  <a className="price">( NT${shippingPrice[3]} )</a>
                </div>
                <div
                  className={"flex v-center" + (method === 4 ? " select" : "")}
                  onClick={() => {
                    setMethod(4);
                    setShipping(shippingPrice[4]);
                  }}
                >
                  <input
                    type="radio"
                    name="method"
                    checked={method === 4}
                    onChange={() => {
                      setMethod(4);
                      setShipping(shippingPrice[4]);
                    }}
                  />
                  <a className="text text-noimage">郵寄寄送</a>
                  <a className="price">( NT${shippingPrice[4]} )</a>
                </div>
                <div
                  className={"flex v-center" + (method === 5 ? " select" : "")}
                  onClick={() => {
                    setMethod(5);
                    setShipping(shippingPrice[5]);
                  }}
                >
                  <input
                    type="radio"
                    name="method"
                    checked={method === 5}
                    onChange={() => {
                      setMethod(5);
                      setShipping(shippingPrice[5]);
                    }}
                  />
                  <a className="text text-noimage">貨到付款</a>
                  <a className="price">( NT${shippingPrice[5]} )</a>
                </div>
              </div>
            </div>
            {/* 付款方式 */}
            <div className="flex-column block">
              <div className="flex h-between v-center title">
                <h3>付款方式</h3>
              </div>
            </div>
          </div>
          <div className="flex-column content-right">
            {/* Calculation */}
            <div className="flex-column calculation">
              <div className="flex h-between">
                <a>小計:</a>
                <a>NT$ {originalTotal.toLocaleString("en-US")}</a>
              </div>
              <div className="flex h-between discount">
                <a>折扣:</a>
                <a>- NT$ {(originalTotal - total).toLocaleString("en-US")}</a>
              </div>
              <div className="flex h-between">
                <a>運費:</a>
                <a>NT$ {shipping.toLocaleString("en-US")}</a>
              </div>
            </div>
            {/* Summary */}
            <div className="flex h-between summary">
              <a>合計:</a>
              <a>NT$ {(total + shipping).toLocaleString("en-US")}</a>
            </div>
            <HCaptcha
              sitekey={process.env.REACT_APP_CAPTCHA_SITE_KEY}
              onError={() =>
                setError("網頁錯誤, 請刷新頁面或與我們聯絡: " + contactEmail)
              }
              onExpire={() => setError("驗證過期, 請刷新頁面")}
              onVerify={setToken}
            />
             {error !== null && <a className="error-msg">{error}</a>}
            <button className="place-order button1 checkout" onClick={handleSubmit}>
              結帳
            </button>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}
