import { useState, useEffect, useRef } from "react";
import axios from "axios";
// Icons
import { CgClose, CgRadioCheck, CgRadioChecked } from "react-icons/cg";
import { AiFillCaretDown, AiFillCaretUp } from "react-icons/ai";
import { MdLibraryAdd, MdRemove } from "react-icons/md";
import { IoMdRemoveCircle, IoMdAddCircle } from "react-icons/io";
// Category
import { category } from "../Data";
// Css
import "./addProduct.css";

export default function AddProduct(props) {
  // Adding Successfully
  const [addSuccess, setAddSuccess] = useState(false);
  // Inputs
  const name = useRef("");
  const model = useRef("");
  // Types
  const [showTypeDropdown, setShowTypeDropdown] = useState(false);
  const [checkedType, setCheckedType] = useState([[]]);
  const [updateCheckedType, setUpdateCheckedType] = useState(false);
  // Colors
  const [colors, setColors] = useState([]);
  const [newColor, setNewColor] = useState({
    name: "",
    colorCode: "#000000",
    newColor: true,
  });
  const [colorMenuIndex, setColorMenuIndex] = useState(0);
  const [showColorDropdown, setShowColorDropdown] = useState(false);
  const [checkedColor, setCheckedColor] = useState([]);
  const [checkedNewColor, setCheckedNewColor] = useState(false);
  const [updateCheckedColor, setUpdateCheckedColor] = useState(false);
  const [updateNewColor, setUpdateNewColor] = useState(false);
  // Prices
  const [originalPrice, setOriginalPrice] = useState("");
  const [salePrice, setSalePrice] = useState("");
  const [priceList, setPriceList] = useState([]);
  // Images
  const [images, setImages] = useState([null]);
  const [imageList, setImageList] = useState([]);
  const [updateImage, setUpdateImage] = useState(false);
  const [previewImageList, setPreviewImageList] = useState([]);
  const [updatePreviewImage, setUpdatePreviewImage] = useState(false);
  const [otherPics, setOtherPics] = useState([null]);
  const [previewOtherPics, setPreviewOtherPics] = useState([null]);
  const [updatePreviewOtherPic, setUpdatePreviewOtherPic] = useState(false);
  const [updateOtherPic, setUpdateOtherPic] = useState(false);
  // Links
  const [rutenLink, setRutenLink] = useState("");
  const [pcstoreLink, setPcstoreLink] = useState("");
  const [shopeeLink, setShopeeLink] = useState("");
  const [rakutenLink, setRakutenLink] = useState("");
  const [momoLink, setMomoLink] = useState("");
  const [linkList, setLinkList] = useState([]);
  // Deals
  const [deals, setDeals] = useState([]);
  const [newDeal, setNewDeal] = useState({
    name: "",
    newDeal: true,
  });
  const [showDealDropdown, setShowDealDropdown] = useState(false);
  const [checkedDeal, setCheckedDeal] = useState([]);
  const [checkedNewDeal, setCheckedNewDeal] = useState(false);
  const [updateCheckedDeal, setUpdateCheckedDeal] = useState(false);
  // Sizes
  const minSize = useRef("24.0");
  const maxSize = useRef("28.0");
  // Shorts
  const [shorts, setShorts] = useState([""]);
  const [updateShorts, setUpdateShorts] = useState(false);
  // Specs
  const [specs, setSpecs] = useState([]);
  const [newSpec, setNewSpec] = useState({
    location: "",
    detail: "",
    advantage: "",
  });
  const [updateSpecs, setUpdateSpecs] = useState(false);
  // Validation
  const [isValid, setIsValid] = useState({
    name: true,
    model: true,
    type: true,
    color: true,
    minSize: true,
    maxSize: true,
    // Multiple inputs
    links: true,
    originalPrices: true,
    salePrices: true,
    images: true,
    otherPics: true,
    shorts: true,
    specs: true,
  });
  const [updateValidation, setUpdateValidation] = useState(false);

  useEffect(() => {
    // Category
    const array = [];
    category.forEach((item) => {
      array.push(new Array(item.type.length).fill(false));
    });
    setCheckedType(array);

    // Color
    callGetAddProductInfoEndpoint();
  }, []);

  // Update on array states
  useEffect(() => {
    // [ Type ]
    if (updateCheckedType) {
      setUpdateCheckedType(false);
    }
    // [ Color ]
    if (updateCheckedColor) {
      setUpdateCheckedColor(false);
    }
    if (updateNewColor) {
      setUpdateNewColor(false);
    }
    // [ Image ]
    if (updateImage) {
      setUpdateImage(false);
    }
    if (updateOtherPic) {
      setUpdateOtherPic(false);
    }
    if (updatePreviewImage) {
      setUpdatePreviewImage(false);
    }
    if (updatePreviewOtherPic) {
      setUpdatePreviewOtherPic(false);
    }
    // [ Deal ]
    if (updateCheckedDeal) {
      setUpdateCheckedDeal(false);
    }
    // [ Short ]
    if (updateShorts) {
      setUpdateShorts(false);
    }
    // [ Spec ]
    if (updateSpecs) {
      setUpdateSpecs(false);
    }
    // [ Validation ]
    if (updateValidation) {
      setUpdateValidation(false);
    }
  }, [
    updateCheckedType,
    updateCheckedColor,
    updateNewColor,
    updateImage,
    updatePreviewImage,
    updateOtherPic,
    updateCheckedDeal,
    updateShorts,
    updateSpecs,
    updateValidation,
  ]);

  // Endpoints
  async function callGetAddProductInfoEndpoint() {
    await axios
      .get(`${process.env.REACT_APP_API}/products/getAddProduct`, {
        headers: { "x-api-key": process.env.REACT_APP_API_KEY },
      })
      .then((res) => {
        if (res.status === 200) {
          // Color
          setColors(res.data.colors);
          setCheckedColor(new Array(res.data.colors.length).fill(false));
          // Link, price & image list
          const linksTmp = [];
          const pricesTmp = [];
          const imagesTmp = [];
          for (let i = 0; i < res.data.colors.length; i++) {
            linksTmp.push({
              rutenLink: "",
              pcstoreLink: "",
              shopeeLink: "",
              rakutenLink: "",
              momoLink: "",
            });
            pricesTmp.push({ originalPrice: "", salePrice: "" });
            imagesTmp.push([null]);
          }
          setLinkList(linksTmp);
          setPriceList(pricesTmp);
          setImageList(imagesTmp);
          setPreviewImageList(imagesTmp);
          // Deal
          setDeals(res.data.deals);
          setCheckedDeal(new Array(res.data.deals.length).fill(false));
        }
      })
      .catch(() => {});
  }
  async function callAddProductEndpoint() {
    const formData = await getFormData();

    await axios
      .post(`${process.env.REACT_APP_API}/products/add`, formData, {
        headers: {
          "Content-Type": `multipart/form-data; boundary=${"---XYZ"}`,
          "x-api-key": process.env.REACT_APP_API_KEY
        },
      })
      .then((res) => {
        if (res.status === 200) {
          setAddSuccess(true);
        }
      })
      .catch(() => {});
  }

  // Format data
  async function getFormData() {
    const formData = new FormData();
    formData.append("name", name.current.value);
    formData.append("model", model.current.value);
    formData.append("types", getTypeData());
    formData.append("deals", JSON.stringify(getDeal()));
    formData.append(
      "sizes",
      JSON.stringify({
        min: minSize.current.value,
        max: maxSize.current.value,
      })
    );
    formData.append("short", shorts);
    formData.append("specs", JSON.stringify(getSpecs()));
    formData.append("detailByColor", JSON.stringify(getDetailByColor()));

    const testImageList = [];
    const testOtherPics = [];
    let imageIndex = 1,
      otherPicIndex = 1;
    for (let i = 0; i < checkedColor.length; i++) {
      if (checkedColor[i]) {
        for (let j = 0; j < imageList[i].length; j++) {
          let currentImage  = null;
          if (typeof imageList[i][j] === "string" && imageList[i][j].includes("blob")) {
            currentImage = await convertBlobUrlToFile(
              imageList[i][j],
              "product-image" + imageIndex,
              imageIndex
            );
          }
          else if (imageList[i][j].type === "Buffer") {
            const imageWithNewType = `data:image/png;base64, ${new Buffer.from(
              imageList[i][j]
            ).toString("base64")}`;
            currentImage = await convertBlobUrlToFile(
              imageWithNewType,
              "product-image" + imageIndex,
              imageIndex
            );
          }
          else {
            currentImage = imageList[i][j];
          }
          testImageList.push(currentImage)
          formData.append("files", currentImage, "image-" + i + "-" + j);
        }
      }
    }

    for (let j = 0; j < otherPics.length; j++) {
      let currentImage  = null;
      if (otherPics[j] === null) {
        continue;
      }
      else if (typeof otherPics[j] === "string" && otherPics[j].includes("blob")) {
        currentImage = await convertBlobUrlToFile(
          otherPics[j],
          "other-picture" + imageIndex,
          otherPicIndex
        );
      }
      else if (otherPics[j].type === "Buffer") {
        const imageWithNewType = `data:image/png;base64, ${new Buffer.from(
          otherPics[j]
        ).toString("base64")}`;
        currentImage = await convertBlobUrlToFile(
          imageWithNewType,
          "other-picture" + imageIndex,
          otherPicIndex
        );
      }
      else {
        currentImage = otherPics[j];
      }
      testOtherPics.push(currentImage)
      formData.append("files", currentImage, "otherPic-" + j);
    }
    console.log({
      name: name.current.value,
      model: model.current.value,
      types: getTypeData(),
      deals: JSON.stringify(getDeal()),
      sizes: JSON.stringify({
        min: minSize.current.value,
        max: maxSize.current.value,
      }),
      short: shorts,
      specs: JSON.stringify(getSpecs()),
      detailByColor:JSON.stringify(getDetailByColor()),
      image: testImageList,
      otherPic: testOtherPics
    })
    return formData;
  }
  const getTypeData = () => {
    const types = [];

    for (let i = 0; i < checkedType.length; i++) {
      for (let j = 0; j < checkedType[i].length; j++) {
        if (checkedType[i][j]) {
          types.push(category[i].type[j]);
        }
      }
    }

    return types;
  };
  const getDetailByColor = () => {
    const details = [];

    for (let i = 0; i < checkedColor.length; i++) {
      if (checkedColor[i]) {
        details.push({
          color: colors[i],
          originalPrice: priceList[i].originalPrice,
          salePrice: priceList[i].salePrice,
          links: linkList[i],
        });
      }
    }

    return details;
  };
  const getDeal = () => {
    const details = [];

    for (let i = 0; i < checkedDeal.length; i++) {
      if (checkedDeal[i]) {
        details.push(deals[i]);
      }
    }

    return details;
  };
  const getSpecs = () => {
    const details = {};

    for (const item of specs) {
      details[item.location] = {
        material: item.detail,
        advantage: item.advantage,
      };
    }

    return details;
  };

  // Handle submit
  const handleSubmit = () => {
    const valid = checkValid();

    if (valid) {
      callAddProductEndpoint();
    }
  };

  // Validation
  const checkValid = () => {
    let allValid = true;
    const currentValid = isValid;

    for (const key of Object.keys(isValid)) {
      // [ Product type ]
      if (key === "type") {
        let exist = false;

        for (const list of checkedType) {
          for (const item of list) {
            if (item) {
              exist = true;
              break;
            }
          }
        }

        if (!exist) {
          allValid = false;
          currentValid[key] = false;
        } else {
          currentValid[key] = true;
        }
      }
      // [ Product color/Original prices/Sale prices/Images]
      else if (key === "color") {
        let oneInvalid = false;
        let colorExist = false; // color
        let linkValid = true; // link
        let originalPriceValid = true; // original price
        let salePriceValid = true; // sale price
        let imageValid = true; // image

        for (let i = 0; i < checkedColor.length; i++) {
          if (checkedColor[i]) {
            colorExist = true;

            // Original price
            if (priceList[i].originalPrice === "") {
              originalPriceValid = false;
              oneInvalid = true;
            }
            // Sale price
            if (priceList[i].salePrice === "") {
              salePriceValid = false;
              oneInvalid = true;
            }

            // Link
            if (
              !isValidHttpUrl(linkList[i].rutenLink) ||
              !isValidHttpUrl(linkList[i].pcstoreLink) ||
              !isValidHttpUrl(linkList[i].shopeeLink) ||
              !isValidHttpUrl(linkList[i].rakutenLink) ||
              !isValidHttpUrl(linkList[i].momoLink)
            ) {
              linkValid = false;
              oneInvalid = true;
            }

            // Image
            if (imageList[i].includes(null)) {
              imageValid = false;
              oneInvalid = true;
            }
          }
        }

        if (!colorExist || oneInvalid) {
          allValid = false;
        }

        currentValid[key] = colorExist;
        currentValid.originalPrices = originalPriceValid;
        currentValid.salePrices = salePriceValid;
        currentValid.links = linkValid;
        currentValid.images = imageValid;
      }
      // [ Sizes ]
      else if (key === "minSize" || key === "maxSize") {
        const field =
          key === "minSize" ? minSize.current.value : maxSize.current.value;
        if (field === "") {
          allValid = false;
          currentValid[key] = false;
        } else {
          currentValid[key] = true;
        }
      }
      // [ Product name/model ]
      else if (key === "name" || key === "model") {
        const field = key === "name" ? name.current.value : model.current.value;
        if (field === "") {
          allValid = false;
          currentValid[key] = false;
        } else {
          currentValid[key] = true;
        }
      }
      // [ Shorts/Specs ]
      else if (key === "shorts" || key === "specs") {
        let check = true;
        const getState = {
          shorts: shorts,
          specs: specs,
        };

        for (const item of getState[key]) {
          // Shorts
          if (key === "shorts") {
            if (item === "") {
              check = false;
              break;
            }
          }
          // Specs
          if (key === "specs") {
            if (
              item.location === "" ||
              item.detail === "" ||
              item.advantage === ""
            ) {
              check = false;
              break;
            }
          }
        }

        if (!check) {
          allValid = false;
          currentValid[key] = false;
        } else {
          currentValid[key] = true;
        }
      }
    }
    setIsValid(currentValid);
    setUpdateValidation(true);

    return allValid;
  };
  // Check for valid http url
  const isValidHttpUrl = (string) => {
    if (string === "") {
      return true;
    }

    try {
      const url = new URL(string);
      return url.protocol === "http:" || url.protocol === "https:";
    } catch (err) {
      return false;
    }
  };
  // Check whether to display invalid input animation/message
  const determineInvalid = (field, index, key) => {
    if (field === "colorMenu") {
      return isValid.originalPrices &&
        isValid.salePrices &&
        isValid.links &&
        isValid.images
        ? ""
        : " invalid-input";
    } else if (field === "shorts") {
      return isValid[field] || shorts[index] !== "" ? "" : " invalid-input";
    } else if (field === "specs") {
      const getNum = {
        location: 0,
        detail: 1,
        advantage: 2,
      };
      let className = "";

      if (
        !(
          isValid[field] ||
          (specs[index].location !== "" &&
            specs[index].detail !== "" &&
            specs[index].advantage !== "")
        )
      ) {
        className += ` invalid-input invalid-input-col${getNum[key]}`;
        className +=
          index > 0 &&
          (specs[index].location === "" ||
            specs[index].detail === "" ||
            specs[index].advantage === "")
            ? " invalid-input-row"
            : "";
      }
      return className;
    } else {
      return isValid[field] ? "" : " invalid-input";
    }
  };
  const getInvalidMsg = (key, field) => {
    if (key === "colorMenu") {
      let allValid = true;
      let field = "";

      if (!isValid.originalPrices) {
        field += " 原價";
        allValid = false;
      }

      if (!isValid.salePrices) {
        field += field !== "" ? ", " : "";
        field += " 特價";
        allValid = false;
      }

      if (!isValid.links) {
        field += field !== "" ? ", " : "";
        field += " 連結";
        allValid = false;
      }

      if (!isValid.images) {
        field += field !== "" ? ", " : "";
        field += " 商品圖片";
        allValid = false;
      }

      return allValid ? "" : `您有至少一項 [ ${field} ] 沒輸入`;
    } else if (key === "sizes") {
      return isValid.minSize && isValid.maxSize ? "" : `請輸入${field}`;
    } else {
      return isValid[key] ? "" : `請輸入${field}`;
    }
  };

  // [ Product types ]
  // Generate product type dropdown list
  const getProductTypes = () => {
    const list = [];

    if (checkedType.length === category.length) {
      category.forEach((mainType, mainIdx) => {
        list.push(
          <a key={"dropdown_type_" + mainType.name} className="main-type">
            {mainType.name}
          </a>
        );
        mainType.type.forEach((item, typeIdx) => {
          list.push(
            <div
              key={"dropdown_type_" + mainIdx + "_" + typeIdx}
              className="flex v-center option"
              onClick={() => {
                setCheckedType((old) => {
                  old[mainIdx][typeIdx] = !old[mainIdx][typeIdx];
                  return old;
                });
                setUpdateCheckedType(true);
              }}
            >
              {checkedType[mainIdx][typeIdx] ? (
                <CgRadioChecked
                  key={"dropdown_type_icon_" + mainIdx + "_" + typeIdx}
                  style={{
                    color: checkedType[mainIdx][typeIdx]
                      ? "var(--sub1)"
                      : "var(--dark5)",
                  }}
                />
              ) : (
                <CgRadioCheck
                  key={"dropdown_type_icon_" + mainIdx + "_" + typeIdx}
                  style={{
                    color: checkedType[mainIdx][typeIdx]
                      ? "var(--sub1)"
                      : "var(--dark5)",
                  }}
                />
              )}
              <a
                key={"dropdown_type_text_" + mainIdx + "_" + typeIdx}
                style={{
                  color: checkedType[mainIdx][typeIdx]
                    ? "var(--sub1)"
                    : "var(--dark5)",
                }}
              >
                {item}
              </a>
            </div>
          );
        });
      });
    }

    return list;
  };
  // Get selected type list
  const getSelectedTypes = () => {
    let text = "";

    if (checkedType.length === category.length) {
      category.forEach((mainType, mainIdx) => {
        mainType.type.forEach((item, typeIdx) => {
          text +=
            text.length === 0 || !checkedType[mainIdx][typeIdx] ? "" : ", ";
          text += checkedType[mainIdx][typeIdx] ? item : "";
        });
      });
    }

    return text.length === 0 ? "--" : text;
  };

  // [ Colors ]
  // Generate color dropdown list
  const getColors = () => {
    const list = [];

    colors.forEach((item, index) => {
      list.push(
        <div
          key={"dropdown_color_" + index}
          className="flex v-center option"
          onClick={() => {
            let prevState = checkedColor[index];
            // Update color
            setCheckedColor((old) => {
              old[index] = !old[index];
              return old;
            });
            setUpdateCheckedColor(true);

            if (prevState) {
              // Update color index - search for next possible color index
              let nextIndex = 0;
              for (let i = 0; i < checkedColor.length; i++) {
                if (i !== index && checkedColor[i]) {
                  nextIndex = i;
                  break;
                }
              }
              setColorMenuIndex(nextIndex);

              // Updae price list
              setOriginalPrice(priceList[nextIndex].originalPrice);
              setSalePrice(priceList[nextIndex].salePrice);
              setPriceList((old) => {
                old[index] = { originalPrice: "", salePrice: "" };
                return old;
              });
              // Updae price list
              setRutenLink(linkList[nextIndex].rutenLink);
              setPcstoreLink(linkList[nextIndex].pcstoreLink);
              setShopeeLink(linkList[nextIndex].shopeeLink);
              setRakutenLink(linkList[nextIndex].rakutenLink);
              setMomoLink(linkList[nextIndex].momoLink);
              setLinkList((old) => {
                old[index] = {
                  rutenLink: "",
                  pcstoreLink: "",
                  shopeeLink: "",
                  rakutenLink: "",
                  momoLink: "",
                };
                return old;
              });
              // Update image list
              setImages(imageList[nextIndex]);
              setImageList((old) => {
                old[index] = [null];
                return old;
              });
              setPreviewImageList((old) => {
                old[index] = [null];
                return old;
              });
            } else {
              // Update color index
              setColorMenuIndex(index);
              // Updae price input
              setOriginalPrice(priceList[index].originalPrice);
              setSalePrice(priceList[index].salePrice);
              // Update link input
              setRutenLink(linkList[index].rutenLink);
              setPcstoreLink(linkList[index].pcstoreLink);
              setShopeeLink(linkList[index].shopeeLink);
              setRakutenLink(linkList[index].rakutenLink);
              setMomoLink(linkList[index].momoLink);
              // Update image inputs
              setImages(imageList[index]);
            }
          }}
        >
          {checkedColor[index] ? (
            <CgRadioChecked
              key={"dropdown_color_icon_" + index}
              style={{
                color: checkedColor[index] ? "var(--sub1)" : "var(--dark5)",
              }}
            />
          ) : (
            <CgRadioCheck
              key={"dropdown_color_icon_" + index}
              style={{
                color: checkedColor[index] ? "var(--sub1)" : "var(--dark5)",
              }}
            />
          )}
          <a
            key={"dropdown_color_text_" + index}
            style={{
              color: checkedColor[index] ? "var(--sub1)" : "var(--dark5)",
            }}
          >
            {item.name}
          </a>
          <a
            className="flex v-center detail"
            style={{
              color: checkedColor[index] ? "var(--sub1)" : "var(--dark5)",
            }}
          >
            {"("}
            <span
              className="flex h-center v-center color-code-box"
              style={{ backgroundColor: item.colorCode }}
            ></span>
            {item.colorCode + " )"}
          </a>
          {item.newColor && (
            <IoMdRemoveCircle
              className="delete"
              onClick={() =>
                setTimeout(() => {
                  setColors((old) => {
                    old.splice(index, 1);
                    return old;
                  });
                  setCheckedColor((old) => {
                    old.splice(index, 1);
                    return old;
                  });
                  setUpdateCheckedColor(true);
                  // Update color index - search for next possible color index
                  let nextIndex = 0;
                  for (let i = 0; i < checkedColor.length; i++) {
                    if (i !== index && checkedColor[i]) {
                      nextIndex = i;
                      break;
                    }
                  }
                  setColorMenuIndex(nextIndex);
                }, 30)
              }
            />
          )}
        </div>
      );
    });

    // Add new color
    list.push(
      <div key="dropdown_new_color" className="flex v-center option new-color">
        {checkedNewColor ? (
          <CgRadioChecked
            key="dropdown_new_color_icon"
            style={{
              color: checkedNewColor ? "var(--sub1)" : "var(--dark5)",
            }}
            onClick={() => setCheckedNewColor(!checkedNewColor)}
          />
        ) : (
          <CgRadioCheck
            key="dropdown_new_color_icon"
            style={{
              color: checkedNewColor ? "var(--sub1)" : "var(--dark5)",
            }}
            onClick={() => setCheckedNewColor(!checkedNewColor)}
          />
        )}
        <input
          type="text"
          placeholder="顏色"
          value={newColor.name}
          onChange={(e) => {
            setNewColor((old) => {
              old.name = e.target.value;
              return old;
            });
            setUpdateNewColor(true);
          }}
        />
        <input
          type="color"
          value={newColor.colorCode}
          onChange={(e) => {
            setNewColor((old) => {
              old.colorCode = e.target.value;
              return old;
            });
            setUpdateNewColor(true);
          }}
        />
        <a className="color-code">{newColor.colorCode}</a>
        <button onClick={addNewColor}>確認</button>
      </div>
    );

    return list;
  };
  // Get selected color list
  const getSelectedColors = () => {
    let text = "";

    colors.forEach((item, index) => {
      text += text.length === 0 || !checkedColor[index] ? "" : ", ";
      text += checkedColor[index] ? item.name : "";
    });

    return text.length === 0 ? "--" : text;
  };
  // Add new color
  const addNewColor = () => {
    if (newColor.name !== "") {
      // Add color
      setColorMenuIndex(colors.length);
      setColors([...colors, newColor]);
      setCheckedColor([...checkedColor, true]);
      setNewColor({
        name: "",
        colorCode: "#000000",
        newColor: true,
      });
      setCheckedNewColor(false);
      setUpdateCheckedColor(true);
      setUpdateNewColor(true);
      // Add price
      setOriginalPrice("");
      setSalePrice("");
      setPriceList([...priceList, { originalPrice: "", salePrice: "" }]);
      // Add link
      setRutenLink("");
      setPcstoreLink("");
      setShopeeLink("");
      setRakutenLink("");
      setMomoLink("");
      setLinkList([
        ...linkList,
        {
          rutenLink: "",
          pcstoreLink: "",
          shopeeLink: "",
          rakutenLink: "",
          momoLink: "",
        },
      ]);
      // Add image
      setImages([null]);
      setImageList([...imageList, [null]]);
      setPreviewImageList([...previewImageList, [null]]);
    }
  };

  // Get image inputs
  const getImageInputs = () => {
    const list = [];

    images.forEach((item, index) => {
      list.push(
        <div
          key={"image_div_" + index}
          className="flex v-center image-input-container"
        >
          <input
            key={"image_" + index}
            type="file"
            id={"image-input" + index}
            accept="image/*"
            onChange={(e) => {
              const newImages = images;
              newImages[index] = e.target.files[0];
              setImages(newImages);
              setImageList((old) => {
                old[colorMenuIndex] = newImages;
                return old;
              });
              setUpdateImage(true);

              const newFile = new File(
                [e.target.files[0]],
                e.target.files[0].name,
                { type: e.target.files[0].type }
              );
              setPreviewImageList((old) => {
                old[colorMenuIndex][index] = URL.createObjectURL(newFile);
                return old;
              });
              setUpdatePreviewImage(true);
            }}
          />
          {previewImageList[colorMenuIndex][index] && (
            <img src={previewImageList[colorMenuIndex][index]} alt="image" />
          )}
          {images.length > 1 && (
            <a
              key={"image_delete" + index}
              className="delete"
              onClick={() => {
                console.log(imageList);
                const newImages = images;
                newImages.splice(index, 1);
                setImages(newImages);
                setImageList((old) => {
                  old[colorMenuIndex] = newImages;
                  return old;
                });
                setUpdateImage(true);
                setPreviewImageList((old) => {
                  old[colorMenuIndex].splice(index, 1);
                  return old;
                });
                setUpdatePreviewImage(true);
                // Reset file input
                document.getElementById("image-input" + index).value = null;
              }}
            >
              刪除
            </a>
          )}
        </div>
      );
    });

    return list;
  };
  const getOtherPicInputs = () => {
    const list = [];

    otherPics.forEach((item, index) => {
      list.push(
        <div
          key={"other_pic_div_" + index}
          className="flex v-center image-input-container"
        >
          <input
            key={"other_pic_" + index}
            type="file"
            id={"other-pic-input" + index}
            accept="image/*"
            onChange={(e) => {
              const newImages = otherPics;
              newImages[index] = e.target.files[0];
              setOtherPics(newImages);

              const newFile = new File(
                [e.target.files[0]],
                e.target.files[0].name,
                { type: e.target.files[0].type }
              );
              setPreviewOtherPics((old) => {
                old[index] = URL.createObjectURL(newFile);
                return old;
              });
              setUpdatePreviewOtherPic(true);
            }}
          />
          {previewOtherPics[index] && (
            <img src={previewOtherPics[index]} alt="image" />
          )}
          {otherPics.length === 1 && otherPics[0] !== null && (
            <a
              key={"other_pic_delete" + index}
              className="delete"
              onClick={() => {
                setOtherPics([null]);
                setPreviewOtherPics([null]);
                setUpdatePreviewOtherPic(true);
                // Reset file input
                document.getElementById("other-pic-input" + index).value = null;
              }}
            >
              刪除
            </a>
          )}
          {otherPics.length > 1 && (
            <a
              key={"other_pic_delete" + index}
              className="delete"
              onClick={() => {
                const newImages = otherPics;
                newImages.splice(index, 1);
                setOtherPics(newImages);
                setPreviewOtherPics((old) => {
                  old.splice(index, 1);
                  return old;
                });
                setUpdatePreviewOtherPic(true);
                // Reset file input
                document.getElementById("other-pic-input" + index).value = null;
              }}
            >
              刪除
            </a>
          )}
        </div>
      );
    });

    return list;
  };
  // Ellipsis image path text
  const ellipisisStyle = (text) => {
    if (text.length > 15) {
      return (
        text.substr(0, 7) + "..." + text.substr(text.length - 7, text.length)
      );
    }
    return text;
  };

  // Get product menu
  const getProductColorMenu = () => {
    const list = [];

    for (let i = 0; i < colors.length; i++) {
      list.push(
        <a
          key={"color_menu_" + i}
          style={{
            display: checkedColor[i] ? "block" : "none",
            color: i === colorMenuIndex ? "var(--red)" : "var(--dark5)",
            borderColor: i === colorMenuIndex ? "var(--red)" : "var(--dark5)",
            backgroundColor:
              i === colorMenuIndex ? "var(--red-2)" : "var(--dark4)",
          }}
          onClick={() => {
            // Update color index
            setColorMenuIndex(i);
            // Update price input
            setOriginalPrice(priceList[i].originalPrice);
            setSalePrice(priceList[i].salePrice);
            // Update link input
            setRutenLink(linkList[i].rutenLink);
            setPcstoreLink(linkList[i].pcstoreLink);
            setShopeeLink(linkList[i].shopeeLink);
            setRakutenLink(linkList[i].rakutenLink);
            setMomoLink(linkList[i].momoLink);
            // Update image list
            setImages(imageList[i]);
            imageList[i].forEach((item, index) => {
              if (document.getElementById("file-name" + index)) {
                document.getElementById("file-name" + index).value =
                  item instanceof File ? ellipisisStyle(item.name) : item;
              }
            });
          }}
        >
          {colors[i].name}
        </a>
      );
    }

    return list;
  };

  // [ Product deals ]
  // Generate product deal dropdown list
  const getProductDeals = () => {
    const list = [];

    if (checkedDeal.length === deals.length) {
      deals.forEach((item, idx) => {
        list.push(
          <div
            key={"dropdown_deal_" + idx}
            className="flex v-center option"
            onClick={() => {
              setCheckedDeal((old) => {
                old[idx] = !old[idx];
                return old;
              });
              setUpdateCheckedDeal(true);
            }}
          >
            {checkedDeal[idx] ? (
              <CgRadioChecked
                key={"dropdown_deal_icon_" + idx}
                style={{
                  color: checkedDeal[idx] ? "var(--sub1)" : "var(--dark5)",
                }}
              />
            ) : (
              <CgRadioCheck
                key={"dropdown_deal_icon_" + idx}
                style={{
                  color: checkedDeal[idx] ? "var(--sub1)" : "var(--dark5)",
                }}
              />
            )}
            <a
              key={"dropdown_deal_text_" + idx}
              style={{
                color: checkedDeal[idx] ? "var(--sub1)" : "var(--dark5)",
              }}
            >
              {item.name}
            </a>
            {item.newDeal && (
              <IoMdRemoveCircle
                className="delete"
                onClick={() =>
                  setTimeout(() => {
                    setDeals((old) => {
                      old.splice(idx, 1);
                      return old;
                    });
                    setCheckedDeal((old) => {
                      old.splice(idx, 1);
                      return old;
                    });
                    setUpdateCheckedDeal(true);
                  }, 30)
                }
              />
            )}
          </div>
        );
      });
    }

    // New deal
    list.push(
      <div key="dropdown_new_deal" className="flex v-center option new-color">
        {checkedNewDeal ? (
          <CgRadioChecked
            key="dropdown_new_deal_icon"
            style={{
              color: checkedNewDeal ? "var(--sub1)" : "var(--dark5)",
            }}
            onClick={() => setCheckedNewDeal(!checkedNewDeal)}
          />
        ) : (
          <CgRadioCheck
            key="dropdown_new_deal_icon"
            style={{
              color: checkedNewDeal ? "var(--sub1)" : "var(--dark5)",
            }}
            onClick={() => setCheckedNewDeal(!checkedNewDeal)}
          />
        )}
        {/* <input
          id="deal-input"
          type="text"
          placeholder="活動/折扣"
          value={newDeal.text}
          onChange={(e) => {
            setNewDeal({
              name: e.target.value,
              newDeal: true,
            });
          }}
        /> */}
        <button onClick={addNewDeal}>確認</button>
      </div>
    );

    return list;
  };
  // Get selected deal list
  const getSelectedDeals = () => {
    let text = "";

    if (checkedDeal.length === deals.length) {
      deals.forEach((item, idx) => {
        text += text.length === 0 || !checkedDeal[idx] ? "" : ", ";
        text += checkedDeal[idx] ? item.name : "";
      });
    }

    return text.length === 0 ? "--" : text;
  };
  // Add new deal
  const addNewDeal = () => {
    if (newDeal.name !== "") {
      setDeals([...deals, newDeal]);
      setCheckedDeal([...checkedDeal, true]);
      setNewDeal({
        name: "",
        newDeal: true,
      });
      setCheckedNewDeal(false);
      setUpdateCheckedDeal(true);
      document.getElementById("deal-input").value = null;
    }
  };

  // [ Shorts ]
  const getShortInputs = () => {
    const list = [];

    shorts.forEach((item, index) => {
      list.push(
        <div key={"short_input_div_" + index} className="flex two-inputs">
          <input
            key={"short_bullet_" + index}
            value="★"
            className={determineInvalid("shorts", index)}
            disabled
          />
          <input
            key={"short_input_" + index}
            placeholder="ex. 輕量化玻纖鋼頭"
            value={item}
            className={determineInvalid("shorts", index)}
            onChange={(e) => {
              setShorts((old) => {
                old[index] = e.target.value;
                return old;
              });
              setUpdateShorts(true);
            }}
          />
          {index + 1 === shorts.length ? (
            <MdLibraryAdd
              className="icon"
              onClick={() => {
                setShorts([...shorts, ""]);
                setUpdateShorts(true);
              }}
            />
          ) : (
            <MdRemove
              className="icon"
              onClick={() => {
                setShorts((old) => {
                  old.splice(index, 1);
                  return old;
                });
                setUpdateShorts(true);
              }}
            />
          )}
        </div>
      );
    });

    return list;
  };

  // Show dynamic inputs
  const showDynamicInputs = () => {
    let size = 0;
    checkedColor.forEach((item) => {
      size += item ? 1 : 0;
    });

    return size > 0;
  };

  // Get product specificationss
  const getProductSpecs = () => {
    const list = [];

    list.push(
      <div key="spec_title_row" className="flex v-center added-spec-title">
        <a key="spec_title_location">部位</a>
        <a key="spec_title_detail">材質說明</a>
        <a key="spec_title_advantage">優點</a>
      </div>
    );

    specs.forEach((item, index) => {
      list.push(
        <div key={"spec_row_" + index} className="flex v-center added-spec">
          <input
            key={"spec_location_" + index}
            className={determineInvalid("specs", index, "location")}
            value={item.location}
          />
          <input
            key={"spec_detail_" + index}
            className={determineInvalid("specs", index, "detail")}
            value={item.detail}
          />
          <input
            key={"spec_advantage_" + index}
            className={determineInvalid("specs", index, "advantage")}
            value={item.advantage}
          />
          <IoMdRemoveCircle
            className="delete"
            onClick={() => {
              setSpecs((old) => {
                old.splice(index, 1);
                return old;
              });
              setUpdateSpecs(true);
            }}
          />
        </div>
      );
    });

    // New spec
    list.push(
      <div key="spec_new_row_" className="flex v-center new-spec-row">
        <input
          placeholder="部位"
          value={newSpec.location}
          onChange={(e) => {
            setNewSpec((old) => {
              old.location = e.target.value;
              return old;
            });
            setUpdateSpecs(true);
          }}
        />
        <input
          placeholder="材質說明"
          value={newSpec.detail}
          onChange={(e) => {
            setNewSpec((old) => {
              old.detail = e.target.value;
              return old;
            });
            setUpdateSpecs(true);
          }}
        />
        <input
          placeholder="優點"
          value={newSpec.advantage}
          onChange={(e) => {
            setNewSpec((old) => {
              old.advantage = e.target.value;
              return old;
            });
            setUpdateSpecs(true);
          }}
        />
        <IoMdAddCircle
          className="add"
          onClick={() => {
            setSpecs([...specs, newSpec]);
            setNewSpec({
              location: "",
              detail: "",
              advantage: "",
              newSpec: true,
            });
            setUpdateSpecs(true);
          }}
        />
      </div>
    );

    return list;
  };

  // Convert blob url to file
  async function convertBlobUrlToFile(blobUrl, filename, index) {
    let convertedFile = null;

    await fetch(blobUrl)
      .then((response) => response.blob())
      .then((blob) => {
        convertedFile = new File([blob], filename + ".png", {
          type: blob.type,
        });
        index++;
      })
      .catch((error) => {
        console.error("Error retrieving Blob:", error);
      });

    return convertedFile;
  }

  return (
    <div id="management-add-product" className="flex h-center v-center modal">
      {!addSuccess && (
        <div className="flex-column modal-content">
          <CgClose
            className="icon close"
            onClick={() => props.setShowAddProduct(false)}
          />
          <h2>產品資料</h2>
          <div className="flex h-between row">
            {/* 商品名稱 */}
            <div className="flex-column v-start column">
              <a className="title">
                商品名稱<span>*</span>
              </a>
              <input ref={name} className={determineInvalid("name")} />
              <a className="v-self-center invalid-msg">
                {getInvalidMsg("name", "商品名稱")}
              </a>
            </div>
            {/* 型號 */}
            <div className="flex-column v-start column">
              <a className="title">
                型號<span>*</span>
              </a>
              <input ref={model} className={determineInvalid("model")} />
              <a className="v-self-center invalid-msg">
                {getInvalidMsg("model", "型號")}
              </a>
            </div>
          </div>
          <div className="flex h-between row">
            {/* 產品分類 */}
            <div className="flex-column column">
              <div className="flex-column v-start dropdown">
                <a className="title">
                  產品分類<span>*</span>
                </a>
                <div
                  className={"flex dropdown-text" + determineInvalid("type")}
                  style={{
                    borderRadius: !showTypeDropdown ? "5px" : "5px 5px 0 0",
                  }}
                  onClick={() => setShowTypeDropdown(!showTypeDropdown)}
                >
                  <a>{getSelectedTypes()}</a>
                  {!showTypeDropdown ? <AiFillCaretDown /> : <AiFillCaretUp />}
                </div>
                <div
                  className="dropdown-content"
                  style={{ display: showTypeDropdown ? "flex" : "none" }}
                >
                  {getProductTypes()}
                </div>
              </div>
              <a className="v-self-center invalid-msg">
                {getInvalidMsg("type", "產品分類")}
              </a>
            </div>
            {/* 顏色 */}
            <div className="flex-column column">
              <div className="flex-column v-start dropdown colors">
                <a className="title">
                  顏色<span>*</span>
                </a>
                <div
                  className={"flex dropdown-text" + determineInvalid("color")}
                  style={{
                    borderRadius: !showColorDropdown ? "5px" : "5px 5px 0 0",
                  }}
                  onClick={() => setShowColorDropdown(!showColorDropdown)}
                >
                  <a>{getSelectedColors()}</a>
                  {!showColorDropdown ? <AiFillCaretDown /> : <AiFillCaretUp />}
                </div>
                <div
                  className="dropdown-content"
                  style={{ display: showColorDropdown ? "flex" : "none" }}
                >
                  {getColors()}
                </div>
              </div>
              <a className="v-self-center invalid-msg">
                {getInvalidMsg("color", "顏色")}
              </a>
            </div>
          </div>
          {showDynamicInputs() && (
            <div
              className={
                "flex-column product-container" + determineInvalid("colorMenu")
              }
            >
              <div className="flex h-between row">
                {/* 原價 */}
                <div className="flex-column v-start column">
                  <a className="title">
                    原價<span>*</span>
                  </a>
                  <div className="flex two-inputs price">
                    <input value="NT$" disabled />
                    <input
                      type="number"
                      value={originalPrice}
                      onChange={(e) => {
                        setOriginalPrice(e.target.value);
                        setPriceList((old) => {
                          old[colorMenuIndex].originalPrice = e.target.value;
                          return old;
                        });
                      }}
                    />
                  </div>
                </div>
                {/* 特價 */}
                <div className="flex-column v-start column">
                  <a className="title">
                    特價<span>*</span>
                  </a>
                  <div className="flex two-inputs price">
                    <input value="NT$" disabled />
                    <input
                      type="number"
                      value={salePrice}
                      onChange={(e) => {
                        setSalePrice(e.target.value);
                        setPriceList((old) => {
                          old[colorMenuIndex].salePrice = e.target.value;
                          return old;
                        });
                      }}
                    />
                  </div>
                </div>
              </div>
              <a className="v-self-center invalid-msg"></a>
              <div className="flex-column links">
                <div className="flex h-between row">
                  {/* 連結 - 露天拍賣 */}
                  <div className="flex-column v-start column">
                    <a className="title">露天拍賣連結</a>
                    <input
                      placeholder="https://"
                      value={rutenLink}
                      onChange={(e) => {
                        setRutenLink(e.target.value);
                        setLinkList((old) => {
                          old[colorMenuIndex].rutenLink = e.target.value;
                          return old;
                        });
                      }}
                    />
                  </div>
                  {/* 連結 - 蝦皮拍賣 */}
                  <div className="flex-column v-start column">
                    <a className="title">蝦皮拍賣連結</a>
                    <input
                      placeholder="https://"
                      value={shopeeLink}
                      onChange={(e) => {
                        setShopeeLink(e.target.value);
                        setLinkList((old) => {
                          old[colorMenuIndex].shopeeLink = e.target.value;
                          return old;
                        });
                      }}
                    />
                  </div>
                </div>
                <div className="flex h-between row">
                  {/* 連結 - PChome商店街 */}
                  <div className="flex-column v-start column">
                    <a className="title">PChome商店街連結</a>
                    <input
                      placeholder="https://"
                      value={pcstoreLink}
                      onChange={(e) => {
                        setPcstoreLink(e.target.value);
                        setLinkList((old) => {
                          old[colorMenuIndex].pcstoreLink = e.target.value;
                          return old;
                        });
                      }}
                    />
                  </div>
                  {/* 連結 - momoe購物網 */}
                  <div className="flex-column v-start column">
                    <a className="title">momoe購物網連結</a>
                    <input
                      placeholder="https://"
                      value={momoLink}
                      onChange={(e) => {
                        setMomoLink(e.target.value);
                        setLinkList((old) => {
                          old[colorMenuIndex].momoLink = e.target.value;
                          return old;
                        });
                      }}
                    />
                  </div>
                </div>
                <div className="flex h-between row">
                  {/* 連結 - 樂天市場 */}
                  <div className="flex-column v-start column">
                    <a className="title">樂天連結</a>
                    <input
                      placeholder="https://"
                      value={rakutenLink}
                      onChange={(e) => {
                        setRakutenLink(e.target.value);
                        setLinkList((old) => {
                          old[colorMenuIndex].rakutenLink = e.target.value;
                          return old;
                        });
                      }}
                    />
                  </div>
                </div>
                <a className="v-self-center invalid-msg"></a>
              </div>
              <div className="flex h-between row">
                {/* 商品圖片 */}
                <div className="flex-column v-start column one-input">
                  <a className="title">
                    商品圖片<span>*</span>
                  </a>
                  <div className="flex-column image-inputs">
                    {getImageInputs()}
                  </div>
                  <a
                    className="add-more-item"
                    onClick={() => {
                      setImages([...images, null]);
                      setImageList((old) => {
                        old[colorMenuIndex] = [...images, null];
                        return old;
                      });
                      setPreviewImageList((old) => {
                        old[colorMenuIndex] = [
                          ...previewImageList[colorMenuIndex],
                          null,
                        ];
                        return old;
                      });
                    }}
                  >
                    + <span>增加更多圖片</span>
                  </a>
                </div>
              </div>
            </div>
          )}
          {/* Color menu */}
          <div className="flex h-center color-menu">
            {getProductColorMenu()}
          </div>
          <a
            className="v-self-center invalid-msg color-menu-invalid"
            style={{
              marginTop: showDynamicInputs() ? "5px" : "-26px",
            }}
          >
            {getInvalidMsg("colorMenu")}
          </a>
          <div className="flex h-between row">
            {/* 活動/折扣 */}
            {/* <div className="flex-column v-start column one-input dropdown">
              <a className="title">活動 / 折扣</a>
              <div
                className="flex dropdown-text"
                style={{
                  borderRadius: !showDealDropdown ? "5px" : "5px 5px 0 0",
                }}
                onClick={() => setShowDealDropdown(!showDealDropdown)}
              >
                <a>{getSelectedDeals()}</a>
                {!showDealDropdown ? <AiFillCaretDown /> : <AiFillCaretUp />}
              </div>
              <div
                className="dropdown-content deals"
                style={{ display: showDealDropdown ? "flex" : "none" }}
              >
                {getProductDeals()}
              </div>
            </div> */}
          </div>
          <a className="v-self-center invalid-msg"></a>
          {/* 尺寸 */}
          {/* <div className="flex-column v-start row sizes">
            <a className="title">
              尺寸<span>*</span>
            </a>
            <div className="flex h-between w-100">
              <div className="column">
                <input
                  type="number"
                  placeholder="最小尺寸 (ex. 24.0)"
                  ref={minSize}
                  className={determineInvalid("minSize")}
                />
              </div>
              <a>-</a>
              <div className="column">
                <input
                  type="number"
                  placeholder="最大尺寸 (ex. 28.5)"
                  ref={maxSize}
                  className={determineInvalid("maxSize")}
                />
              </div>
            </div>
            <a className="v-self-center invalid-msg">
              {getInvalidMsg("sizes", "尺寸")}
            </a>
          </div> */}
          <div className="flex h-between row shorts">
            {/* 商品特點 */}
            <div className="flex-column v-start column one-input">
              <a className="title">
                商品特點<span>*</span>
              </a>
              {getShortInputs()}
              <a className="v-self-center invalid-msg">
                {getInvalidMsg("shorts", "商品特點")}
              </a>
            </div>
          </div>
          {/* 商品規格 */}
          <div className="flex-column v-start specs">
            <a className="title">商品規格:</a>
            {getProductSpecs()}
            <a className="v-self-center invalid-msg">
              {getInvalidMsg("specs", "商品規格")}
            </a>
          </div>
          {/* 其他圖片 */}
          <div className="flex-column v-start column one-input other-pics">
            <a className="title">其他圖片</a>
            <div className="flex-column image-inputs">
              {getOtherPicInputs()}
            </div>
            <a
              className="add-more-item"
              onClick={() => {
                setOtherPics([...otherPics, null]);
                setPreviewOtherPics((old) => {
                  old = [...previewOtherPics, null];
                  return old;
                });
              }}
            >
              + <span>增加更多圖片</span>
            </a>
          </div>
          <button className="button1 add-product" onClick={handleSubmit}>
            添加商品
          </button>
        </div>
      )}
      {addSuccess && (
        <div className="flex-column h-center v-center modal-content success-pop-up">
          <a>商品添加成功</a>
          <button
            className="button1 add-product"
            onClick={() => {
              props.setShowAddProduct(false);
              props.setShouldRefresh(true);
            }}
          >
            確認
          </button>
        </div>
      )}
    </div>
  );
}
