import React from "react";
import { useNavigate } from "react-router-dom";
// Footer
import Footer from "../../widgets/Footer/Footer";
// Images
import img1 from "../../files/images/home-1.jpg";
import img2 from "../../files/images/home-2.jpg";
import img3 from "../../files/images/home-3.jpg";
import img_banner from "../../files/images/home-banner.png";
// Icons
import { FcCheckmark } from "react-icons/fc";
// Css
import "./home.scss";

export default function Home() {
  const navigate = useNavigate();

  const goCategoryPage = (category) => {
    navigate("/product-list/" + category);
  };

  return (
    <div id="page-home" className="flex-column v-center page">
      <div className="flex-column v-center page-content">
        {/* Top banner */}
        <div className="flex h-between v-start main">
          <div className="flex-column h-center text-block">
            <a className="text1">TOPING</a>
            <a className="text2">安全鞋 • 生活鞋</a>
            <a className="text3">你安心穿的唯一選擇</a>
            <button
              className="button1"
              onClick={() => goCategoryPage("所有商品")}
            >
              馬上看看
            </button>
          </div>
          <img src={img_banner} className="banner" />
        </div>
        {/* Intro */}
        <div className="flex-column v-center intro">
          <div className="flex-column v-center main-text">
            <h2>您的每一步，由我來守護</h2>
            <a>
              自1972年以來，得裕盛業堅守企業誠信的文化經營，並透過「純手工」產品與「品質保障」，悉心完成客戶的每一雙「使命」。
              Toping 專業安全鞋，以守護者為志，保護您每一步的安全。
            </a>
          </div>
          <div className="flex text-cont">
            <div className="flex v-center text">
              <FcCheckmark />
              <a>真台灣原廠製造</a>
            </div>
            <div className="flex v-center text">
              <FcCheckmark />
              <a>符合CNS國家標準規範</a>
            </div>
            <div className="flex v-center text">
              <FcCheckmark />
              <a>材質皆通ISO認證</a>
            </div>
            <div className="flex v-center text">
              <FcCheckmark />
              <a>安全可靠</a>
            </div>
          </div>
        </div>
        {/* Category - 專業安全鞋/舒適生活鞋/器材與用品 */}
        <div className="flex h-between v-center category">
          <div
            className="flex-column v-center"
            onClick={() => goCategoryPage("專業安全鞋")}
          >
            <img src={img1}></img>
            <a>專業安全鞋</a>
          </div>
          <div
            className="flex-column v-center"
            onClick={() => goCategoryPage("舒適生活鞋")}
          >
            <img src={img2}></img>
            <a>舒適生活鞋</a>
          </div>
          <div
            className="flex-column v-center"
            onClick={() => goCategoryPage("其他")}
          >
            <img src={img3}></img>
            <a>器材與用品</a>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}
