import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Buffer } from "buffer";
// Footer
import Footer from "../../widgets/Footer/Footer";
// Category
import { category } from "../Data";
// Icons
import { TbCaretRight } from "react-icons/tb";
import { AiFillCaretDown, AiFillCaretUp } from "react-icons/ai";
import { BiSortDown, BiSortUp } from "react-icons/bi";
// Css
import "./productList.scss";

export default function ProductList(props) {
  const navigate = useNavigate();
  // [ Sort ]
  const [sort, setSort] = useState("產品型號");
  const [sortAsc, setSortAsc] = useState(true);
  // [ Dropdown ]
  const [showDropdown, setShowDropdown] = useState(false);
  // [ Products ]
  const [products, setProducts] = useState(new Map());
  const [updateImageIndex, setUpdateImageIndex] = useState(false);
  const [showProducts, setShowProducts] = useState([]);
  // [ Redirect ]
  const [productId, setProductId] = useState(null);
  const [productSelected, setProductSelected] = useState(null);
  const [clickColor, setClickColor] = useState(false);

  useEffect(() => {
    const data = getProductList(props.searchResult);
    setProducts(data);
    filterProducts(data);
  }, []);
  useEffect(() => {
    // Update image index
    if (updateImageIndex) {
      setUpdateImageIndex(false);
    }

    // Update search input
    if (props.updateSearch) {
      const data = getProductList(props.searchResult);
      setProducts(data);
      filterProducts(data);

      props.setUpdateSearch(false);
    }
  }, [updateImageIndex, props.updateSearch]);
  useEffect(() => {
    if (productId && clickColor) {
      setProductId(null);
      setClickColor(false);
    } else if (productId && !clickColor) {
      goProductInfoPage();
    }
  }, [productId]);

  // Get product list
  const getProductList = (data) => {
    const productMap = new Map();

    // Product colors, price, and sale
    for (let i = 0; i < data.length; i++) {
      const { productId, name, model, color, code, price, sale, image } =
        data[i];

      if (!productMap.has(productId)) {
        productMap.set(productId, {
          name: name,
          model: model,
          color: [color],
          code: [code],
          price: [price],
          sale: [sale],
          image: [image],
          imageIndex: 0,
        });
      } else {
        const old = productMap.get(productId);
        productMap.set(productId, {
          name: name,
          model: model,
          color: [...old.color, color],
          code: [...old.code, code],
          price: [...old.price, price],
          sale: [...old.sale, sale],
          image: [...old.image, image],
          imageIndex: 0,
        });
      }
    }

    return productMap;
  };
  // Filter products
  const filterProducts = (data) => {
    let list = [];

    for (const [key, value] of data.entries()) {
      value.id = key;
      list.push(value);
    }

    sortProducts(list === undefined ? [] : list, sort, sortAsc);
  };
  // Sort products
  const sortProducts = (data, currentSort, currentAsc) => {
    const field = {
      產品型號: "model",
      產品價格: "sale",
    };
    const fieldName = field[currentSort];
    let sortedData = null;

    if (fieldName === "sale") {
      sortedData = data.sort((a, b) => {
        const priceA = Math.min(...a[fieldName]);
        const priceB = Math.min(...b[fieldName]);

        if (priceA === priceB) {
          return 0;
        }
        return currentAsc ? priceA - priceB : priceB - priceA;
      });
    } else {
      sortedData = data.sort((a, b) => {
        if (a[fieldName] === b[fieldName]) {
          return 0;
        }

        if (currentAsc) {
          return a[fieldName] < b[fieldName] ? -1 : 1;
        } else {
          return b[fieldName] < a[fieldName] ? -1 : 1;
        }
      });
    }

    setShowProducts(sortedData);
  };

  // Redirect to product info page
  const goProductInfoPage = () => {
    navigate(`/product/${productId}/${productSelected}`);
  };

  // Get category
  const getCategory = () => {
    const list = [];

    for (let i = 0; i < category.length; i++) {
      const subList = [];
      const { type } = category[i];

      for (let j = 0; j < type.length; j++) {
        subList.push(
          <a
            key={"type" + i + "_" + j}
            className="flex v-center sub-list"
            onClick={() => {
              navigate("/product-list/" + type[j]);
            }}
          >
            <TbCaretRight /> {type[j]}
          </a>
        );
      }

      list.push(
        <div key={"category_div" + i} className="flex-column">
          <a
            key={"category_text" + i}
            className="main-list"
            onClick={() => {
              navigate("/product-list/" + category[i].name);
            }}
          >
            {category[i].name}
          </a>
          {subList}
        </div>
      );
    }

    return list;
  };

  // List products
  const listProducts = () => {
    const list = [];

    for (let i = 0; i < showProducts.length; i++) {
      const { id, name, model, price, sale, code, image, imageIndex } =
        showProducts[i];
      const number = "(型號: " + model + ")";

      // Get lowest sale price
      let minIdx = null;
      let min = null;
      for (let j = 0; j < price.length; j++) {
        if (j === 0 || price[j] < min) {
          minIdx = j;
          min = price[j];
        }
      }

      // List all colors
      const colorDivs = [];
      for (let j = 0; j < code.length; j++) {
        colorDivs.push(
          <div
            key={"product_color" + i + "_" + j}
            style={{ backgroundColor: code[j] }}
            className="color"
            onMouseOver={() => {
              setShowProducts((old) => {
                old[i].imageIndex = j;
                return old;
              });
              setUpdateImageIndex(true);
            }}
            onClick={() => {
              setClickColor(true);
            }}
          ></div>
        );
      }

      // Convert image
      const base64String = new Buffer.from(image[imageIndex]).toString(
        "base64"
      );

      list.push(
        <div
          key={"product_div" + i}
          className="flex-column v-center product"
          to="/product"
          onClick={() => {
            setProductId(id);
            setProductSelected(imageIndex);
          }}
        >
          <div key={"product_img" + i} className="product-img">
            <img src={`data:image/png;base64, ${base64String}`} />
          </div>
          <a key={"product_name" + i} className="product-name">
            {name}
            <span>{number}</span>
          </a>
          <div key={"product_price_div" + i} className="flex">
            <a
              key={"product_price" + i}
              className="product-price"
              style={{
                textDecoration:
                  price[minIdx] !== sale[minIdx] ? "line-through" : "none",
                opacity: price[minIdx] !== sale[minIdx] ? 0.5 : 1,
              }}
            >
              NT${price[minIdx]}
            </a>
            {price[minIdx] !== sale[minIdx] && (
              <a key={"product_sale" + i} className="product-sale">
                NT${sale[minIdx]}
              </a>
            )}
          </div>
          <div key={"product_color_div" + i} className="flex color_container">
            {colorDivs}
          </div>
        </div>
      );
    }

    return list;
  };

  return (
    <div id="page-product-list" className="flex-column v-center page">
      <div className="flex page-content">
        {/* Category */}
        <div className="flex-column side-bar">
          <div className="flex-column v-center category">{getCategory()}</div>
        </div>
        <div className="flex-column list">
          <div className="flex v-center h-between title-row">
            <a className="category-select">搜尋結果</a>
            {/* Sort */}
            <div className="flex h-end v-center sort">
              <a>排序方式:</a>
              <div className="flex-column dropdown">
                <div
                  className="flex h-between v-center dropdown-text"
                  onClick={() => setShowDropdown(!showDropdown)}
                >
                  <a id="dropdown-text">{sort}</a>
                  {showDropdown ? (
                    <AiFillCaretUp id="dropdown-icon" />
                  ) : (
                    <AiFillCaretDown id="dropdown-icon" />
                  )}
                  <div
                    style={{
                      display: showDropdown ? "flex" : "none",
                    }}
                    id="dropdown-content"
                    className="v-center dropdown-content"
                  >
                    <a
                      onClick={() => {
                        setSort("產品型號");
                        sortProducts(showProducts, "產品型號", sortAsc);
                        setShowDropdown(!showDropdown);
                      }}
                    >
                      產品型號
                    </a>
                    <a
                      onClick={() => {
                        setSort("產品價格");
                        sortProducts(showProducts, "產品價格", sortAsc);
                        setShowDropdown(!showDropdown);
                      }}
                    >
                      產品價格
                    </a>
                  </div>
                </div>
              </div>
              <div className="flex v-center sort-icon">
                {sortAsc ? (
                  <BiSortDown
                    onClick={() => {
                      sortProducts(showProducts, sort, !sortAsc);
                      setSortAsc(!sortAsc);
                    }}
                  />
                ) : (
                  <BiSortUp
                    onClick={() => {
                      sortProducts(showProducts, sort, !sortAsc);
                      setSortAsc(!sortAsc);
                    }}
                  />
                )}
              </div>
            </div>
          </div>
          {/* List of products */}
          <div className="h-around product-list">{listProducts()}</div>
        </div>
      </div>
      <Footer />
    </div>
  );
}
