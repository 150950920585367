import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import { Buffer } from "buffer";
// Footer
import Footer from "../../widgets/Footer/Footer";
// Icons
import { BsTrash } from "react-icons/bs";
import { FaTimes, FaEquals } from "react-icons/fa";
// Css
import "./cart.css";

export default function Cart(props) {
  // Cart
  const [cart, setCart] = useState([]);
  const [updateQuantity, setUpdateQuantity] = useState(false);
  const [updateField, setUpdateField] = useState(false);
  // Summary
  const [save, setSave] = useState(0);
  const [total, setTotal] = useState(0);

  useEffect(() => {
    callGetCartEndpoint();
  }, []);

  useEffect(() => {
    if (props.refreshCart) {
      callGetCartEndpoint();
    }

    if (updateField) {
      callUpdateCartEndpoint();
      setUpdateField(false);
    }

    if (updateQuantity) {
      setUpdateQuantity(false);
    }
  }, [props.refreshCart, updateQuantity, updateField]);

  // Endpoint
  async function callGetCartEndpoint() {
    const cartId = getCartFromCookie();

    if (cartId) {
      await axios
        .get(`${process.env.REACT_APP_API}/carts/get?`, {
          params: { cartId },
        }, {
          headers: { "x-api-key": process.env.REACT_APP_API_KEY },
        })
        .then((res) => {
          if (res.status === 200) {
            let totalAmount = 0;
            let saveAmount = 0;

            // Set cart list state
            setCart(res.data);

            // Set quantity & total amount for each item
            res.data.forEach((item) => {
              totalAmount += item.quantity * item.sale;
              saveAmount += (item.price - item.sale) * item.quantity;
            });

            // Set total & save amount
            setTotal(totalAmount);
            setSave(saveAmount);

            // Update cart quantity
            props.setCartQuantity(res.data.length);
          }
        })
        .catch(() => {});
    } else {
      setCart([]);
    }
  }
  async function callUpdateCartEndpoint() {
    const cartId = getCartFromCookie();
    const expires = new Date(new Date().getTime() + 7 * 24 * 60 * 60 * 1000);

    await axios
      .post(`${process.env.REACT_APP_API}/carts/update`, {
        cartId,
        cart,
        expires,
      }, {
        headers: { "x-api-key": process.env.REACT_APP_API_KEY },
      })
      .then((res) => {
        if (res.status === 200) {
          // Update cart quantity
          props.setCartQuantity(res.data.length);
          // Update refresh cart state
          props.setRefreshCart(true);
        }
      })
      .catch(() => {});
  }

  // Get cart from cookie
  const getCartFromCookie = () => {
    let id = null;
    let { cookie } = document;
    cookie = cookie.split("; ");

    if (cookie.length > 0 && cookie[0] !== "") {
      for (let item of cookie) {
        item = item.split("=");
        if (item[0] === "cart") {
          id = item[1];
          break;
        }
      }
    }

    return id;
  };

  // Quantity
  const handleQuantity = (number, index) => {
    let newValue = null;
    number = parseInt(number);

    if (number < 1) {
      newValue = 1;
    } else if (number > 999) {
      newValue = 999;
    } else {
      newValue = number;
    }

    setCart((old) => {
      old[index].quantity = newValue;
      return old;
    });
  };
  const handleQuantityInput = (event, index) => {
    const { value } = event.target;

    if (value === "") {
      setCart((old) => {
        old[index].quantity = value;
        return old;
      });
    } else if (/^[0-9]+$/.test(value)) {
      handleQuantity(value, index);
      setUpdateField(true);
    }

    setUpdateQuantity(true);
  };

  // Delete item
  const deleteItem = (targetIndex) => {
    setCart((old) => {
      const newCart = [];
      old.forEach((item, index) => {
        if (index !== targetIndex) {
          newCart.push(item);
        }
      });

      return newCart;
    });
    setUpdateField(true);
  };

  // Get cart list
  const getCartList = () => {
    const list = [];

    if (cart.length > 0) {
      cart.forEach((item, index) => {
        const { name, model, colorCode, price, sale, size, quantity, image } =
          item;

        list.push(
          <div
            key={"cart" + index}
            className="flex h-between v-center row item"
          >
            <img
              key={"cart-image" + index}
              src={
                image
                  ? `data:image/png;base64, ${new Buffer.from(image).toString(
                      "base64"
                    )}`
                  : null
              }
            />
            <div key={"cart-name-div" + index} className="flex-column name">
              <a key={"cart-name" + index}>{`${name}`}</a>
              <a key={"cart-model" + index}>{`(型號: ${model})`}</a>
            </div>
            <div
              key={"cart-color-div" + index}
              className="flex h-center color-div"
            >
              <div
                key={"cart-color" + index}
                style={{ backgroundColor: colorCode }}
                className="color"
              ></div>
            </div>
            <a key={"cart-size" + index}>{size}</a>
            <input
              key={"cart-quantity" + index}
              type="text"
              value={quantity}
              onChange={(event) => handleQuantityInput(event, index)}
            />
            <FaTimes key={"cart-times" + index} className="icon" />
            <div key={"cart-price-div" + index} className="flex-column">
              {sale !== price && (
                <a key={"cart-sale" + index} className="sale">
                  ${sale.toLocaleString("en-US")}
                </a>
              )}
              <a
                key={"cart-price" + index}
                style={{
                  color: sale !== price ? "var(--red)" : "var(--dark5)",
                }}
              >
                ${price.toLocaleString("en-US")}
              </a>
            </div>
            <FaEquals key={"cart-equals" + index} className="icon" />
            <a key={"cart-total" + index}>
              ${(quantity * sale).toLocaleString("en-US")}
            </a>
            <BsTrash
              key={"cart-delete" + index}
              className="delete"
              onClick={() => deleteItem(index)}
            />
          </div>
        );
      });
    } else {
      list.push(
        <div key="cart-no-item" className="flex h-center row no-item">
          購物車內無商品
        </div>
      );
    }

    return list;
  };

  return (
    <div id="page-cart" className="flex-column v-center page">
      <div className="flex-column v-center page-content">
        <h2>購物車</h2>
        <div className="flex-column table">
          {/* Title */}
          <div className="flex h-between v-center row title">
            <a className="image"></a>
            <a className="name">商品名稱</a>
            <a>顏色</a>
            <a>尺寸</a>
            <a className="quantity">數量</a>
            <a className="icon"></a>
            <a>單價</a>
            <a className="icon"></a>
            <a>小計</a>
            <a className="delete"></a>
          </div>
          {/* Cart list */}
          {getCartList()}
          <div className="divider"></div>
          {/* Summary */}
          <div className="flex-column row summary">
            {save > 0 && (
              <div className="flex h-between v-center row save-div">
                <a className="image"></a>
                <a className="name"></a>
                <a></a>
                <a></a>
                <a className="quantity"></a>
                <a className="icon"></a>
                <a className="no-wrap">折扣(–):</a>
                <a className="icon"></a>
                <a className="text save">${save.toLocaleString("en-US")}</a>
                <a className="delete"></a>
              </div>
            )}
            <div className="flex h-between v-center row">
              <a className="image"></a>
              <a className="name"></a>
              <a></a>
              <a></a>
              <a className="quantity"></a>
              <a className="icon"></a>
              <a>合計:</a>
              <FaEquals className="icon" />
              <a className="text">${total.toLocaleString("en-US")}</a>
              <a className="delete"></a>
            </div>
            <div className="flex h-between v-center row check-out">
              <a className="image"></a>
              <a className="name"></a>
              <a></a>
              <a></a>
              <a className="quantity"></a>
              <a className="icon"></a>
              <a></a>
              <a className="icon"></a>
              {cart.length > 0 ? (
                <Link to="/shipping-info" state={{ cart }} className="button1">
                  前往結帳
                </Link>
              ) : (
                <a className="button1 no-item">前往結帳</a>
              )}
              <a className="delete"></a>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}
