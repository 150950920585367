import React, { useState, useEffect } from "react";
import axios from "axios";
import { Buffer } from "buffer";
// Footer
import Footer from "../../widgets/Footer/Footer";
// Icon
import { FiDownloadCloud } from "react-icons/fi";
// Css
import "./catalog.scss";
// Files
import file1 from "../../files/pdf/dm1.pdf";
import file2 from "../../files/pdf/dm2.pdf";
import file3 from "../../files/pdf/dm3.pdf";
import file4 from "../../files/pdf/dm4.pdf";
import file5 from "../../files/pdf/dm5.pdf";
import file6 from "../../files/pdf/dm6.pdf";
import file7 from "../../files/pdf/dm7.pdf";
import file8 from "../../files/pdf/dm8.pdf";
import file9 from "../../files/pdf/dm9.pdf";
import file10 from "../../files/pdf/dm10.pdf";
import file11 from "../../files/pdf/dm11.pdf";

export default function Catalog() {
  // Catalog menu - 專業工作鞋/舒適生活鞋
  const [activeList, setActiveList] = useState(1);
  // DM list
  const [dmList, setDmList] = useState(new Map());
  const [updateDMIndex, setUpdateDMIndex] = useState(false);

  useEffect(() => {
    callDMListEndpoint();
  }, []);

  useEffect(() => {
    setUpdateDMIndex(false);
  }, [updateDMIndex]);

  // Endpoint
  async function callDMListEndpoint() {
    await axios
      .get(`${process.env.REACT_APP_API}/dms/list`, {
        headers: { "x-api-key": process.env.REACT_APP_API_KEY },
      })
      .then((res) => {
        if (res.status === 200) {
          handleData(res.data);
        }
      })
      .catch(() => {});
  }

  // Handle data
  const handleData = (rawData) => {
    const fileList = [
      file1,
      file2,
      file3,
      file4,
      file5,
      file6,
      file7,
      file8,
      file9,
      file10,
      file11,
    ];
    const map = new Map();

    for (let i = 0; i < rawData.length; i++) {
      const { id, name, image, listIndex } = rawData[i];

      if (!map.has(id)) {
        map.set(id, {
          name,
          listIndex,
          images: [image],
          file: fileList[id-1],
          index: 0,
        });
      } else {
        const data = map.get(id);
        data.images = [...data.images, image];
        map.set(id, data);
      }
    }
    setDmList(map);
  };

  // Determine list shown event
  const getActiveList = (current) => {
    if (activeList === current) {
      return "menu-active";
    } else {
      return "";
    }
  };

  // Print list of cards
  const getCards = (dm) => {
    const cards = [];

    for (const [id, data] of dmList) {
      const { name, listIndex, images, file, index } = data;
      const base64String = new Buffer.from(images[index]).toString("base64");

      if (listIndex === dm) {
        cards.push(
          <div
            key={"card_container" + id}
            className="flex-column v-center card-container"
          >
            <div
              key={"loading_container" + id}
              className="flex-column h-center v-center card-img-container"
            >
              <img
                key={"card_img" + id}
                src={`data:image/png;base64, ${base64String}`}
                className="card-img"
              />
              <div
                key={"card_img_list_container" + id}
                className="flex card-img-list"
              >
                {imgList(images, id, index)}
              </div>
            </div>
            {/* DM text */}
            <div className="flex v-center card-text">
              <a key={"card_name" + id}>{name}</a>
              <FiDownloadCloud
                key={"card_download" + id}
                onClick={() => downloadLink(file)}
              />
            </div>
          </div>
        );
      }
    }

    return cards;
  };

  // Image pagination
  const imgList = (images, dmId, imageIndex) => {
    const list = [];

    for (let j = 0; j < images.length; j++) {
      const style = {};
      const base64String = new Buffer.from(images[j]).toString("base64");

      if (imageIndex === j) {
        style.opacity = 1;
      }

      list.push(
        <div
          key={"card_img_list_border" + dmId + "_" + j}
          style={{
            border: `2px solid ${
              imageIndex === j ? "var(--dark2)" : "transparent"
            }`,
          }}
          className="flex v-center"
          onClick={() => {
            setDmList(old => {
              const data = old.get(dmId);
              data.index = j;
              old.set(dmId, data);
              return old;
            })
            setUpdateDMIndex(true);
          }}
        >
          <img
            key={"card_img_list" + dmId + "_" + j}
            src={`data:image/png;base64, ${base64String}`}
            style={style}
          />
        </div>
      );
    }

    return list;
  };

  // Open download link
  const downloadLink = (file) => {
    window.open(file, "_blank");
  };

  return (
    <div id="page-catalog" className="flex-column v-center page">
      {/* Page content */}
      <div className="flex-column v-center page-content">
        <h2>產品型錄</h2>
        {/* List menu */}
        <div className="flex v-center list-menu">
          <a className={getActiveList(1)} onClick={() => setActiveList(1)}>
            專業工作鞋
          </a>
          <div className="divider"></div>
          <a className={getActiveList(2)} onClick={() => setActiveList(2)}>
            舒適生活鞋
          </a>
        </div>
        {/* 專業工作鞋 */}
        <div
          style={{ display: activeList === 1 ? "grid" : "none" }}
          className="h-around list-content"
        >
          {getCards(1)}
        </div>
        {/* 舒適生活鞋 */}
        <div
          style={{ display: activeList === 2 ? "grid" : "none" }}
          className="h-around list-content"
        >
          {getCards(2)}
        </div>
      </div>
      {/* Footer */}
      <Footer />
    </div>
  );
}
