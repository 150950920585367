import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { Buffer } from "buffer";
// Loading Animation
import LoadingAnimation from "../../widgets/Animations/Loading";
// Footer
import Footer from "../../widgets/Footer/Footer";
// Category
import { category } from "../Data";
// Images
import ShopRuten from "../../files/images/shop-ruten.png";
import ShopShopee from "../../files/images/shop-shopee.jpeg";
import ShopPChome from "../../files/images/shop-pchome.jpeg";
import ShopMomo from "../../files/images/shop-momo.jpeg";
import ShopRakuten from "../../files/images/shop-rakuten.jpeg";
// Icons
import { AiTwotoneCrown } from "react-icons/ai";
import { TbCaretRight } from "react-icons/tb";
import { VscTriangleRight } from "react-icons/vsc";
import { MdRemove, MdAdd } from "react-icons/md";
import { GoStar } from "react-icons/go";
import { CgChevronLeft, CgChevronRight } from "react-icons/cg";
import { BsArrowDownShort } from "react-icons/bs";
// Css
import "./productInfo.scss";

export default function Productinfo(props) {
  const navigate = useNavigate();
  // [ Loading ]
  const [loading, setLoading] = useState(true);
  // [ Product info ]
  const [info, setInfo] = useState({});
  const [currentProduct, setCurrentProduct] = useState(0);
  // [ Product images ]
  const [currentImage, setCurrentImage] = useState(0);
  const [hoverImage, setHoverImage] = useState(null);
  // [ Description ]
  const [currentDescription, setCurrentDescription] = useState(0);
  // [ Quantity ]
  const [quantity, setQuantity] = useState(1);

  useEffect(() => {
    let path = window.location.pathname;
    path = path.split("/");
    const index = Number.isInteger(parseInt(path[3])) ? parseInt(path[3]) : 0;
    callProductInfoEndpoint(path[2]);
    setCurrentProduct(index);
    window.scrollTo(0, 0);
  }, []);

  // Endpoint
  async function callProductInfoEndpoint(id) {
    await axios
      .post(`${process.env.REACT_APP_API}/products/info`, {
        productId: id,
      }, {
        headers: { "x-api-key": process.env.REACT_APP_API_KEY },
      })
      .then((res) => {
        if (res.status === 200) {
          handleData(res.data);
          setLoading(false);
        }
      })
      .catch(() => {});
  }
  async function callAddToCartEndpoint(item, expires) {
    const cookie = getCookie();

    if (cookie.user && cookie.x) {
      await axios
        .post(
          `${process.env.REACT_APP_API}/carts/add`,
          {
            user: cookie.user,
            cart: cookie.cart,
            item,
            expires,
          },
          {
            headers: {
              "x-access-token": cookie.x,
              "x-api-key": process.env.REACT_APP_API_KEY
            },
          }
        )
        .then((res) => {
          if (res.status === 200) {
            const { cart, quantity } = res.data;

            // Update cookie
            setCookie(cart, expires);
            // Update cart quantity
            props.setCartQuantity(quantity);
            // Toggle shopping cart
            props.setToggleCart(true);
          }
        })
        .catch(() => {});
    } else {
      await axios
        .post(`${process.env.REACT_APP_API}/carts/add`, {
          cart: cookie.cart,
          item,
          expires,
        }, {
          headers: { "x-api-key": process.env.REACT_APP_API_KEY },
        })
        .then((res) => {
          if (res.status === 200) {
            const { cart, quantity } = res.data;

            // Update cookie
            setCookie(cart, expires);
            // Update cart quantity
            props.setCartQuantity(quantity);
            // Toggle shopping cart
            props.setToggleCart(true);
          }
        })
        .catch(() => {});
    }
  }

  // Handle data
  const handleData = (data) => {
    const { product, product_image, detail_image, size_info, product_deal } =
      data;
    const cMap = new Map();
    let productInfo = {};

    product.forEach((item, index) => {
      const {
        id,
        name,
        model,
        sizes,
        description,
        colorId,
        color,
        colorCode,
        price,
        sale,
        rutenLink,
        pcstoreLink,
        shopeeLink,
        rakutenLink,
        momoLink,
      } = item;

      if (index === 0) {
        productInfo = {
          id,
          name,
          model,
          price: [price],
          sale: [sale],
          colorId: [colorId],
          color: [color],
          colorCode: [colorCode],
          sizes: JSON.parse(sizes),
          rutenLink: [rutenLink],
          pcstoreLink: [pcstoreLink],
          shopeeLink: [shopeeLink],
          rakutenLink: [rakutenLink],
          momoLink: [momoLink],
          description: JSON.parse(description),
          images: [[]],
        };
      } else {
        productInfo.price.push(price);
        productInfo.sale.push(sale);
        productInfo.colorId.push(colorId);
        productInfo.color.push(color);
        productInfo.colorCode.push(colorCode);
        productInfo.images.push([]);
        productInfo.rutenLink.push(rutenLink);
        productInfo.pcstoreLink.push(pcstoreLink);
        productInfo.shopeeLink.push(shopeeLink);
        productInfo.rakutenLink.push(rakutenLink);
        productInfo.momoLink.push(momoLink);
      }
      cMap.set(colorId, index);
    });

    product_image.forEach((item) => {
      const { colorId, image } = item;
      const index = cMap.get(colorId);
      productInfo.images[index].push(image);
    });

    detail_image.forEach((item) => {
      const { image } = item;
      productInfo.details
        ? productInfo.details.push(image)
        : (productInfo.details = [image]);
    });

    productInfo.sizeInfo = size_info;

    product_deal.forEach((item) => {
      productInfo.deal
        ? productInfo.deal.push(item.deal)
        : (productInfo.deal = [item.deal]);
    });

    setInfo(productInfo);
  };

  // Get category
  const getCategory = () => {
    const list = [];

    for (let i = 0; i < category.length; i++) {
      const subList = [];
      const { type } = category[i];

      for (let j = 0; j < type.length; j++) {
        subList.push(
          <a
            key={"type" + i + "_" + j}
            className="flex v-center sub-list"
            onClick={() => {
              navigate("/product-list/" + type[j]);
            }}
          >
            <TbCaretRight /> {type[j]}
          </a>
        );
      }

      list.push(
        <div key={"category_div" + i} className="flex-column">
          <a
            key={"category_text" + i}
            className="main-list"
            onClick={() => {
              navigate("/product-list/" + category[i].name);
            }}
          >
            {category[i].name}
          </a>
          {subList}
        </div>
      );
    }

    return list;
  };

  // [ Images ]
  const updateImageIndex = (index) => {
    const { length } = info.images[currentProduct];

    if (index < 0) {
      setCurrentImage(length - 1);
    } else if (index >= length) {
      setCurrentImage(0);
    } else {
      setCurrentImage(index);
    }
  };
  const getImages = () => {
    const list = [];

    if (info.images) {
      info.images[currentProduct].forEach((item, index) => {
        const base64String = new Buffer.from(item).toString("base64");

        list.push(
          <img
            key={"img" + index}
            src={`data:image/png;base64, ${base64String}`}
            style={{
              border:
                currentImage === index || hoverImage === index
                  ? "2px solid var(--dark5)"
                  : "2px solid transparent",
            }}
            onMouseOver={() => setHoverImage(index)}
            onMouseOut={() => setHoverImage(null)}
            onClick={() => setCurrentImage(index)}
          />
        );
      });
    }

    return list;
  };

  // [ Feature ]
  const getFeature = () => {
    const list = [];
    const { short } = info.description;

    short.forEach((item, index) => {
      list.push(
        <div key={"feature_row" + index} className="flex v-center">
          <GoStar key={"feature_img" + index} />
          <a key={"feature" + index}>{item}</a>
        </div>
      );
    });

    return list;
  };

  // [ Colors ]
  const getColors = () => {
    const list = [];

    if (info.color) {
      info.colorCode.forEach((item, index) => {
        list.push(
          <div
            key={"color_container" + index}
            className="flex h-center v-center color"
            style={{
              border:
                currentProduct === index
                  ? "1px solid var(--dark5)"
                  : "1px solid transparent",
            }}
            onClick={() => {
              setCurrentProduct(index);
            }}
          >
            <div key={"color" + index} style={{ backgroundColor: item }}></div>
          </div>
        );
      });
    }

    return list;
  };

  // [ Quantity ]
  const handleQuantity = (number) => {
    number = parseInt(number);

    if (number < 1) {
      setQuantity(1);
    } else if (number > 999) {
      setQuantity(999);
    } else {
      setQuantity(number);
    }
  };
  const handleQuantityInput = (event) => {
    const { value } = event.target;

    if (value === "") {
      setQuantity(value);
    } else if (/^[0-9]+$/.test(value)) {
      handleQuantity(value);
    }
  };

  // [ Descriptions ]
  const getDescription = () => {
    const field1 = [];
    const field2 = [];
    const field3 = [];

    if (info.description) {
      Object.keys(info.description).forEach((item, index) => {
        if (item !== "short") {
          // Make \n into new line
          const parts = item.split("\n");
          const advantages = info.description[item].advantage.split("\n");
          const materials = info.description[item].material.split("\n");
          const maxLength = Math.max(
            parts.length,
            advantages.length,
            materials.length
          );

          field1.push(
            <div
              key={"table_row_container" + index + "-1"}
              className="flex-column column"
            >
              {getSubDescription(parts, maxLength, index, 1)}
            </div>
          );
          field2.push(
            <div
              key={"table_row_container" + index + "-2"}
              className="flex-column column"
            >
              {getSubDescription(advantages, maxLength, index, 2)}
            </div>
          );
          field3.push(
            <div
              key={"table_row_container" + index + "-3"}
              className="flex-column column"
            >
              {getSubDescription(materials, maxLength, index, 3)}
            </div>
          );
        } else {
          field1.push(
            <div key={"table_row_container" + index + "-1"} className="column">
              <a key={"table_row" + index + "-1"}>部位</a>
            </div>
          );
          field2.push(
            <div key={"table_row_container" + index + "-2"} className="column">
              <a key={"table_row" + index + "-2"}>材質說明</a>
            </div>
          );
          field3.push(
            <div key={"table_row_container" + index + "-3"} className="column">
              <a key={"table_row" + index + "-3"}>優點</a>
            </div>
          );
        }
      });
    }

    return (
      <div className="flex table">
        <div className="flex table-wrapper">
          <div className="flex-column row">{field1}</div>
          <div className="flex-column row">{field2}</div>
          <div className="flex-column row">{field3}</div>
        </div>
      </div>
    );
  };
  const getSubDescription = (info, length, row, column) => {
    const list = [];

    for (let i = 0; i < length; i++) {
      // Replace ^ with power of
      const content = [];
      const powerNum = [];
      let text = i < info.length ? info[i] : "";
      text = text.split("^(");
      if (text.length > 1) {
        text.forEach((item, index) => {
          if (index > 0) {
            const numPos = item.indexOf(")");
            powerNum.push(item.slice(0, numPos));
            text[index] = item.slice(numPos + 1);
          }
        });
      }

      text.forEach((item, index) => {
        if (index === 0) {
          content.push(
            <a key={"table_text" + row + "_" + column + "_" + i + "_" + index}>
              {item}
            </a>
          );
        } else {
          content.push(
            <a
              key={"table_num" + row + "_" + column + "_" + i + "_" + index}
              className="number"
            >
              {powerNum[index - 1]}
            </a>
          );
          content.push(
            <a key={"table_text" + row + "_" + column + "_" + i + "_" + index}>
              {item}
            </a>
          );
        }
      });

      list.push(
        <div
          key={"table_column" + row + "_" + column + "_" + i}
          className="flex v-center"
        >
          {content}
        </div>
      );
    }

    return list;
  };
  const getProductInfoImages = () => {
    const list = [];

    if (info.details) {
      info.details.forEach((item, index) => {
        const base64String = new Buffer.from(item).toString("base64");
        list.push(
          <img
            key={"detail_image" + index}
            src={`data:image/png;base64, ${base64String}`}
          />
        );
      });
    }

    return list;
  };
  const getSizeInfoImage = () => {
    if (info.sizeInfo) {
      const base64String = new Buffer.from(info.sizeInfo).toString("base64");

      return <img src={`data:image/png;base64, ${base64String}`} />;
    }

    return null;
  };

  // Add to cart
  const addToCart = () => {
    // Expires in 7 days
    const expires = new Date(new Date().getTime() + 7 * 24 * 60 * 60 * 1000);
    const item = {
      productId: info.id,
      colorId: info.colorId[currentProduct],
      size: 2,
      quantity,
    };

    // Add item to cart database
    callAddToCartEndpoint(item, expires);
  };

  // Cookie
  const getCookie = () => {
    const info = {};
    let { cookie } = document;
    cookie = cookie.split("; ");

    if (cookie.length > 0 && cookie[0] !== "") {
      for (let item of cookie) {
        item = item.split("=");
        info[item[0]] = item[1];
      }
    }

    return info;
  };
  const setCookie = (cart, expires) => {
    document.cookie =
      "cart=" + cart + "; expires=" + expires.toUTCString() + "; path=/";
  };

  // Validate shop link
  const validLink = (type) => {
    return info[type + "Link"] && info[type + "Link"][currentProduct]
      ? ""
      : " invalid";
  };
  // Go shop link
  const goLink = (type) => {
    if (info[type + "Link"] && info[type + "Link"][currentProduct]) {
      window.open(info[type + "Link"][currentProduct], "_blank");
    }
  };

  return (
    <div id="page-product-info" className="flex-column v-center page">
      <div className="flex page-content">
        <div className="flex-column side-bar">
          {/* Category */}
          <div className="flex-column v-center category">{getCategory()}</div>
        </div>
        {/* Loading animation */}
        {loading &&
          <div className="flex h-center loader-cont">
            <LoadingAnimation />
          </div>
        }
        {/* Product info */}
        {!loading && (
          <div className="flex-column info">
            <div className="flex info-wrapper">
              {/* Images */}
              <div className="flex-column v-center product-images">
                <div className="flex h-between v-center">
                  <button onClick={() => updateImageIndex(currentImage - 1)}>
                    <CgChevronLeft />
                  </button>
                  <img
                    src={
                      info.images
                        ? `data:image/png;base64, ${new Buffer.from(
                            info.images[currentProduct][currentImage]
                          ).toString("base64")}`
                        : null
                    }
                    className="current_image"
                  />
                  <button onClick={() => updateImageIndex(currentImage + 1)}>
                    <CgChevronRight />
                  </button>
                </div>
                <div className="flex h-center image-list">{getImages()}</div>
              </div>
              <hr className="mobile" />
              <div className="flex-column detail">
                {/* Name & Model */}
                <h2>
                  {info.name} <a>(型號: {info.model})</a>
                </h2>
                {/* Price */}
                {info.price && info.sale && (
                  <div className="flex-column price">
                    <div className="flex-column">
                      <a>牌價</a>
                      <a>${info.price && info.price[currentProduct]}</a>
                    </div>
                    <div className="flex-column">
                      <a>特價</a>
                      <a className="sale">${info.sale[currentProduct]}</a>
                    </div>
                  </div>
                )}
                {/* Deal */}
                {/* {info.deal && (
                  <div className="flex v-center deal">
                    <div className="flex v-center">
                      <a>活動</a>
                      <VscTriangleRight />
                    </div>
                    <a className="text">{info.deal}</a>
                  </div>
                )} */}
                {/* Color */}
                <div className="flex-column h-center block color-wrapper">
                  <a className="title">顏色</a>
                  <div className="flex v-center content">
                    <div className="flex">{getColors()}</div>
                    <a className="current_color">
                      {info.price && info.color[currentProduct]}
                    </a>
                  </div>
                </div>
                {/* Size */}
                {/* <div className="flex-column h-center block size">
                  <a className="title">尺寸</a>
                  <div className="flex content">
                    <a className="number">{info.sizes && info.sizes.min}</a>
                    <a className="symbol">–</a>
                    <a className="number">{info.sizes && info.sizes.max}</a>
                  </div>
                </div> */}
                {/* Quantity */}
                {/* <div className="flex-column block quantity">
                  <a className="title">數量</a>
                  <div className="flex v-center content">
                    <button
                      className="flex v-center"
                      onClick={() => handleQuantity(quantity - 1)}
                    >
                      <MdRemove />
                    </button>
                    <input
                      type="text"
                      value={quantity}
                      onChange={handleQuantityInput}
                    />
                    <button
                      className="flex v-center"
                      onClick={() => handleQuantity(quantity + 1)}
                    >
                      <MdAdd />
                    </button>
                  </div>
                </div> */}
                {/* Feature */}
                 {info.description && (
                  <div className="flex-column feature">{getFeature()}</div>
                )}
                {/* Add to cart & Check out */}
                {/* <div className="flex buttons">
                  <button className="add-to-cart" onClick={addToCart}>
                    加入購物車
                  </button>
                  <button
                    className="check-out"
                    onClick={() => {
                      addToCart();
                      setTimeout(() => navigate("/cart"), 300);
                    }}
                  >
                    立即結帳
                  </button>
                </div> */}
              </div>
            </div>
            {/* Shops - Ruten, PChome, Shopee */}
            <div className="flex-column v-center shops">
              <a className="discount">請到我們的購物商城購買</a>
              <div className="flex h-center divider">
                <BsArrowDownShort />
                <BsArrowDownShort />
                <BsArrowDownShort />
                <BsArrowDownShort />
                <BsArrowDownShort />
                <BsArrowDownShort />
                <BsArrowDownShort />
                <BsArrowDownShort />
                <BsArrowDownShort />
                <BsArrowDownShort />
                <BsArrowDownShort />
              </div>
              <div className="flex h-center buttons">
                <button
                  className={"flex h-center v-center" + validLink("ruten")}
                  onClick={() => goLink("ruten")}
                >
                  <img src={ShopRuten} />
                  <a>露天拍賣</a>
                </button>
                <button
                  className={"flex h-center v-center" + validLink("shopee")}
                  onClick={() => goLink("shopee")}
                >
                  <img src={ShopShopee} />
                  <a>蝦皮拍賣</a>
                </button>
                <button
                  className={"flex h-center v-center" + validLink("pcstore")}
                  onClick={() => goLink("pcstore")}
                >
                  <img src={ShopPChome} />
                  <div className="flex-column">
                    <a>PChome</a>
                    <a>商店街</a>
                  </div>
                </button>
                <button
                  className={"flex h-center v-center" + validLink("momo")}
                  onClick={() => goLink("momo")}
                >
                  <img src={ShopMomo} />
                  <div className="flex-column">
                    <a>momo</a>
                    <a>購物網</a>
                  </div>
                </button>
                <button
                  className={"flex h-center v-center" + validLink("rakuten")}
                  onClick={() => goLink("rakuten")}
                >
                  <img src={ShopRakuten} />
                  <a>樂天市場</a>
                </button>
              </div>
            </div>
            {/* Descriptions */}
            <div className="flex-column description">
              {/* Title list */}
              <div className="flex title-list">
                <a
                  style={{
                    fontWeight: currentDescription === 0 ? "bold" : "normal",
                  }}
                  onClick={() => setCurrentDescription(0)}
                >
                  商品資訊
                </a>
                <a
                  style={{
                    fontWeight: currentDescription === 1 ? "bold" : "normal",
                  }}
                  onClick={() => setCurrentDescription(1)}
                >
                  尺寸指南
                </a>
              </div>
              {/* Descriptions */}
              {/* [ Product info ] */}
              {currentDescription === 0 && (
                <div className="flex-column content">
                  {/* Detail images */}
                  <div className="flex-column block detail-images">
                    {getProductInfoImages()}
                  </div>
                  {/* 商品規格 */}
                  <div className="flex-column block">
                    <a className="title">商品規格</a>
                    {getDescription()}
                  </div>
                  {/* 安心小檔案 */}
                  <div className="flex-column block file">
                    <a className="title">安心小檔案</a>
                    <div className="flex v-center">
                      <AiTwotoneCrown />
                      <a>台灣製MIT微笑標章產品</a>
                    </div>
                    <div className="flex v-center">
                      <AiTwotoneCrown />
                      <a>符合CNS 20345國家標準規範</a>
                    </div>
                    <div className="flex v-center">
                      <AiTwotoneCrown />
                      <a>通過ISO 9001國際品保認證</a>
                    </div>
                    <div className="flex v-center">
                      <AiTwotoneCrown />
                      <a>取得經濟部標檢局驗證登錄證書</a>
                    </div>
                    <div className="flex v-center">
                      <AiTwotoneCrown />
                      <a>每雙鞋款均投保1000萬產品責任險</a>
                    </div>
                  </div>
                </div>
              )}
              {/* [ Size info ] */}
              {currentDescription === 1 && (
                <div className="flex-column content">
                  <div className="flex-column v-center block size-info">
                    {getSizeInfoImage()}
                  </div>
                </div>
              )}
            </div>
          </div>
        )}
      </div>
      <Footer />
    </div>
  );
}
