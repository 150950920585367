import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Buffer } from "buffer";
import axios from "axios";
// Footer
import Footer from "../../../widgets/Footer/Footer";
// Css
import "./orderDetail.css";

export default function OrderTracking() {
  const navigate = useNavigate();

  // Order, shipping & contact detail
  const [order, setOrder] = useState([]);
  const [shipping, setShipping] = useState({});
  // Order doesn't exist
  const [notExist, setNotExist] = useState(true);

  useEffect(() => {
    let path = window.location.pathname;
    path = path.split("/");
    const orderNumber = path[2];
    const email = path[3];

    // Call endpoint
    callOrderDetailEndpoint(orderNumber, email);
  }, []);

  // Endpoint
  async function callOrderDetailEndpoint(orderNumber, email) {
    const info = getCookie();

    await axios
      .get(
        `${process.env.REACT_APP_API}/orders/orderDetail?userName=${info.user}&trackingNumber=${orderNumber}&email=${email}`,
        {
          headers: {
            "x-access-token": info.x,
            "x-api-key": process.env.REACT_APP_API_KEY
          },
        }
      )
      .then((res) => {
        if (res.status === 200) {
          // Update shipping & order info
          const { shippingInfo } = res.data;

          setShipping({
            trackingNumber: orderNumber,
            fullName: shippingInfo[0].fullName,
            phoneNumber: shippingInfo[0].phoneNumber,
            address: shippingInfo[0].address,
            city: shippingInfo[0].city,
            district: shippingInfo[0].district,
            zipcode: shippingInfo[0].zipcode,
            shipping: shippingInfo[0].shipping,
            originalTotal: shippingInfo[0].originalTotalAmount,
            total: shippingInfo[0].totalAmount,
            shippingMethod: shippingInfo[0].shippingMethod,
            note: shippingInfo[0].note,
            status: shippingInfo[0].status,
            expectedDeliveryDate: shippingInfo[0].expectedDeliveryDate,
            date: shippingInfo[0].date,
            payment: "信用卡",
          });
          setOrder(res.data.order);
          setNotExist(false);
        }
      })
      .catch((err) => {
        if (err.response && err.response.status === 403) {
          setNotExist(true);
        }
      });
  }

  // Get order list
  const getOrderList = () => {
    const list = [];

    order.forEach((item, index) => {
      const { name, model, colorCode, price, sale, size, quantity, image } =
        item;

      list.push(
        <div key={"order" + index} className="flex h-between v-center row item">
          <img
            key={"order-image" + index}
            src={
              image
                ? `data:image/png;base64, ${new Buffer.from(image).toString(
                    "base64"
                  )}`
                : null
            }
          />
          <div key={"order-name-div" + index} className="flex-column name">
            <a key={"order-name" + index}>{`${name}`}</a>
            <a key={"order-model" + index}>{`(型號: ${model})`}</a>
          </div>
          <div
            key={"order-color-div" + index}
            className="flex h-center color-div"
          >
            <div
              key={"order-color" + index}
              style={{ backgroundColor: colorCode }}
              className="color"
            ></div>
          </div>
          <a key={"order-size" + index}>{size}</a>
          <a key={"order-quantity" + index}>{quantity}</a>
          <div key={"order-price-div" + index} className="flex-column">
            {sale !== price && (
              <a key={"order-sale" + index} className="sale">
                ${sale.toLocaleString("en-US")}
              </a>
            )}
            <a
              key={"order-price" + index}
              style={{
                color: sale !== price ? "var(--red)" : "var(--dark5)",
              }}
            >
              ${price.toLocaleString("en-US")}
            </a>
          </div>
          <a key={"order-total" + index}>
            ${(quantity * sale).toLocaleString("en-US")}
          </a>
        </div>
      );
    });

    return list;
  };

  // Get cookie
  const getCookie = () => {
    let { cookie } = document;
    cookie = cookie.split("; ");

    if (cookie.length === 1 && cookie[0] === "") {
      return null;
    } else {
      const info = {};
      cookie.forEach((item) => {
        const str = item.split("=");
        info[str[0]] = str[1];
      });

      return info;
    }
  };

  // Format date
  const formatDate = (rawDate) => {
    let date = "";
    date = new Date(rawDate).getFullYear() + "/";
    date += new Date(rawDate).getMonth() < 10 ? "0" : "";
    date += new Date(rawDate).getMonth() + "/";
    date += new Date(rawDate).getDate() < 10 ? "0" : "";
    date += new Date(rawDate).getDate();

    return date;
  };

  return (
    <div id="page-order-detail" className="flex-column v-center page">
      <div className="flex-column v-center page-content">
        <h2>訂單詳情</h2>
        {!notExist &&
          order.length > 0 &&
          Object.keys(shipping).length === 16 && (
            <div className="flex h-between content">
              <div className="flex-column content-left">
                {/* Shipping info, payment method & tracking number */}
                <div className="flex-column shipping">
                  <div className="flex h-between">
                    <div className="flex-column field">
                      <div className="flex v-baseline">
                        <a className="title">訂單編號:</a>
                        <a>{shipping.trackingNumber}</a>
                      </div>
                      <div className="flex v-baseline">
                        <a className="title">訂單日期:</a>
                        <a>{formatDate(shipping.date)}</a>
                      </div>
                      <div className="flex v-baseline">
                        <a className="title">訂單狀態:</a>
                        <a>{shipping.status}</a>
                      </div>
                      <div className="flex v-baseline">
                        <a className="title">預計到貨日:</a>
                        <a>
                          {shipping.expectedDeliveryDate
                            ? formatDate(shipping.expectedDeliveryDate)
                            : "--"}
                        </a>
                      </div>
                    </div>
                    <div className="flex-column field">
                      <a className="title">{shipping.fullName}</a>
                      <a>
                        {shipping.city}
                        {shipping.district}
                      </a>
                      <a>
                        {shipping.address} {shipping.zipcode}
                      </a>
                    </div>
                    <div className="flex-column field">
                      <div className="flex v-baseline">
                        <a className="title">聯絡電話:</a>
                        <a>+886 {shipping.phoneNumber}</a>
                      </div>
                      <div className="flex v-baseline">
                        <a className="title">付款方式:</a>
                        <a>{shipping.payment}</a>
                      </div>
                      <div className="flex v-baseline">
                        <a className="title">送貨方式:</a>
                        <a>{shipping.shippingMethod}</a>
                      </div>
                    </div>
                  </div>
                  <div className="flex v-baseline field note">
                    <a className="title">備註:</a>
                    <a>{shipping.note ? shipping.note : "--"}</a>
                  </div>
                </div>
                {/* Order details */}
                <div className="flex-column table">
                  {/* Title */}
                  <div className="flex h-between v-center row table-title">
                    <a className="image"></a>
                    <a className="name">商品名稱</a>
                    <a>顏色</a>
                    <a>尺寸</a>
                    <a className="quantity">數量</a>
                    <a>單價</a>
                    <a>小計</a>
                  </div>
                  {getOrderList()}
                </div>
              </div>
              <div className="flex-column content-right">
                {/* Calculation */}
                <div className="flex-column calculation">
                  <div className="flex h-between">
                    <a>小計:</a>
                    <a>NT$ {shipping.originalTotal.toLocaleString("en-US")}</a>
                  </div>
                  <div className="flex h-between discount">
                    <a>折扣:</a>
                    <a>
                      - NT${" "}
                      {(shipping.originalTotal - shipping.total).toLocaleString(
                        "en-US"
                      )}
                    </a>
                  </div>
                  <div className="flex h-between">
                    <a>運費:</a>
                    <a>NT$ {shipping.shipping.toLocaleString("en-US")}</a>
                  </div>
                </div>
                {/* Summary */}
                <div className="flex h-between summary">
                  <a>合計:</a>
                  <a>
                    NT${" "}
                    {(shipping.total + shipping.shipping).toLocaleString(
                      "en-US"
                    )}
                  </a>
                </div>
              </div>
            </div>
          )}
        {notExist && <a>很抱歉, 找不到您要查的訂單。</a>}
      </div>
      <Footer />
    </div>
  );
}
