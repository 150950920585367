import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import HCaptcha from "@hcaptcha/react-hcaptcha";
// import FacebookLogin from "react-facebook-login";
// Zipcode
import { zipCodes, cities, districts } from "use-tw-zipcode";
// Pop up
import PopUp from "../../../widgets/PopUps/PopUps";
// Icons
import { FaLine, FaFacebook, FaGoogle } from "react-icons/fa";
import { CgClose } from "react-icons/cg";
import { HiOutlineEye, HiOutlineEyeOff } from "react-icons/hi";
import { AiFillCaretDown, AiFillCaretUp } from "react-icons/ai";
import { MdLibraryAdd, MdRemove } from "react-icons/md";
// Css
import "./signInUp.css";

export default function SignUp(props) {
  const { insidePage } = props;
  // [ Captcha ]
  const [token, setToken] = useState(null);
  // [ User info ]
  // User name
  const userName = useRef("");
  // Password
  const [showPassword, setShowPassword] = useState(false);
  const password = useRef("");
  const checkPassword = useRef("");
  // Company
  const company = useRef("");
  // Full name
  const fullName = useRef("");
  // gender
  const [gender, setGender] = useState("先生");
  // dob
  const dob = useRef("");
  // email
  const email = useRef("");
  // Phone
  const [lines, setLines] = useState(1);
  const [phoneType, setPhoneType] = useState(["手機"]);
  const [updatePhoneType, setUpdatePhoneType] = useState(false);
  const [phoneNumber, setPhoneNumber] = useState([""]);
  const [updatePhoneNumber, setUpdatePhoneNumber] = useState(false);
  // Address
  const address = useRef("");
  const [city, setCity] = useState("");
  const [district, setDistrict] = useState("");
  const [zipcode, setZipcode] = useState("--");
  // Validation
  const [isValid, setIsValid] = useState({
    userName: true,
    userNameValidInfo: "",
    password: [true, true],
    passwordValidInfo: "",
    fullName: true,
    dob: true,
    email: true,
    phoneNumber: [true],
    address: true,
    city: true,
    district: true,
  });
  // [ Dropdowns ]
  // Phone type
  const [showTypeDropdown, setShowTypeDropdown] = useState(false);
  const [typeDropdownIndex, setTypeDropdownIndex] = useState(null);
  // City
  const [showCityDropdown, setShowCityDropdown] = useState(false);
  // District
  const [showDistrictDropdown, setShowDistrictDropdown] = useState(false);
  // [ Event listener ]
  const [click, setClick] = useState(null);
  const [enter, setEnter] = useState(false);
  // [ Error ]
  const [error, setError] = useState(null);
  const [popUpError, setPopUpError] = useState(null);
  // [ Pop up ]
  const [showPopUp, setShowPopUp] = useState(false);
  const [popUpType, setPopUpType] = useState(null);
  // [ Social ]
  const [socialFullName, setSocialFullName] = useState(null);

  // Env
  const contactEmail = process.env.REACT_APP_CONTACT_EMAIL;
  const environment = process.env.REACT_APP_ENVIRONMENT;

  // Event listener - mouse click and Enter key press
  useEffect(() => {
    // Set email if sign up with social account
    if (props.socialInfo && props.socialInfo.email) {
      email.current.value = props.socialInfo.email;
    }

    const handleClick = (event) => {
      setClick(event);
    };
    const keyDownHandler = (event) => {
      if (event.key === "Enter") {
        setEnter(true);
      }
    };

    document.addEventListener("click", handleClick);
    document.addEventListener("keydown", keyDownHandler);

    return () => {
      document.removeEventListener("click", handleClick);
      document.removeEventListener("keydown", keyDownHandler);
    };
  }, []);

  // Phone input change
  useEffect(() => {
    // Phone type
    if (updatePhoneType) {
      setUpdatePhoneType(false);
    }

    // Phone number
    if (updatePhoneNumber) {
      setUpdatePhoneNumber(false);
    }

    // Click event
    if (click) {
      hideDropdowns(click);
      setClick(null);
    }

    // Enter key press event
    if (enter) {
      handleSubmit();
      setEnter(false);
    }
  }, [updatePhoneType, updatePhoneNumber, click, enter]);

  // Update password icon
  const updatePasswordIcon = () => {
    setShowPassword(!showPassword);
  };

  // Phone
  const getLine = () => {
    const list = [];

    for (let i = 0; i < lines; i++) {
      list.push(
        <div
          key={"phone-line" + i}
          style={{ marginTop: i > 0 ? "-15px" : "0" }}
          className="flex h-between v-start"
        >
          <div key={"phone-input" + i} className="flex h-between phone-input">
            <div
              key={"phone-type" + i}
              className="flex-column v-start phone-input-type"
            >
              <a key={"phone-type-txt" + i}>
                類型<span>*</span>
              </a>
              <div
                key={"phone-type-dropdown" + i}
                className="flex-column dropdown"
              >
                <div
                  key={"phone-type-dropdown-text-div" + i}
                  style={{
                    borderRadius:
                      showTypeDropdown && typeDropdownIndex == i
                        ? "5px 5px 0 0"
                        : "5px",
                  }}
                  className="flex h-between v-center dropdown-text"
                >
                  <a
                    key={"phone-type-dropdown-text" + i}
                    id="dropdown-type-text"
                    onClick={() => {
                      toggleTypeDropdown(i);
                    }}
                  >
                    {phoneType[i]}
                  </a>
                  {showTypeDropdown && typeDropdownIndex == i ? (
                    <AiFillCaretUp
                      id="dropdown-type-icon"
                      className="icon "
                      onClick={() => {
                        toggleTypeDropdown(i);
                      }}
                    />
                  ) : (
                    <AiFillCaretDown
                      id="dropdown-type-icon"
                      className="icon"
                      onClick={() => {
                        toggleTypeDropdown(i);
                      }}
                    />
                  )}
                </div>
                <div
                  key={"phone-type-dropdown-content" + i}
                  style={{
                    display:
                      showTypeDropdown && typeDropdownIndex == i
                        ? "flex"
                        : "none",
                  }}
                  id="dropdown-type-content"
                  className="flex-column dropdown-content"
                >
                  <a
                    key={"phone-type-dropdown-content-phone" + i}
                    onClick={() => {
                      setPhoneType((old) => {
                        old[i] = "手機";
                        return old;
                      });
                      setUpdatePhoneType(true);
                    }}
                  >
                    手機
                  </a>
                  <a
                    key={"phone-type-dropdown-content-landline" + i}
                    onClick={() => {
                      setPhoneType((old) => {
                        old[i] = "市話";
                        return old;
                      });
                      setUpdatePhoneType(true);
                    }}
                  >
                    市話
                  </a>
                </div>
              </div>
            </div>
            <div
              key={"phone-number" + i}
              className="flex-column v-start phone-input-number"
            >
              <a key={"phone-number-text" + i}>
                電話<span>*</span>
              </a>
              <div className="flex h-between">
                <input
                  key={"phone-number-area-code" + i}
                  className={determineInvalid("phoneNumber", i)}
                  type="text"
                  value="+886"
                  disabled
                />
                <input
                  key={"phone-number-input" + i}
                  className={determineInvalid("phoneNumber", i)}
                  type="text"
                  placeholder={"聯絡電話" + (i + 1)}
                  value={phoneNumber[i]}
                  onChange={(event) => {
                    phoneNumber[i] = event.target.value;
                    setPhoneNumber(phoneNumber);
                    setUpdatePhoneNumber(true);
                  }}
                />
              </div>
            </div>
          </div>
          {i === 0 ? (
            <MdLibraryAdd
              key={"phone-icon" + i}
              className="icon"
              onClick={() => {
                updatePhoneLine("add");
              }}
            />
          ) : (
            <MdRemove
              key={"phone-icon" + i}
              className="icon"
              onClick={() => {
                updatePhoneLine("delete", i);
              }}
            />
          )}
        </div>
      );
    }

    return list;
  };
  const updatePhoneLine = (action, index) => {
    if (action === "add") {
      setLines(lines + 1);
      setPhoneType((old) => {
        old.push("手機");
        return old;
      });
      setPhoneNumber((old) => {
        old.push("");
        return old;
      });
      setIsValid((old) => {
        old.phoneNumber.push(true);
        return old;
      });
    } else {
      setLines(lines - 1);
      setPhoneType((old) => {
        old.splice(index, 1);
        return old;
      });
      setPhoneNumber((old) => {
        old.splice(index, 1);
        return old;
      });
      setIsValid((old) => {
        old.phoneNumber.splice(index, 1);
        return old;
      });
    }
  };

  // City
  const getCities = () => {
    const list = [];

    for (let i = 0; i < cities.length; i++) {
      list.push(
        <a
          key={"city-dropdown-content-text" + i}
          onClick={() => {
            setCity(cities[i]);
            setDistrict("");
            setZipcode("--");
          }}
        >
          {cities[i]}
        </a>
      );
    }

    return (
      <div className="flex-column dropdown">
        <div
          style={{
            borderRadius: showCityDropdown ? "5px 5px 0 0" : "5px",
          }}
          className={
            "flex h-between v-center dropdown-text" + determineInvalid("city")
          }
        >
          <a
            id="dropdown-city-text"
            onClick={() => setShowCityDropdown(!showCityDropdown)}
          >
            {city === "" ? "--" : city}
          </a>
          {showCityDropdown ? (
            <AiFillCaretUp
              id="dropdown-city-icon"
              className="icon "
              onClick={() => setShowCityDropdown(!showCityDropdown)}
            />
          ) : (
            <AiFillCaretDown
              id="dropdown-city-icon"
              className="icon"
              onClick={() => setShowCityDropdown(!showCityDropdown)}
            />
          )}
        </div>
        <div
          style={{
            display: showCityDropdown ? "flex" : "none",
          }}
          id="dropdown-city-content"
          className="flex-column dropdown-content"
        >
          {list}
        </div>
      </div>
    );
  };

  // Distrct
  const getDistrict = () => {
    const list = [];
    const districtList = districts[city] ? districts[city] : [];

    for (let i = 0; i < districtList.length; i++) {
      list.push(
        <a
          key={"district-dropdown-content-text" + i}
          onClick={() => {
            setDistrict(districtList[i]);
            setZipcode(zipCodes[city][districtList[i]]);
          }}
        >
          {districtList[i]}
        </a>
      );
    }

    return (
      <div className="flex-column dropdown">
        <div
          style={{
            borderRadius: showDistrictDropdown ? "5px 5px 0 0" : "5px",
          }}
          className={
            "flex h-between v-center dropdown-text" +
            determineInvalid("district")
          }
        >
          <a
            id="dropdown-district-text"
            style={{ cursor: districtList.length > 0 ? "pointer" : "auto" }}
            onClick={() => {
              setShowDistrictDropdown(
                districtList.length > 0 ? !showDistrictDropdown : false
              );
            }}
          >
            {district === "" ? "--" : district}
          </a>
          {districtList.length > 0 &&
            (showDistrictDropdown ? (
              <AiFillCaretUp
                id="dropdown-district-icon"
                className="icon "
                onClick={() => setShowDistrictDropdown(!showDistrictDropdown)}
              />
            ) : (
              <AiFillCaretDown
                id="dropdown-district-icon"
                className="icon"
                onClick={() => setShowDistrictDropdown(!showDistrictDropdown)}
              />
            ))}
        </div>
        <div
          style={{
            display: showDistrictDropdown ? "flex" : "none",
          }}
          id="dropdown-district-content"
          className="flex-column dropdown-content"
        >
          {list}
        </div>
      </div>
    );
  };

  // Dropdowns
  const toggleTypeDropdown = (i) => {
    if (typeDropdownIndex === i) {
      setShowTypeDropdown(!showTypeDropdown);
    } else {
      setShowTypeDropdown(true);
    }
    setTypeDropdownIndex(i);
  };
  const hideDropdowns = (event) => {
    const { id } = event.target;
    const parentId = event.target.parentElement
      ? event.target.parentElement.id
      : null;

    // Phone type dropdowns
    if (
      showTypeDropdown &&
      id !== "dropdown-type-text" &&
      id !== "dropdown-type-content" &&
      id !== "dropdown-type-icon" &&
      parentId !== "dropdown-type-icon"
    ) {
      setShowTypeDropdown(false);
      setTypeDropdownIndex(null);
    }

    // City dropdown
    if (
      showCityDropdown &&
      id !== "dropdown-city-text" &&
      id !== "dropdown-city-content" &&
      id !== "dropdown-city-icon" &&
      parentId !== "dropdown-city-icon"
    ) {
      setShowCityDropdown(false);
    }

    // District dropdown
    if (
      showDistrictDropdown &&
      id !== "dropdown-district-text" &&
      id !== "dropdown-district-content" &&
      id !== "dropdown-district-icon" &&
      parentId !== "dropdown-district-icon"
    ) {
      setShowDistrictDropdown(false);
    }
  };

  // Validation
  const validation = () => {
    let allValid = true;
    const newValid = isValid;

    for (const key of Object.keys(isValid)) {
      // Skip some fields
      if (key === "userNameValidInfo" || key === "passwordValidInfo") {
        continue;
      }
      // Password
      else if (key === "password") {
        if (password.current.value === "") {
          allValid = false;
          newValid[key][0] = false;
          newValid.passwordValidInfo = "missing";
        }

        if (checkPassword.current.value === "") {
          allValid = false;
          newValid[key][1] = false;
          newValid.passwordValidInfo = "missing";
          continue;
        }

        if (password.current.value !== checkPassword.current.value) {
          allValid = false;
          newValid[key] = false;
          newValid.passwordValidInfo = "no match";
        } else {
          newValid[key] = [true, true];
          newValid.passwordValidInfo = "";
        }
      }
      // DOB
      else if (key === "dob") {
        const { value } = dob.current;
        const validStartTime = new Date();
        validStartTime.setFullYear(validStartTime.getFullYear() - 160);

        if (
          value.length === 0 ||
          new Date(value) > new Date() ||
          new Date(value) < new Date(validStartTime)
        ) {
          allValid = false;
          newValid[key] = false;
        } else {
          newValid[key] = true;
        }
      }
      // Email
      else if (key === "email") {
        if (
          /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(
            email.current.value
          )
        ) {
          newValid[key] = true;
        } else {
          allValid = false;
          newValid[key] = false;
        }
      }
      // Phone number
      else if (key === "phoneNumber") {
        for (let i = 0; i < phoneNumber.length; i++) {
          let regex = null;

          if (phoneType[i] === "市話") {
            regex = /^(2|3|4|5|6|7|8)[-\s]?[0-9]{3,4}[-\s]?[0-9]{4}$/;
          } else if (phoneType[i] === "手機") {
            regex = /^9[-\s]?[0-9]{2}[-\s]?[0-9]{3}[-\s]?[0-9]{3}$/;
          }
          // Skip check if doesn't specify type
          else {
            continue;
          }

          if (!regex.test(phoneNumber[i])) {
            allValid = false;
            newValid[key][i] = false;
          } else {
            newValid[key][i] = true;
          }
        }
      }
      // User name, Full name, Address, City, District
      else {
        let field = null;

        if (key === "userName") {
          field = userName.current.value;
        } else if (key === "fullName") {
          field = fullName.current.value;
        } else if (key === "address") {
          field = address.current.value;
        } else if (key === "city") {
          field = city;
        } else if (key === "district") {
          field = district;
        }

        if (field === "") {
          allValid = false;
          newValid[key] = false;
        } else {
          newValid[key] = true;
        }
      }
    }
    setIsValid(newValid);
    setError(null);

    return allValid;
  };
  const determineInvalid = (field, index) => {
    // Phone
    if (index !== undefined) {
      return isValid[field][index] ? "" : " invalid-input";
    }
    // Zip code
    else if (field === "zipcode") {
      return isValid.city && isValid.district ? "" : " invalid-input";
    }
    // Others
    else {
      return isValid[field] ? "" : " invalid-input";
    }
  };
  const getInvalidMsg = (key, field) => {
    // User name
    if (key === "userName") {
      return isValid[key] || error ? "" : `請輸入${field}`;
    }
    // Password
    else if (key === "password") {
      if (isValid.passwordValidInfo === "") {
        return "";
      } else {
        return isValid.passwordValidInfo === "missing"
          ? `請輸入${field}`
          : `輸入的${field}不一致`;
      }
    }
    // DOB
    else if (key === "dob") {
      const { value } = dob.current;
      const validStartTime = new Date();
      validStartTime.setFullYear(validStartTime.getFullYear() - 160);

      if (
        new Date(value) > new Date() ||
        new Date(value) < new Date(validStartTime)
      ) {
        return `請輸入合理的${field}`;
      } else {
        return isValid[key] ? "" : `請輸入${field}`;
      }
    }
    // Email
    else if (key === "email") {
      return isValid[key] || error
        ? ""
        : `請輸入${field}或確認您的格式是否正確`;
    }
    // Phone number
    else if (key === "phoneNumber") {
      let allValid = true;
      isValid[key].forEach((elmnt) => {
        if (!elmnt) {
          allValid = false;
        }
      });
      return allValid ? "" : `請輸入${field}或確認您的格式是否正確`;
    }
    // Zipcode
    else if (key === "zipcode") {
      return isValid.city && isValid.district ? "" : `請輸入${field}`;
    }
    // Others
    else {
      return isValid[key] ? "" : `請輸入${field}`;
    }
  };

  // Submit
  async function handleSubmit() {
    // Validation
    const valid = validation();

    if (valid) {
      if (environment === "prod") {
        callSignUpEndpoint();
      } else {
        const data =
          `secret=${process.env.REACT_APP_CAPTCHA_SECRET_KEY}` +
          `&response=${token}`;
        await axios
          .post(process.env.REACT_APP_CAPTCHA_VERIFY_URL, data, {
            headers: { "x-api-key": process.env.REACT_APP_API_KEY },
          })
          .then((res) => {
            const { success } = res.data;

            if (success) {
              callSignUpEndpoint();
            } else {
              setError(
                "請完成驗證, 如果您認為有問題, 請與我們聯繫: " + contactEmail
              );
            }
          })
          .catch((err) => {
            console.log(err);
          });
      }
    }
  }

  // Endpoints
  async function callSignUpEndpoint() {
    const data = {
      userName: userName.current.value,
      password: password.current.value,
      company: company.current.value,
      fullName: fullName.current.value,
      gender,
      dob: dob.current.value,
      email: email.current.value,
      phoneType,
      phoneNumber: formatPhone(phoneNumber),
      address: address.current.value,
      city,
      district,
      zipcode,
      source:
        props.socialInfo && props.socialInfo.source
          ? props.socialInfo.source
          : "website",
    };

    await axios
      .post(`${process.env.REACT_APP_API}/accounts/signUp`, data, {
        headers: { "x-api-key": process.env.REACT_APP_API_KEY },
      })
      .then((res) => {
        if (res.status === 200) {
          // Show sign up complete pop up
          if (data.source === "website") {
            setPopUpType(1);
          } else {
            setPopUpType(2);
          }
          setError(null);
          setShowPopUp(true);
        }
      })
      .catch((err) => {
        if (err.response && err.response.status === 409) {
          if (err.response.data.email) {
            setIsValid((old) => {
              old.email = false;
              return old;
            });
            setError("此信箱已被註冊, 請移至登入頁面");
          } else if (err.response.data.userName) {
            setIsValid((old) => {
              old.userName = false;
              return old;
            });
            setError(
              "此帳號名已被註冊, 請確認您是否註冊過, 或使用其他用戶名註冊"
            );
          }
        } else {
          setError(
            "網頁錯誤, 目前無法註冊, 若您看到此訊息, 請與我們聯絡: " +
              contactEmail
          );
        }
      });
  }
  async function callSendVerifyEmailEndpoint() {
    await setPopUpError(null);
    await axios
      .put(`${process.env.REACT_APP_API}/accounts/verifyEmail`, {
        email: email.current.value,
      }, {
        headers: { "x-api-key": process.env.REACT_APP_API_KEY },
      })
      .then(() => {
        // Show resend verify email sucess
        setPopUpError("郵件已發送");
      })
      .catch(() => {
        setPopUpError(
          "網頁錯誤, 目前無法重寄驗證信, 若您看到此訊息, 請與我們聯絡: " +
            contactEmail
        );
      });
  }
  async function callSignInWithSocialEndpoint(email, source) {
    setError(null);
    await axios
      .post(`${process.env.REACT_APP_API}/accounts/signInWithSocial`, {
        email,
        source,
        cart: getCookie(),
      }, {
        headers: { "x-api-key": process.env.REACT_APP_API_KEY },
      })
      .then((res) => {
        // Sign in
        if (res.status === 200) {
          // Cookie
          const time = new Date(res.data.expires).toUTCString();
          setCookie("name", res.data.fullName, time);
          setCookie("user", res.data.userName, time);
          setCookie("x", res.headers["x-access-token"], time);

          // [ Cart ]
          const cart = res.data.cart.length > 0 ? res.data.cart[0] : null;
          if (cart && cart.id && cart.expires) {
            setCookie("cart", cart.id, new Date(cart.expires));
            // Update cart quantity
            props.setCartQuantity(res.data.quantity);
            // Update refresh cart state
            props.setRefreshCart(true);
          }

          // Show sign in complete pop up
          setSocialFullName(res.data.fullName);
          setPopUpType(3);
          setShowPopUp(true);

          // Set social info
          props.setSocialInfo({ source });
        }
        // Not register - go to sign up page
        else if (res.status === 204) {
          props.setShowSignUp(false);
          props.setShowSignUpSocial(true);
          props.setSocialInfo({
            email,
            source: "facebook",
          });
        }
      })
      .catch(() => {});
  }

  // Cookie
  const getCookie = () => {
    let { cookie } = document;
    cookie = cookie.split("; ");

    if (cookie.length > 0 && cookie[0] !== "") {
      for (let item of cookie) {
        item = item.split("=");
        if (item[0] === "cart") {
          return item[1];
        }
      }
    }

    return null;
  };
  const setCookie = (name, value, expires) => {
    document.cookie = name + "=" + value + "; expires=" + expires + "; path=/";
  };

  // Format phone
  const formatPhone = (phoneNumber) => {
    return phoneNumber.map((item) => {
      let format = item.replaceAll("-", "");
      format = format.replaceAll(" ", "");
      return format;
    });
  };

  // Pop up
  const popUpContent1 = () => {
    return (
      <div className="w-100 resend">
        <a>帳號註冊成功, 驗證郵件將在10分鐘內發送到您的信箱。</a>
        <a>若是您等了10分鐘還是沒有收到郵件, 您可以:</a>
        <ol>
          <li>先確認您的垃圾郵件夾。</li>
          <li>
            點擊<a onClick={callSendVerifyEmailEndpoint}>這裡</a>再次發送郵件。
          </li>
          <li>與我們聯繫: {contactEmail}</li>
        </ol>
        <div className="flex h-center">
          <a className="error-msg">{popUpError}</a>
        </div>
      </div>
    );
  };
  const popUpContent2 = () => {
    return (
      <a className="sign-up-social">
        帳號註冊成功, 您未來可以使用帳號密碼或是{props.socialInfo.source}登入。
      </a>
    );
  };
  const popUpContent3 = () => {
    return <a className="sign-up-social">您的帳號已註冊過, 自動將您登入。</a>;
  };
  const hidePopUp1 = () => {
    setShowPopUp(false);
    showSignIn();
  };
  const hidePopUp2 = () => {
    props.setUserFullName(socialFullName);
    props.setSignedIn(true);
    props.setShowSignUp(false);
    setShowPopUp(false);
  };
  const getPopUpContent = () => {
    if (popUpType === 1) {
      return popUpContent1();
    } else if (popUpType === 2) {
      return popUpContent2();
    } else {
      return popUpContent3();
    }
  };

  // Show sign in modal
  const showSignIn = () => {
    props.setShowSignIn(true);
    props.setShowSignInAnimation(false);
    props.setShowSignUp(false);
    props.setShowSignUpSocial(false);
  };

  // Facebook login
  const responseFacebook = (response) => {
    const { email } = response;

    if (email) {
      callSignInWithSocialEndpoint(email, "facebook");
    } else {
      setError("您的facebook登入有問題, 請稍後重試");
    }
  };

  return (
    <div
      id="page-enroll"
      style={{
        position: insidePage ? "static" : "fixed",
        height: insidePage ? "auto" : "100vh",
        width: insidePage ? "100%" : "100vw",
        zIndex: insidePage ? 0 : 3,
        backgroundColor: insidePage ? "var(--light3)" : "var(--dark6-9",
      }}
      className="flex h-center v-center resend"
    >
      <div
        style={{
          boxShadow: insidePage ? "none" : "5px 5px 10px var(--shadow)",
          overflowY: insidePage ? "auto" : "scroll",
        }}
        className="flex-column v-center content"
      >
        {!insidePage && (
          <CgClose
            className="close"
            onClick={() => props.setShowSignUp(false)}
          />
        )}
        <h2>加入會員</h2>
        <div className="flex-column text-inputs sign-up-inputs">
          {/* 帳號 */}
          <div className="flex-column v-start">
            <a>
              帳號<span>*</span>
            </a>
            <input
              type="text"
              ref={userName}
              className={determineInvalid("userName")}
            />
            <a className="invalid-msg">{getInvalidMsg("userName", "帳號")}</a>
          </div>
          {/* 密碼, 確認密碼 */}
          <div className="flex-column v-start password">
            <div className="flex h-between">
              <div className="flex-column v-start column">
                <a>
                  密碼<span>*</span>
                </a>
                <input
                  type={showPassword ? "text" : "password"}
                  ref={password}
                  className={determineInvalid("password", 0)}
                />
              </div>
              <div className="flex-column v-start column">
                <a>
                  確認密碼<span>*</span>
                </a>
                <input
                  type={showPassword ? "text" : "password"}
                  ref={checkPassword}
                  className={determineInvalid("password", 1)}
                />
                {!showPassword ? (
                  <HiOutlineEyeOff
                    className="icon"
                    onClick={updatePasswordIcon}
                  />
                ) : (
                  <HiOutlineEye className="icon" onClick={updatePasswordIcon} />
                )}
              </div>
            </div>
            <a className="invalid-msg">{getInvalidMsg("password", "密碼")}</a>
          </div>

          {/* 公司名稱 */}
          <div className="flex-column v-start company">
            <a>公司名稱</a>
            <input type="text" ref={company} />
          </div>
          {/* 會員姓名 */}
          <div className="flex-column v-start">
            <a>
              會員姓名<span>*</span>
            </a>
            <input
              type="text"
              ref={fullName}
              className={determineInvalid("fullName")}
            />
            <a className="invalid-msg">{getInvalidMsg("fullName", "姓名")}</a>
          </div>
          {/* 性別, 生日 */}
          <div className="flex h-between">
            <div className="flex-column v-start column">
              <a>
                性別<span>*</span>
              </a>
              <div className="flex h-center gender">
                <div className="flex h-end v-center">
                  <input
                    type="radio"
                    name="gender"
                    checked={gender === "先生"}
                    onChange={() => setGender("先生")}
                  />
                  <label onClick={() => setGender("先生")}>先生</label>
                </div>
                <div className="flex v-center">
                  <input
                    type="radio"
                    name="gender"
                    checked={gender === "小姐"}
                    onChange={() => setGender("小姐")}
                  />
                  <label onClick={() => setGender("小姐")}>小姐</label>
                </div>
              </div>
            </div>
            <div className="flex-column v-start column">
              <a>
                生日<span>*</span>
              </a>
              <input
                type="date"
                ref={dob}
                className={determineInvalid("dob")}
              />
              <a className="invalid-msg">{getInvalidMsg("dob", "生日")}</a>
            </div>
          </div>
          {/* 聯絡信箱 */}
          <div className="flex-column v-start">
            <a>
              聯絡信箱<span>*</span>
            </a>
            <input
              type="email"
              ref={email}
              className={determineInvalid("email")}
              disabled={props.socialInfo && props.socialInfo.email}
            />
            <a className="invalid-msg">{getInvalidMsg("email", "信箱")}</a>
          </div>
          {/* 聯絡電話 */}
          <div className="flex-column phone">
            {getLine()}
            <a className="invalid-msg">
              {getInvalidMsg("phoneNumber", "電話號碼")}
            </a>
          </div>
          {/* 聯絡地址 */}
          <div className="flex-column v-start">
            <a>
              聯絡地址<span>*</span>
            </a>
            <input
              type="text"
              ref={address}
              className={determineInvalid("address")}
            />
            <a className="invalid-msg">{getInvalidMsg("address", "地址")}</a>
          </div>
          {/* 縣市, 地區 */}
          <div className="flex h-between address-detail">
            <div className="flex-column v-start column">
              <a>
                縣市<span>*</span>
              </a>
              {getCities()}
              <a className="invalid-msg">{getInvalidMsg("city", "縣市")}</a>
            </div>
            <div className="flex-column v-start column">
              <a>
                行政區<span>*</span>
              </a>
              {getDistrict()}
              <a className="invalid-msg">
                {getInvalidMsg("district", "行政區")}
              </a>
            </div>
            <div className="flex-column v-start column zipcode">
              <a>
                郵遞區號<span>*</span>
              </a>
              <input
                type="text"
                value={zipcode}
                className={determineInvalid("zipcode")}
                disabled
              />
              <a className="invalid-msg">
                {getInvalidMsg("zipcode", "縣市和行政區")}
              </a>
            </div>
          </div>
          <div className="flex h-center captcha">
            <HCaptcha
              sitekey={process.env.REACT_APP_CAPTCHA_SITE_KEY}
              onError={() =>
                setError("網頁錯誤, 請刷新頁面或與我們聯絡: " + contactEmail)
              }
              onExpire={() => setError("驗證過期, 請刷新頁面")}
              onVerify={setToken}
            />
          </div>
        </div>
        {error !== null && <a className="error-msg">{error}</a>}
        <button className="button1 submit" onClick={handleSubmit}>
          確認
        </button>
        <div className="divider"></div>
        <div className="flex h-around social">
          <button className="flex h-center v-center button2 button-line">
            <FaLine />
            Line註冊
          </button>
          {/* <FacebookLogin
            appId={process.env.REACT_APP_FACEBOOK_APP_IP}
            fields="name,email"
            language="zh_TW"
            cssClass="flex h-center v-center button2 button-fb"
            textButton="FB註冊"
            icon={<FaFacebook />}
            callback={responseFacebook}
          /> */}
          {/* <button className="flex h-center v-center button2 button-google">
            <FaGoogle />
            Google註冊
          </button> */}
        </div>
        <button className="button1 sign-up" onClick={showSignIn}>
          會員登入
        </button>
      </div>
      {showPopUp && (
        <PopUp
          popUpContent={getPopUpContent}
          hidePopUp={
            popUpType === 1 || popUpType === 2 ? hidePopUp1 : hidePopUp2
          }
        />
      )}
    </div>
  );
}
