import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
// Pop up
import PopUp from "../../../../widgets/PopUps/PopUps";

export default function VerifyEmail(props) {
  const navigate = useNavigate();

  // Env
  const contactEmail = process.env.REACT_APP_CONTACT_EMAIL;

  // [ Pop up ]
  const [showPopUp, setShowPopUp] = useState(false);
  const [popUpMessage, setPopUpMessage] = useState("");

  // Get url params
  const getParams = () => {
    const str = window.location.search.substr(1);
    const params = str.split("&vCode=");

    if (params.length !== 2) {
      goHomePage();
    } else {
      // Get user name and vCode
      const userName = params[0].split("userName=")[1];
      const vCode = params[1];
      if (userName === undefined || vCode === undefined) {
        goHomePage();
      } else {
        verifyEmail(userName, vCode);
      }
    }
  };

  // Endpoint
  async function verifyEmail(userName, vCode) {
    await axios
      .put(`${process.env.REACT_APP_API}/accounts/verified`, {
        userName: userName,
        vCode: vCode,
      }, {
        headers: { "x-api-key": process.env.REACT_APP_API_KEY },
      })
      .then((res) => {
        if (res.status === 200) {
          setPopUpMessage("驗證成功!");
          setShowPopUp(true);
        }
      })
      .catch((err) => {
        if (err.response && err.response.status === 498) {
          setPopUpMessage(
            "驗證失敗, 您的驗證連結可能已經失效, 點擊確認將跳轉至登入頁面,  請重新登入發送驗證碼。若您持續看到此訊息, 請與我們聯絡: " +
              contactEmail
          );
        } else {
          setPopUpMessage(
            "網頁錯誤, 目前無法完成驗證, 若您看到此訊息, 請與我們聯絡: " +
              contactEmail
          );
        }
        setShowPopUp(true);
      });
  }

  // Go home page
  const goHomePage = () => {
    navigate("/");
  };

  // Pop up
  const popUpContent = () => {
    return <a>{popUpMessage}</a>;
  };
  const hidePopUp = () => {
    setShowPopUp(false);
    goHomePage();
    props.setShowSignIn(true);
    props.setShowSignInAnimation(true);
  };

  useEffect(() => {
    getParams();
  }, []);

  return (
    <div id="page-verify-email">
      {showPopUp && <PopUp popUpContent={popUpContent} hidePopUp={hidePopUp} />}
    </div>
  );
}
