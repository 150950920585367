import React, { useEffect, useState, useRef } from "react";
import axios from "axios";
import HCaptcha from "@hcaptcha/react-hcaptcha";
// Footer
import Footer from "../../widgets/Footer/Footer";
// Pop up
import PopUp from "../../widgets/PopUps/PopUps";
// Icons
import { FaLine, FaFacebook } from "react-icons/fa";
// Css
import "./contactUs.scss";

export default function ContactUs() {
  // [ Captcha ]
  const [token, setToken] = useState(null);
  // [ Error ]
  const [error, setError] = useState(null);
  // [ Form ]
  const name = useRef("");
  const company = useRef("");
  const email = useRef("");
  const phone = useRef("");
  const content = useRef("");
  // [ Validation ]
  const [isValid, setIsValid] = useState({
    name: true,
    email: true,
    content: true,
  });
  const [checkValid, setCheckValid] = useState(false);
  // [ Pop up ]
  const [showPopUp, setShowPopUp] = useState(false);

  // Env
  const contactEmail = process.env.REACT_APP_CONTACT_EMAIL;
  const environment = process.env.REACT_APP_ENVIRONMENT;

  useEffect(() => {
    if (checkValid) {
      setCheckValid(false);
    }
  }, [checkValid]);

  // Handle submit
  async function handleSubmit() {
    const valid = validation();
    if (valid) {
      if (environment === "prod") {
        callContactEndpoint();
      } else {
        const data =
          `secret=${process.env.REACT_APP_CAPTCHA_SECRET_KEY}` +
          `&response=${token}`;
        await axios
          .post(process.env.REACT_APP_CAPTCHA_VERIFY_URL, data, {
            headers: { "x-api-key": process.env.REACT_APP_API_KEY },
          })
          .then((res) => {
            const { success } = res.data;

            if (success) {
              callContactEndpoint();
            } else {
              setError(
                "請完成驗證, 如果您認為有問題, 請與我們聯繫: " + contactEmail
              );
            }
          })
          .catch((err) => {
            console.log(err);
          });
      }
    }
  }

  // Endpoint
  async function callContactEndpoint() {
    await axios
      .put(`${process.env.REACT_APP_API}/contact/`, {
        name: name.current.value,
        company: company.current.value,
        email: email.current.value,
        phone: phone.current.value,
        content: content.current.value,
      }, {
        headers: { "x-api-key": process.env.REACT_APP_API_KEY },
      })
      .then((res) => {
        if (res.status === 200) {
          setShowPopUp(true);
        }
      })
      .catch((err) => {
        setError(
          "很抱歉, 暫時無法發送您的消息, 請稍後再試或者聯絡我們: " +
            contactEmail
        );
      });
  }

  // Validation
  const validation = () => {
    let allValid = true;
    const newValid = isValid;

    for (const key of Object.keys(isValid)) {
      if (key === "email") {
        if (
          /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(
            email.current.value
          )
        ) {
          newValid[key] = true;
        } else {
          allValid = false;
          newValid[key] = false;
        }
      } else {
        const field = key === "name" ? name : content;

        if (field.current.value.length === 0) {
          allValid = false;
          newValid[key] = false;
        } else {
          newValid[key] = true;
        }
      }
    }
    setIsValid(newValid);
    setCheckValid(true);

    return allValid;
  };
  const determineInvalid = (field) => {
    return isValid[field] ? "" : " invalid-input";
  };

  // Pop up
  const popUpContent = () => {
    return <a>感謝您的留言, 我們會盡快回覆您, 謝謝。</a>;
  };
  const hidePopUp = () => {
    setShowPopUp(false);
    document.location.reload();
  };

  return (
    <div id="page-contact" className="flex-column v-center page">
      <div className="flex-column v-center page-content">
        <div className="flex-column content">
          <h2>聯絡我們</h2>
          {/* 線上客服 */}
          <a className="title">線上客服:</a>
          <a>服務時間: 週一至週五 09:00 - 18:00</a>
          <div className="flex chats">
            <button
              className="flex h-center v-center button2 button-line"
              onClick={() =>
                window.open("https://line.me/R/ti/p/@345ycbrl", "_blank")
              }
            >
              <FaLine />
              Line客服
            </button>
            <button
              className="flex h-center v-center button2 button-fb"
              onClick={() =>
                window.open("https://www.facebook.com/messages/t/102023319185686", "_blank")
              }
            >
              <FaFacebook />
              Facebook客服
            </button>
          </div>
          <div className="divider"></div>
          {/* 留言 */}
          <a className="title">留言:</a>
          <div className="flex-column form">
            <div className="flex h-between row">
              <div className="flex-column">
                <a>
                  姓名<span>*</span>:
                </a>
                <input
                  type="text"
                  ref={name}
                  className={determineInvalid("name")}
                ></input>
              </div>
              <div className="flex-column">
                <a>公司名稱:</a>
                <input type="text" ref={company}></input>
              </div>
            </div>
            <div className="flex h-between row">
              <div className="flex-column">
                <a>
                  電子信箱<span>*</span>:
                </a>
                <input
                  type="email"
                  ref={email}
                  className={determineInvalid("email")}
                ></input>
              </div>
              <div className="flex-column">
                <a>聯絡電話:</a>
                <input type="text" ref={phone}></input>
              </div>
            </div>
            <a>
              詢問或建議事項<span>*</span>:
            </a>
            <textarea ref={content} className={determineInvalid("content")} />
            <div className="captcha">
              <HCaptcha
                sitekey={process.env.REACT_APP_CAPTCHA_SITE_KEY}
                onError={() =>
                  setError("網頁錯誤, 請刷新頁面或與我們聯絡: " + contactEmail)
                }
                onExpire={() => setError("驗證過期, 請刷新頁面")}
                onVerify={setToken}
              />
            </div>
            {error !== null && <a className="error-msg">{error}</a>}
            <button className="button2" onClick={handleSubmit}>
              發送
            </button>
          </div>
        </div>
        {showPopUp && (
          <PopUp popUpContent={popUpContent} hidePopUp={hidePopUp} />
        )}
      </div>
      <Footer />
    </div>
  );
}
