import React, { useState, useEffect } from "react";
// Footer
import Footer from "../../widgets/Footer/Footer";
// QA List
import { qaList } from "../Data";
// Icons
import { AiFillPlusCircle, AiFillMinusCircle } from "react-icons/ai";
// Css
import "./qa.scss";

export default function QA() {
  const [showList, setShowList] = useState(
    new Array(qaList.length).fill(false)
  );
  const [updateShowList, setUpdateShowList] = useState(false);

  useEffect(() => {
    setUpdateShowList(false);
  }, [updateShowList]);

  const questionList = () => {
    const qElements = [];
    for (let i = 0; i < qaList.length; i++) {
      const { question, answer, table, list } = qaList[i];

      qElements.push(
        <div key={"list" + i} className="list-container">
          {/* Title */}
          <div
            key={"list_title_container" + i}
            className="flex h-between v-center"
          >
            <a key={"list_title" + i} className="list-title">
              {i + 1 + ". " + question}
            </a>
            {!showList[i] && (
              <AiFillPlusCircle
                onClick={() => {
                  setShowList((old) => {
                    old[i] = !old[i];
                    return old;
                  });
                  setUpdateShowList(true);
                }}
              />
            )}
            {showList[i] && (
              <AiFillMinusCircle
                onClick={() => {
                  setShowList((old) => {
                    old[i] = !old[i];
                    return old;
                  });
                  setUpdateShowList(true);
                }}
              />
            )}
          </div>
          {/* Content */}
          {showList[i] && (
            <div key={"list_content" + i} className="list-content">
              <a key={"list_answer" + i}>{answer}</a>
              {table.length > 0 && (
                <div key={"list_table" + i} className="list-table">
                  {getTable(table, i)}
                </div>
              )}
              {list.length > 0 && (
                <div
                  key={"list_list" + i}
                  className="flex-column list-list"
                  style={{
                    marginTop:
                      answer.length === 0 && table.length === 0
                        ? "-2px"
                        : "6px",
                  }}
                >
                  {getList(list, i)}
                </div>
              )}
            </div>
          )}
        </div>
      );
    }

    return qElements;
  };

  // Table
  const getTable = (table, i) => {
    const row = [];

    for (let j = 0; j < table.length; j++) {
      let k = 0;
      let listCount = 0;
      const list = [];
      const { title, content } = table[j];

      // Get list
      content.forEach((object) => {
        const { text, isList } = object;

        if (isList) {
          list.push(
            <div
              key={`list_table_content_container${i}-${j}-${k}`}
              className="flex v-center list_table_content_list"
            >
              <a key={`list_table_content_num${i}-${j}-${k}`}>
                {listCount + 1 + "."}
              </a>
              <a key={`list_table_content_text${i}-${j}-${k}`}>{text}</a>
            </div>
          );
          listCount++;
        } else {
          list.push(
            <a key={`list_table_content_text${i}-${j}-${k}`}>{text}</a>
          );
        }
        k++;
      });

      // Get table row
      row.push(
        <div
          key={`list_table_element${i}-${j}`}
          className="flex list-table-element"
        >
          <a key={`list_table_title${i}-${j}`} className="list-table-column">
            {title}
          </a>
          <div
            key={`list_table_row_list${i}-${j}`}
            className="flex-column list-table-column"
          >
            {list}
          </div>
        </div>
      );
    }

    return row;
  };

  // List
  const getList = (list, i) => {
    let listCount = 0;
    const row = [];

    for (let j = 0; j < list.length; j++) {
      const { content, emphasize, isList } = list[j];

      if (isList) {
        row.push(
          <div
            key={`list_list_container${i}-${j}`}
            className="flex v-center list-list-container"
          >
            <a key={`list_list_num${i}-${j}`}>{listCount + 1 + "."}</a>
            <a key={`list_list${i}-${j}`}>{content}</a>
          </div>
        );
        listCount++;
      } else {
        const style = {
          color: "inherit",
          fontWeight: "normal",
          margin: "0",
        };

        if (emphasize === 1) {
          style.color = "red";
          style.margin = "2px 0";
        }
        if (emphasize === 2) {
          style.fontWeight = "bold";
          style.margin = "2px 0";
        }

        row.push(
          <a key={`list_list${i}-${j}`} style={style}>
            {content}
          </a>
        );
      }
    }

    return row;
  };

  return (
    <div id="page-qa" className="flex-column v-center page">
      <div className="flex-column v-center page-content">
        <h2>Q&A</h2>
        <div className="flex h-end toggle-menu">
          <a
            onClick={() => {
              setShowList(new Array(qaList.length).fill(true));
            }}
          >
            [ 全部展開 ＋ ]
          </a>
          <a
            onClick={() => {
              setShowList(new Array(qaList.length).fill(false));
            }}
          >
            [ 全部收起 － ]
          </a>
        </div>
        {questionList()}
      </div>
      <Footer />
    </div>
  );
}
