import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
// Zipcode
import { zipCodes, cities, districts } from "use-tw-zipcode";
// Footer
import Footer from "../../../widgets/Footer/Footer";
// Pop up
import PopUp from "../../../widgets/PopUps/PopUps";
// Icons
import { BsFillPersonFill } from "react-icons/bs";
import { FiEdit3 } from "react-icons/fi";
import { HiOutlineEye, HiOutlineEyeOff } from "react-icons/hi";
import { AiFillCaretDown, AiFillCaretUp } from "react-icons/ai";
import { MdLibraryAdd, MdRemove } from "react-icons/md";
// Css
import "./userInfo.css";

export default function UserInfo(props) {
  const navigate = useNavigate();

  // [ Pop up ]
  const [showPopUp, setShowPopUp] = useState(false);
  const [popUpType, setPopUpType] = useState(false);
  // [ Error ]
  const [error, setError] = useState(null);
  // [ Auth ]
  const [validAuth, setValidAuth] = useState(false);
  // [ User info ]
  // Info
  const [userInfo, setUserInfo] = useState({});
  const [data, setData] = useState("");
  // User name
  const [userName, setUserName] = useState("");
  // Password
  const [codeSent, setCodeSent] = useState(false);
  const [countDown, setCountDown] = useState(null);
  const [code, setCode] = useState("");
  const [codeValid, setCodeValid] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [password, setPassword] = useState("");
  const [checkPassword, setCheckPassword] = useState("");
  // Company
  const [company, setCompany] = useState("");
  // Full name
  const [fullName, setFullName] = useState("");
  // gender
  const [gender, setGender] = useState("");
  // dob
  const [dob, setDob] = useState("");
  // email
  const [email, setEmail] = useState("");
  // Phone
  const [lines, setLines] = useState(1);
  const [phoneType, setPhoneType] = useState(["手機"]);
  const [updatePhoneType, setUpdatePhoneType] = useState(false);
  const [phoneNumber, setPhoneNumber] = useState([""]);
  const [updatePhoneNumber, setUpdatePhoneNumber] = useState(false);
  // Address
  const [address, setAddress] = useState("");
  const [city, setCity] = useState("");
  const [district, setDistrict] = useState("");
  const [zipcode, setZipcode] = useState("--");
  // Update field
  const [onEdit, setOnEdit] = useState(false);
  // Need verification
  const [needVerify, setNeedVerify] = useState(false);
  // Validation
  const [isValid, setIsValid] = useState({
    password: [true, true],
    fullName: true,
    dob: true,
    email: true,
    phoneNumber: [true],
    address: true,
    city: true,
    district: true,
  });
  // [ Dropdowns ]
  // Gender
  const [showGenderDropdown, setShowGenderDropdown] = useState(false);
  // Phone type
  const [showTypeDropdown, setShowTypeDropdown] = useState(false);
  const [typeDropdownIndex, setTypeDropdownIndex] = useState(null);
  // City
  const [showCityDropdown, setShowCityDropdown] = useState(false);
  // District
  const [showDistrictDropdown, setShowDistrictDropdown] = useState(false);
  // [ Event listener ]
  const [click, setClick] = useState(null);
  const [enter, setEnter] = useState(false);
  // [ Hint ]
  const [hint, setHint] = useState(null);

  // Env
  const contactEmail = process.env.REACT_APP_CONTACT_EMAIL;

  useEffect(() => {
    // Check for auth
    const info = getCookie();
    setUserInfo(info);
    if (info) {
      callGetUserInfoEndpoint(info);
    } else {
      setShowPopUp(true);
      setPopUpType(1);
    }

    // Event listener - mouse click
    const handleClick = (event) => {
      setClick(event);
    };
    const keyDownHandler = (event) => {
      if (event.key === "Enter") {
        setEnter(true);
      }
    };

    document.addEventListener("click", handleClick);
    document.addEventListener("keydown", keyDownHandler);

    return () => {
      document.removeEventListener("click", handleClick);
      document.removeEventListener("keydown", keyDownHandler);
    };
  }, []);
  useEffect(() => {
    if (countDown !== null && countDown > 0) {
      setTimeout(() => {
        setCountDown((old) => old - 1);
      }, 1000);
    } else {
      setCodeSent(false);
      setCountDown(null);
    }
  }, [countDown]);
  // Phone input change
  useEffect(() => {
    // Phone type
    if (updatePhoneType) {
      setUpdatePhoneType(false);
    }

    // Phone number
    if (updatePhoneNumber) {
      setUpdatePhoneNumber(false);
    }

    // Click event
    if (click) {
      hideDropdowns(click);
      setClick(null);
    }

    // Enter key press event
    if (enter) {
      handleSubmit();
      setEnter(false);
    }
  }, [updatePhoneType, updatePhoneNumber, click, enter]);

  // Validation
  const validation = () => {
    let allValid = true;
    const newValid = isValid;

    for (const key of Object.keys(isValid)) {
      // Password
      if (key === "password") {
        if (!codeValid) {
          continue;
        }

        if (password === "") {
          allValid = false;
          newValid[key][0] = false;
        }

        if (checkPassword === "") {
          allValid = false;
          newValid[key][1] = false;
          continue;
        }

        if (password !== checkPassword) {
          allValid = false;
          newValid[key] = false;
        } else {
          newValid[key] = [true, true];
        }
      }
      // DOB
      else if (key === "dob") {
        const validStartTime = new Date();
        validStartTime.setFullYear(validStartTime.getFullYear() - 160);

        if (
          dob.length === 0 ||
          new Date(dob) > new Date() ||
          new Date(dob) < new Date(validStartTime)
        ) {
          allValid = false;
          newValid[key] = false;
        } else {
          newValid[key] = true;
        }
      }
      // Email
      else if (key === "email") {
        if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(email)) {
          newValid[key] = true;
        } else {
          allValid = false;
          newValid[key] = false;
        }
      }
      // Phone number
      else if (key === "phoneNumber") {
        for (let i = 0; i < phoneNumber.length; i++) {
          let regex = null;

          if (phoneType[i] === "市話") {
            regex = /^(2|3|4|5|6|7|8)[-\s]?[0-9]{3,4}[-\s]?[0-9]{4}$/;
          } else if (phoneType[i] === "手機") {
            regex = /^9[-\s]?[0-9]{2}[-\s]?[0-9]{3}[-\s]?[0-9]{3}$/;
          }
          // Skip check if doesn't specify type
          else {
            continue;
          }

          if (!regex.test(phoneNumber[i])) {
            allValid = false;
            newValid[key][i] = false;
          } else {
            newValid[key][i] = true;
          }
        }
      }
      // Full name, Address, City, District
      else {
        let field = null;

        if (key === "fullName") {
          field = fullName;
        } else if (key === "address") {
          field = address;
        } else if (key === "city") {
          field = city;
        } else if (key === "district") {
          field = district;
        }

        if (field === "") {
          allValid = false;
          newValid[key] = false;
        } else {
          newValid[key] = true;
        }
      }
    }
    setIsValid(newValid);

    return allValid;
  };
  const determineInvalid = (field, index) => {
    // Password
    if (index !== undefined) {
      return isValid[field][index] ? "" : " invalid-input";
    } else if (field === "zipcode") {
      return isValid.city && isValid.district ? "" : " invalid-input";
    }
    // Others
    else {
      return isValid[field] ? "" : " invalid-input";
    }
  };

  // Save current change
  const handleSubmit = () => {
    // Validation
    const valid = validation();

    if (valid) {
      callUpdateUserInfoEndpoint();
    }
  };

  // Reset states
  const handleCancel = () => {
    updateUserInfo(data);
    setCodeSent(false);
    setCountDown(null);
    setCode("");
    setCodeValid(false);
    setShowPassword(false);
    setPassword("");
    setCheckPassword("");
    setIsValid({
      password: [true, true],
      fullName: true,
      dob: true,
      email: true,
      phoneNumber: [true],
      address: true,
      city: true,
      district: true,
    });
    setError(null);
    setHint(null);
    setOnEdit(false);
    setNeedVerify(false);
  };

  // Get cookie
  const getCookie = () => {
    let cookie = document.cookie;
    cookie = cookie.split("; ");

    if (cookie.length === 1 && cookie[0] === "") {
      return null;
    } else {
      const info = {};
      cookie.forEach((item) => {
        const str = item.split("=");
        info[str[0]] = str[1];
      });

      return info;
    }
  };

  // Cookies
  const clearCookie = (name, value, expires) => {
    document.cookie = name + "=" + value + "; expires=" + expires + "; path=/";
  };

  // Format phone
  const formatPhone = (phoneNumber) => {
    return phoneNumber.map((item) => {
      let format = item.replaceAll("-", "");
      format = format.replaceAll(" ", "");
      return format;
    });
  };

  // Endpoint
  async function callGetUserInfoEndpoint(info) {
    await axios
      .post(
        `${process.env.REACT_APP_API}/accountInfo/getInfo`,
        {
          userName: info.user,
        },
        {
          headers: {
            "x-access-token": info.x,
            "x-api-key": process.env.REACT_APP_API_KEY
          },
        }
      )
      .then((res) => {
        if (res.status === 200) {
          props.setUserFullName(info.name);
          props.setSignedIn(true);
          setValidAuth(true);

          // Update user info
          setData(res.data);
          updateUserInfo(res.data);
        }
      })
      .catch(() => {
        setShowPopUp(true);
        setPopUpType(1);
      });
  }
  async function callSendVerifyCodeEndpoint() {
    setHint(null);

    await axios
      .post(
        `${process.env.REACT_APP_API}/accountInfo/sendVerifyCode`,
        { userName },
        { headers: {
          "x-access-token": userInfo.x,
          "x-api-key": process.env.REACT_APP_API_KEY
        } }
      )
      .then((res) => {
        if (res.status === 200) {
          const time = 60;
          setHint(
            "驗證碼已發送到您的信箱, 若是您等了10分鐘還是沒有收到郵件, 可以先確認您的垃圾郵件夾"
          );
          setCodeSent(true);
          setCountDown(time);
        }
      })
      .catch(() => {
        setShowPopUp(true);
        setPopUpType(1);
      });
  }
  async function callValidationEndpoint() {
    setHint(null);
    await axios
      .post(
        `${process.env.REACT_APP_API}/accountInfo/validation`,
        { userName, code },
        { headers: {
          "x-access-token": userInfo.x,
          "x-api-key": process.env.REACT_APP_API_KEY
        } }
      )
      .then((res) => {
        if (res.status === 200) {
          setCodeValid(true);
        }
      })
      .catch((err) => {
        if (err.response && err.response.status === 403) {
          if (err.response.data === "Invalid") {
            setHint("您的驗證可能已經過期, 請重新發送確認碼。");
          } else {
            setShowPopUp(true);
            setPopUpType(1);
          }
        } else {
          setHint(
            "很抱歉, 網站出現錯誤。 若您看到此訊息, 請與我們聯絡: " +
              contactEmail
          );
        }
      });
  }
  async function callUpdateUserInfoEndpoint() {
    const info = {
      userName,
      fullName,
      gender,
      company,
      dob,
      phoneType,
      phoneNumber: formatPhone(phoneNumber),
      email,
      zipcode,
      city,
      district,
      address,
    };

    if (codeValid) {
      info.password = password;
    }

    setError(null);
    await axios
      .post(`${process.env.REACT_APP_API}/accountInfo/updateInfo`, info, {
        headers: {
          "x-access-token": userInfo.x,
          "x-api-key": process.env.REACT_APP_API_KEY
        },
      })
      .then((res) => {
        if (res.status === 200) {
          if (res.data.needVerify) {
            setNeedVerify(true);
            setPopUpType(1);
          } else {
            setPopUpType(2);
          }
          setShowPopUp(true);
        }
      })
      .catch((err) => {
        if (err.response && err.response.status === 409) {
          setIsValid((old) => {
            old.email = false;
            return old;
          });
          setError("此信箱已被註冊");
        } else {
          setError(
            "網頁錯誤, 目前無法更新您的資料, 請稍後重試, 或與我們聯絡: " +
              contactEmail
          );
        }
      });
  }

  // Update user info
  const updateUserInfo = (data) => {
    const { info, phones } = data;

    setUserName(info.userName);
    setFullName(info.fullName);
    setGender(info.gender);
    setDob(info.dob);
    setCompany(info.company);
    setEmail(info.email);
    setZipcode(info.zipcode);
    setCity(info.city);
    setDistrict(info.district);
    setAddress(info.address);

    // Phones
    const phoneTs = [];
    const phoneNs = [];
    phones.forEach((item) => {
      let num = item.number;
      if (item.type === "手機") {
        num = num.slice(0, 3) + "-" + num.slice(3, 6) + "-" + num.slice(6, 9);
      } else {
        num = num[0] + " " + num.slice(1, -4) + "-" + num.slice(-4);
      }

      phoneTs.push(item.type);
      phoneNs.push(num);
    });
    setLines(phones.length);
    setPhoneType(phoneTs);
    setPhoneNumber(phoneNs);
  };

  // Pop up
  const popUpContent1 = () => {
    return (
      <a>
        {needVerify
          ? "資料更新成功, 由於您註冊了新的email, 請驗證後重新登入"
          : "抱歉, 您的登入驗證已過期, 請重新登入"}
      </a>
    );
  };
  const popUpContent2 = () => {
    return <a>資料更新成功</a>;
  };
  const hidePopUp1 = () => {
    // Clear cookies
    const time = new Date().toUTCString();
    clearCookie("name", "", time);
    clearCookie("user", "", time);
    clearCookie("x", "", time);
    // Sign out
    props.setSignedIn(false);
    props.setUserFullName(null);
    props.setShowSignInAnimation(true);
    setError(null);
    setNeedVerify(false);
    setPopUpType(null);
    setShowPopUp(false);
    // Go home page
    navigate("/");
  };
  const hidePopUp2 = () => {
    setOnEdit(!onEdit);
    // Reset states
    setCodeSent(false);
    setCountDown(null);
    setCode("");
    setCodeValid(false);
    setShowPassword(false);
    setPassword("");
    setCheckPassword("");
    setShowPopUp(false);
    setError(null);
    setPopUpType(null);
    setNeedVerify(false);
  };

  // Phone
  const getLine = () => {
    const list = [];

    for (let i = 0; i < lines; i++) {
      list.push(
        <div
          key={"phone-line" + i}
          className="flex h-between v-start phone-line"
        >
          <div key={"phone-input" + i} className="flex h-between phone-input">
            <div key={"phone_div" + i} className="flex v-center phone-item">
              <a key={"phone_title" + i} className="phone-title">
                號碼{i + 1}:
              </a>
              {!onEdit ? (
                <a key={"phone_input" + i} className="input">
                  {`+886${phoneType[i] === "手機" ? "-" : " "}` +
                    phoneNumber[i]}
                </a>
              ) : (
                <div className="flex h-between v-center line-sub-div">
                  <div
                    key={"phone-type-dropdown" + i}
                    className="flex-column dropdown"
                  >
                    <div
                      key={"phone-type-dropdown-text-div" + i}
                      className="flex h-between v-center dropdown-text"
                    >
                      <a
                        key={"phone-type-dropdown-text" + i}
                        id="dropdown-type-text"
                        onClick={() => {
                          toggleTypeDropdown(i);
                        }}
                      >
                        {phoneType[i]}
                      </a>
                      {showTypeDropdown && typeDropdownIndex == i ? (
                        <AiFillCaretUp
                          id="dropdown-type-icon"
                          onClick={() => {
                            toggleTypeDropdown(i);
                          }}
                        />
                      ) : (
                        <AiFillCaretDown
                          id="dropdown-type-icon"
                          onClick={() => {
                            toggleTypeDropdown(i);
                          }}
                        />
                      )}
                    </div>
                    <div
                      key={"phone-type-dropdown-content" + i}
                      style={{
                        display:
                          showTypeDropdown && typeDropdownIndex == i
                            ? "flex"
                            : "none",
                      }}
                      id="dropdown-type-content"
                      className="flex-column dropdown-content"
                    >
                      <a
                        key={"phone-type-dropdown-content-phone" + i}
                        onClick={() => {
                          setPhoneType((old) => {
                            old[i] = "手機";
                            return old;
                          });
                          setUpdatePhoneType(true);
                        }}
                      >
                        手機
                      </a>
                      <a
                        key={"phone-type-dropdown-content-landline" + i}
                        onClick={() => {
                          setPhoneType((old) => {
                            old[i] = "市話";
                            return old;
                          });
                          setUpdatePhoneType(true);
                        }}
                      >
                        市話
                      </a>
                    </div>
                  </div>
                  <div className="flex h-end">
                    <input
                      className={determineInvalid("phoneNumber", i)}
                      value="+886"
                      disabled
                    />
                    <input
                      key={"phone_input_number" + i}
                      className={determineInvalid("phoneNumber", i)}
                      type="text"
                      value={phoneNumber[i]}
                      onChange={(event) => {
                        setPhoneNumber((arr) => {
                          arr[i] = event.target.value;
                          return arr;
                        });
                        setUpdatePhoneNumber(true);
                      }}
                    />
                  </div>
                  {i === 0 ? (
                    <MdLibraryAdd
                      key={"phone-icon" + i}
                      className="icon"
                      onClick={() => {
                        updatePhoneLine("add");
                      }}
                    />
                  ) : (
                    <MdRemove
                      key={"phone-icon" + i}
                      className="icon icon-remove"
                      onClick={() => {
                        updatePhoneLine("delete", i);
                      }}
                    />
                  )}
                </div>
              )}
            </div>
          </div>
        </div>
      );
    }

    return list;
  };
  const updatePhoneLine = (action, index) => {
    if (action === "add") {
      setLines(lines + 1);
      setPhoneType((old) => {
        old.push("手機");
        return old;
      });
      setPhoneNumber((old) => {
        old.push("");
        return old;
      });
      setIsValid((old) => {
        old.phoneNumber.push(true);
        return old;
      });
    } else {
      setLines(lines - 1);
      setPhoneType((old) => {
        old.splice(index, 1);
        return old;
      });
      setPhoneNumber((old) => {
        old.splice(index, 1);
        return old;
      });
      setIsValid((old) => {
        old.phoneNumber.splice(index, 1);
        return old;
      });
    }
  };

  // City
  const getCities = () => {
    const list = [];

    for (let i = 0; i < cities.length; i++) {
      list.push(
        <a
          key={"city-dropdown-content-text" + i}
          onClick={() => {
            setCity(cities[i]);
            setDistrict("");
            setZipcode("--");
          }}
        >
          {cities[i]}
        </a>
      );
    }

    return (
      <div className="flex-column dropdown">
        <div
          className={
            "flex h-between v-center dropdown-text" + determineInvalid("city")
          }
        >
          <a
            id="dropdown-city-text"
            onClick={() => setShowCityDropdown(!showCityDropdown)}
          >
            {city === "" ? "--" : city}
          </a>
          {showCityDropdown ? (
            <AiFillCaretUp
              id="dropdown-city-icon"
              onClick={() => setShowCityDropdown(!showCityDropdown)}
            />
          ) : (
            <AiFillCaretDown
              id="dropdown-city-icon"
              onClick={() => setShowCityDropdown(!showCityDropdown)}
            />
          )}
        </div>
        <div
          style={{
            display: showCityDropdown ? "flex" : "none",
          }}
          id="dropdown-city-content"
          className="flex-column dropdown-content"
        >
          {list}
        </div>
      </div>
    );
  };

  // Distrct
  const getDistrict = () => {
    const list = [];
    const districtList = districts[city] ? districts[city] : [];

    for (let i = 0; i < districtList.length; i++) {
      list.push(
        <a
          key={"district-dropdown-content-text" + i}
          onClick={() => {
            setDistrict(districtList[i]);
            setZipcode(zipCodes[city][districtList[i]]);
          }}
        >
          {districtList[i]}
        </a>
      );
    }

    return (
      <div className="flex-column dropdown">
        <div
          className={
            "flex h-between v-center dropdown-text" +
            determineInvalid("district")
          }
        >
          <a
            id="dropdown-district-text"
            style={{ cursor: districtList.length > 0 ? "pointer" : "auto" }}
            onClick={() => {
              setShowDistrictDropdown(
                districtList.length > 0 ? !showDistrictDropdown : false
              );
            }}
          >
            {district === "" ? "--" : district}
          </a>
          {districtList.length > 0 &&
            (showDistrictDropdown ? (
              <AiFillCaretUp
                id="dropdown-district-icon"
                onClick={() => setShowDistrictDropdown(!showDistrictDropdown)}
              />
            ) : (
              <AiFillCaretDown
                id="dropdown-district-icon"
                onClick={() => setShowDistrictDropdown(!showDistrictDropdown)}
              />
            ))}
        </div>
        <div
          style={{
            display: showDistrictDropdown ? "flex" : "none",
          }}
          id="dropdown-district-content"
          className="flex-column dropdown-content"
        >
          {list}
        </div>
      </div>
    );
  };

  // Dropdowns
  const toggleTypeDropdown = (i) => {
    if (typeDropdownIndex === i) {
      setShowTypeDropdown(!showTypeDropdown);
    } else {
      setShowTypeDropdown(true);
    }
    setTypeDropdownIndex(i);
  };
  const hideDropdowns = (event) => {
    const { id } = event.target;
    const parentId = event.target.parentElement
      ? event.target.parentElement.id
      : null;

    // Gender dropdowns
    if (
      showGenderDropdown &&
      id !== "dropdown-gender-text" &&
      id !== "dropdown-gender-content" &&
      id !== "dropdown-gender-icon" &&
      parentId !== "dropdown-gender-icon"
    ) {
      setShowGenderDropdown(false);
    }

    // Phone type dropdowns
    if (
      showTypeDropdown &&
      id !== "dropdown-type-text" &&
      id !== "dropdown-type-content" &&
      id !== "dropdown-type-icon" &&
      parentId !== "dropdown-type-icon"
    ) {
      setShowTypeDropdown(false);
      setTypeDropdownIndex(null);
    }

    // City dropdown
    if (
      showCityDropdown &&
      id !== "dropdown-city-text" &&
      id !== "dropdown-city-content" &&
      id !== "dropdown-city-icon" &&
      parentId !== "dropdown-city-icon"
    ) {
      setShowCityDropdown(false);
    }

    // District dropdown
    if (
      showDistrictDropdown &&
      id !== "dropdown-district-text" &&
      id !== "dropdown-district-content" &&
      id !== "dropdown-district-icon" &&
      parentId !== "dropdown-district-icon"
    ) {
      setShowDistrictDropdown(false);
    }
  };

  return (
    <div id="page-user-info" className="flex-column v-center page">
      <div className="flex-column v-center page-content">
        {validAuth && (
          <div className="flex-column v-center page-content-main">
            <BsFillPersonFill className="main-icon" />
            <div className="flex-column content">
              <div className="flex h-center v-center page-main-title">
                <h2>個人資料</h2>
                {!onEdit && <FiEdit3 onClick={() => setOnEdit(!onEdit)} />}
              </div>
              <div className="flex-column field account-field">
                <div className="flex v-center main-title">
                  <a>帳號</a>
                </div>
                <div
                  className={`${onEdit ? "flex-column" : "flex h-between"} row`}
                >
                  {/* 帳號 */}
                  <div className="flex v-center">
                    <a className="title">帳號:</a>
                    <a className="input">{userName}</a>
                  </div>
                  {/* 密碼 */}
                  <div
                    className="flex v-center password"
                    style={{ width: !onEdit ? "40%" : "100%" }}
                  >
                    <a className="title">密碼:</a>
                    {!onEdit ? (
                      <a className="input">********</a>
                    ) : !codeValid ? (
                      <div className="flex v-center">
                        <input
                          className="confirm-input"
                          type="text"
                          value={code}
                          onChange={(event) => setCode(event.target.value)}
                          placeholder="確認碼"
                        />
                        <button
                          className="button1"
                          style={{
                            width: countDown === null ? "80px" : "110px",
                          }}
                          onClick={callSendVerifyCodeEndpoint}
                          disabled={codeSent}
                        >
                          {countDown === null
                            ? "發送確認碼"
                            : "發送確認碼(" + countDown + ")"}
                        </button>
                        <a className="confirm" onClick={callValidationEndpoint}>
                          確認
                        </a>
                      </div>
                    ) : (
                      <div className="flex v-center new-password">
                        <input
                          className={
                            "password-input" + determineInvalid("password", 0)
                          }
                          type={showPassword ? "text" : "password"}
                          value={password}
                          onChange={(event) => setPassword(event.target.value)}
                          placeholder="新密碼"
                        />
                        <input
                          className={determineInvalid("password", 1)}
                          type={showPassword ? "text" : "password"}
                          value={checkPassword}
                          onChange={(event) =>
                            setCheckPassword(event.target.value)
                          }
                          placeholder="確認密碼"
                        />
                        {!showPassword ? (
                          <HiOutlineEyeOff
                            onClick={() => setShowPassword(!showPassword)}
                          />
                        ) : (
                          <HiOutlineEye
                            onClick={() => setShowPassword(!showPassword)}
                          />
                        )}
                      </div>
                    )}
                  </div>
                </div>
                {onEdit && hint && <a className="password-msg">{hint}</a>}
              </div>
              <div className="flex-column field">
                <div className="flex v-center main-title">
                  <a>會員資料</a>
                </div>
                <div className="flex h-between row">
                  {/* 會員姓名 */}
                  <div className="flex v-center">
                    <a className="title">會員姓名:</a>
                    {!onEdit ? (
                      <a className="input">{fullName}</a>
                    ) : (
                      <input
                        className={determineInvalid("fullName")}
                        type="text"
                        value={fullName}
                        onChange={(event) => setFullName(event.target.value)}
                      />
                    )}
                  </div>
                  {/* 性別 */}
                  <div className="flex v-center gender">
                    <a className="title">性別:</a>
                    {!onEdit ? (
                      <a className="input">{gender}</a>
                    ) : (
                      <div className="flex-column dropdown">
                        <div className="flex h-between v-center dropdown-text">
                          <a
                            id="dropdown-gender-text"
                            onClick={() =>
                              setShowGenderDropdown(!showGenderDropdown)
                            }
                          >
                            {gender}
                          </a>
                          {showGenderDropdown ? (
                            <AiFillCaretUp
                              id="dropdown-gender-icon"
                              onClick={() =>
                                setShowGenderDropdown(!showGenderDropdown)
                              }
                            />
                          ) : (
                            <AiFillCaretDown
                              id="dropdown-gender-icon"
                              onClick={() =>
                                setShowGenderDropdown(!showGenderDropdown)
                              }
                            />
                          )}
                          <div
                            style={{
                              display: showGenderDropdown ? "flex" : "none",
                            }}
                            id="dropdown-gender-content"
                            className="flex-column dropdown-content"
                          >
                            <a
                              onClick={() => {
                                setGender("先生");
                                setShowGenderDropdown(!showGenderDropdown);
                              }}
                            >
                              先生
                            </a>
                            <a
                              onClick={() => {
                                setGender("小姐");
                                setShowGenderDropdown(!showGenderDropdown);
                              }}
                            >
                              小姐
                            </a>
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
                <div className="flex h-between row">
                  {/* 公司名稱 */}
                  <div className="flex v-center">
                    <a className="title">公司名稱:</a>
                    {!onEdit ? (
                      <a className="input">
                        {company === "" ? "(無)" : company}
                      </a>
                    ) : (
                      <input
                        type="text"
                        value={company ? company : ""}
                        onChange={(event) => setCompany(event.target.value)}
                        placeholder={company === "" ? "(無)" : ""}
                      />
                    )}
                  </div>
                  {/* 生日 */}
                  <div className="flex v-center dob">
                    <a className="title">生日:</a>
                    {!onEdit ? (
                      <a className="input">{dob.replaceAll("-", " / ")}</a>
                    ) : (
                      <input
                        className={determineInvalid("dob")}
                        type="date"
                        value={dob}
                        onChange={(event) => setDob(event.target.value)}
                      />
                    )}
                  </div>
                </div>
              </div>
              <div className="flex-column field">
                <div className="flex v-center main-title">
                  <a>聯絡資訊</a>
                </div>
                {/* 聯絡電話 */}
                <div className="flex row phones">
                  <a className="flex v-center title">聯絡電話:</a>
                  <div className="flex-column line-div">{getLine()}</div>
                </div>
                <div className="flex row">
                  {/* 聯絡信箱 */}
                  <div className="flex v-center email">
                    <a className="title">聯絡信箱:</a>
                    {!onEdit ? (
                      <a className="input">{email}</a>
                    ) : (
                      <input
                        className={determineInvalid("email")}
                        type="text"
                        value={email}
                        onChange={(event) => setEmail(event.target.value)}
                      />
                    )}
                  </div>
                </div>
                <div className="flex row v-center">
                  {/* 聯絡地址 */}
                  <div className="flex address">
                    <a className="flex v-center title">聯絡地址:</a>
                    {!onEdit ? (
                      <div className="flex v-center ">
                        <a className="input address-zipcode">{zipcode}</a>
                        <a className="input">{city}</a>
                        <a className="input">{district}</a>
                        <a className="input">{address}</a>
                      </div>
                    ) : (
                      <div className="flex-column">
                        <div className="flex h-between v-center address-line1">
                          <input
                            className={
                              "input-zipcode" + determineInvalid("zipcode")
                            }
                            type="text"
                            value={zipcode}
                            onChange={(event) => setZipcode(event.target.value)}
                            disabled
                          />
                          {getCities()}
                          {getDistrict()}
                        </div>
                        <input
                          className={
                            "input-address" + determineInvalid("address")
                          }
                          type="text"
                          value={address}
                          onChange={(event) => setAddress(event.target.value)}
                        />
                      </div>
                    )}
                  </div>
                </div>
                {onEdit && (
                  <div className="flex-column v-center">
                    {error !== null && <a className="error-msg">{error}</a>}
                    <button
                      className="button1 submit"
                      style={{ marginTop: error === null ? "20px" : "13px" }}
                      onClick={handleSubmit}
                    >
                      保存
                    </button>
                    <a className="cancel" onClick={handleCancel}>
                      取消
                    </a>
                  </div>
                )}
              </div>
            </div>
          </div>
        )}
      </div>
      <Footer />
      {showPopUp && (
        <div id="sign-out">
          <PopUp
            popUpContent={popUpType === 1 ? popUpContent1 : popUpContent2}
            hidePopUp={popUpType === 1 ? hidePopUp1 : hidePopUp2}
          />
        </div>
      )}
    </div>
  );
}
