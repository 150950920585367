import React, { useState, useRef, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
// Sign in
import SignIn from "../../User/SignInUp/SignIn";
import SignUp from "../../User/SignInUp/SignUp";
import ForgotUserName from "../../User/SignInUp/ForgotUserName";
import ForgotPassword from "../../User/SignInUp/ForgotPassword";
// Footer
import Footer from "../../../widgets/Footer/Footer";
// Css
import "./orderTracking.css";

export default function OrderTracking(props) {
  const navigate = useNavigate();
  const { signedIn } = props;

  // Sign in/sign up/forgot user name/forgot password
  const [showSignIn, setShowSignIn] = useState(true);
  const [showSignUp, setShowSignUp] = useState(false);
  const [showForgotUserName, setShowForgotUserName] = useState(false);
  const [showForgotPassword, setShowForgotPassword] = useState(false);
  // Orders
  const [orders, setOrders] = useState([]);
  // Tracking & email
  const trackingNumber = useRef("");
  const trackingEmail = useRef("");
  const [validTrackingNumber, setValidTrackingNumber] = useState(true);
  const [validTrackingEmail, setValidTrackingEmail] = useState(true);
  const [validTracking, setValidTracking] = useState(true);
  const [error, setError] = useState("");

  const getCookie = () => {
    let { cookie } = document;
    cookie = cookie.split("; ");

    if (cookie.length === 1 && cookie[0] === "") {
      return null;
    } else {
      const info = {};
      cookie.forEach((item) => {
        const str = item.split("=");
        info[str[0]] = str[1];
      });

      return info;
    }
  };

  useEffect(() => {
    const info = getCookie();

    if (signedIn || (info.user && info.x)) {
      // call endpoint - get tracking status
      callGetAccountOrders();
    }
  }, []);

  // Endpoint
  async function callGetAccountOrders(info) {
    if (!info) {
      info = getCookie();
    }

    await axios
      .get(
        `${process.env.REACT_APP_API}/orders/getOrders?userName=${info.user}`,
        {
          headers: {
            "x-access-token": info.x,
            "x-api-key": process.env.REACT_APP_API_KEY
          },
        }
      )
      .then((res) => {
        if (res.status === 200) {
          setOrders(res.data);
        }
      })
      .catch(() => {});
  }
  async function callCheckOrderEndpoint(orderNumber, email) {
    const info = getCookie();

    await axios
      .get(
        `${process.env.REACT_APP_API}/orders/check?trackingNumber=${orderNumber}&email=${email}`, {
          headers: { "x-api-key": process.env.REACT_APP_API_KEY },
        }
      )
      .then((res) => {
        if (res.status === 200) {
          navigate("/order-detail/" + orderNumber + "/" + email);
        }
      })
      .catch((err) => {
        if (err.response && err.response.status === 403) {
          setValidTracking(false);
          setError("抱歉, 查無此訂單, 請確認您輸入的訂單號碼和email是否正確");
        }
      });
  }

  // Get order list
  const getOrderList = () => {
    const list = [];

    if (orders.length === 0) {
      list.push(
        <div key="order_list_no_order" className="flex h-between row no-order">
          <div key="order_list_div_no_order">
            <a key="order_list_text_no_order">無訂單資料</a>
          </div>
        </div>
      );
    } else {
      orders.forEach((item, index) => {
        list.push(
          <div key={"order_list" + index} className="flex h-between row">
            <div key={"order_number_div" + index}>
              <a key={"order_number" + index}>{item.trackingNumber}</a>
            </div>
            <div key={"order_date_div" + index}>
              <a key={"order_date" + index}>{formatDate(item.date)}</a>
            </div>
            <div key={"order_status_div" + index}>
              <a key={"order_status" + index}>{item.status}</a>
            </div>
            <div key={"order_detail_div" + index}>
              <a
                key={"order_detail" + index}
                className="link"
                onClick={() =>
                  navigate("/order-detail/" + item.trackingNumber + "/0")
                }
              >
                訂單詳情
              </a>
            </div>
          </div>
        );
      });
    }

    return (
      <div className="flex-column order-list">
        <div className="flex h-between row title-row">
          <div>
            <a>訂單編號</a>
          </div>
          <div>
            <a>訂單日期</a>
          </div>
          <div>
            <a>訂單狀態</a>
          </div>
          <div>
            <a>訂單詳情</a>
          </div>
        </div>
        {list}
      </div>
    );
  };

  // Format date
  const formatDate = (rawDate) => {
    let date = "";
    date = new Date(rawDate).getFullYear() + "/";
    date += new Date(rawDate).getMonth() < 10 ? "0" : "";
    date += new Date(rawDate).getMonth() + "/";
    date += new Date(rawDate).getDate() < 10 ? "0" : "";
    date += new Date(rawDate).getDate();

    return date;
  };

  // Validate tracking input
  const validateTrackingInput = () => {
    let check = true;
    setValidTracking(true);

    // Tracking number
    if (trackingNumber.current.value.length === 0) {
      setValidTrackingNumber(false);
      check = false;
    } else {
      setValidTrackingNumber(true);
    }

    // Email
    if (
      !/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(
        trackingEmail.current.value
      )
    ) {
      setValidTrackingEmail(false);
      check = false;
    } else {
      setValidTrackingEmail(true);
    }

    if (check) {
      callCheckOrderEndpoint(
        trackingNumber.current.value,
        trackingEmail.current.value
      );
    }
  };

  return (
    <div id="page-tracking" className="flex-column v-center page">
      <div className="flex page-content">
        <div className="flex-column content">
          <h2>訂單查詢</h2>
          {!signedIn && (
            <div className="flex h-between tracking sign-out">
              <div className="flex-column v-center form">
                {showSignIn && (
                  <SignIn
                    insidePage={true}
                    showForgetFileds={true}
                    showSignInAnimation={false}
                    setShowSignIn={setShowSignIn}
                    setShowSignUp={setShowSignUp}
                    setShowForgotPassword={setShowForgotPassword}
                    setShowForgotUserName={setShowForgotUserName}
                    setSignedIn={props.setSignedIn}
                    setUserFullName={props.setUserFullName}
                    setShowSignInAnimation={props.setShowSignInAnimation}
                    setShowSignUpSocial={props.setShowSignUpSocial}
                    setSocialInfo={props.setSocialInfo}
                    setCartQuantity={props.setCartQuantity}
                    setRefreshCart={props.setRefreshCart}
                  />
                )}
                {showSignUp && (
                  <SignUp
                    insidePage={true}
                    setShowSignIn={setShowSignIn}
                    setShowSignInAnimation={() => {}}
                    setShowSignUp={setShowSignUp}
                  />
                )}
                {showForgotUserName && (
                  <ForgotUserName
                    insidePage={true}
                    setShowSignIn={setShowSignIn}
                    setShowForgotUserName={setShowForgotUserName}
                    setShowForgotPassword={setShowForgotPassword}
                    setShowSignInAnimation={() => {}}
                  />
                )}
                {showForgotPassword && (
                  <ForgotPassword
                    insidePage={true}
                    setShowSignIn={setShowSignIn}
                    setShowForgotUserName={setShowForgotUserName}
                    setShowForgotPassword={setShowForgotPassword}
                    setShowSignInAnimation={() => {}}
                  />
                )}
              </div>
              <div className="flex-column v-center form tracking-form">
                <div className="flex-column v-center">
                  <a className="input-title">
                    訂單號碼<span>*</span>
                  </a>
                  <input
                    type="text"
                    ref={trackingNumber}
                    className={validTrackingNumber ? "" : "invalid-input"}
                  />
                  <a className="input-title">
                    Email<span>*</span>
                  </a>
                  <input
                    type="text"
                    ref={trackingEmail}
                    className={validTrackingEmail ? "" : "invalid-input"}
                  />
                  {!validTracking && <a className="invalid-msg">{error}</a>}
                  <button className="button1" onClick={validateTrackingInput}>
                    查詢
                  </button>
                </div>
              </div>
            </div>
          )}
          {signedIn && getOrderList()}
        </div>
      </div>
      <Footer />
    </div>
  );
}
